import {COST_PER_KM, COST_PER_MIN, TAXI_STARTING_FEE} from "../constants/enums";
import axios from "../lib/axios";
const REACT_APP_GOOGLE_MAPS_APIKEY = process.env.REACT_APP_GOOGLE_MAPS_APIKEY;

export const formatPlaceAddressFromPlacesAPI = (placeDetails) => {
    if (placeDetails && placeDetails.address_components) {
        // Initialize variables for parts of the address
        let streetName = "", streetNumber = "", locality = "";

        // Extract components using .find() on the address_components array
        const routeComponent = placeDetails.address_components.find(component => component.types?.includes("route"));
        if (routeComponent) streetName = routeComponent.long_name;

        const streetNumberComponent = placeDetails.address_components.find(component => component.types?.includes("street_number"));
        if (streetNumberComponent) streetNumber = streetNumberComponent.long_name;

        const localityComponent = placeDetails.address_components.find(component => component.types?.includes("locality"));
        if (localityComponent) locality = localityComponent.long_name;

        // Construct and return the address string
        let a = [streetName + streetNumber, locality].filter(Boolean).join(", ").trim();
        console.log(a);
        return a;
    }
    return ''; // Return an empty string if no placeDetails or address_components
}

export const selectSuggestionAndParsePlaceDetails = async (placeId, onPressGooglePlacesAutocomplete) => {
    try {
        const response = await fetch(
            `https://maps.googleapis.com/maps/api/place/details/json?placeid=${placeId}&fields=formatted_address,address_components&key=${REACT_APP_GOOGLE_MAPS_APIKEY}`
        );
        const data = await response.json();

        if (data.status === "OK" && onPressGooglePlacesAutocomplete) {
            console.log(data.result)
            let formattedAddress = formatPlaceAddressFromPlacesAPI(data.result);
            onPressGooglePlacesAutocomplete(formattedAddress);
        }
    } catch (error) {
        console.error("Error fetching place details:", error);
    }
};

export const selectSuggestion = (place, onPressGooglePlacesAutocomplete) => {
    console.log("Selected place", JSON.stringify(place, null, 4))
    if (onPressGooglePlacesAutocomplete) {
        onPressGooglePlacesAutocomplete(place.description)
    }
};

export const renderPlacesAPISuggestions = (predictions, setPredictions, onPressGooglePlacesAutocomplete) => {
    return (
        <div className="predictions-list">
            {predictions.map((place) => (
                <div
                    className="prediction-container"
                    key={place.place_id}
                    onClick={() => {
                        selectSuggestion(place, onPressGooglePlacesAutocomplete);
                        setPredictions([]);
                    }}
                    role="button"
                    tabIndex={0}
                    style={{cursor: 'pointer'}}
                >
                    <div className="prediction">{place.description}</div>
                </div>
            ))}
        </div>
    )
}

export const fetchPlacesPredictions = async (inputText, setPredictions) => {
    if (!inputText || inputText.length < 3) {
        setPredictions([]);
        return;
    }

    try {
        const response = await axios.get(`/google_maps/autocomplete?inputText=${encodeURIComponent(inputText)}`);

        if (response && !response.error) {
            setPredictions(response.data.predictions);
        } else {
            console.error('Failed to fetch predictions:', response?.error);
            setPredictions([]);
        }
    } catch (error) {
        console.error("Error fetching places predictions:", error);
        setPredictions([]);
    }
};

export const fetchRouteDetails = async (route, vehicleCategory) => {
    try {
        const originAddress = route[0].address;
        const destinationAddress = route[route.length - 1].address;
        const waypoints = (route.length > 2) ? route.slice(1, -1).map(point => point.address) : [];
        const waypointsParam = waypoints.length > 0 ? `&waypoints=${waypoints.join('|')}` : '';

        const req = `https://maps.googleapis.com/maps/api/directions/json?origin=${originAddress}&destination=${destinationAddress}${waypointsParam}&departure_time=now&trafficModel=pessimistic&key=${REACT_APP_GOOGLE_MAPS_APIKEY}`;
        console.log(req)
        const response = await fetch(req);

        const data = await response.json();
        if (data.status === 'OK') {
            let totalDistance = 0;
            let totalDuration = 0;
            data.routes[0].legs.forEach(leg => {
                totalDistance += leg.distance.value;
                totalDuration += leg.duration_in_traffic ? leg.duration_in_traffic.value : leg.duration.value;
            });

            const duration = totalDuration / 60; // duration from API is in seconds, convert to minutes
            let distanceInKm = totalDistance / 1000; // distance from API is in meters, convert to km
            distanceInKm = Math.round(distanceInKm * 10) / 10; // round to one decimal place
            const cost = (distanceInKm * COST_PER_KM[vehicleCategory?.toUpperCase()] +
                TAXI_STARTING_FEE[vehicleCategory?.toUpperCase()] +
                duration.toFixed(0) * COST_PER_MIN[vehicleCategory?.toUpperCase()]).toFixed(2);

            console.log('Route details ORDER:', distanceInKm, cost);
            return {duration: duration.toFixed(0), distance: distanceInKm.toFixed(1), cost};
        } else {
            console.error('Error response from Google Directions API:', data.status);
            return null;
        }
    } catch (error) {
        console.error('Error fetching data from Google Directions API:', error);
        return null;
    }
};

export const fetchRouteDetailsFromPointToDriver = async (origin, destination, vehicleCategory) => {
    try {
        const req = `https://maps.googleapis.com/maps/api/directions/json?origin=${origin.latitude},${origin.longitude}&destination=${destination.latitude},${destination.longitude}&departure_time=now&traffic_model=pessimistic&key=${REACT_APP_GOOGLE_MAPS_APIKEY}`;
        console.log(req);
        const response = await fetch(req);

        const data = await response.json();
        if (data.status === 'OK') {
            let totalDistance = 0;
            let totalDuration = 0;
            data.routes[0].legs.forEach(leg => {
                totalDistance += leg.distance.value;
                totalDuration += leg.duration_in_traffic ? leg.duration_in_traffic.value : leg.duration.value;
            });

            const duration = totalDuration / 60; // duration from API is in seconds, convert to minutes
            let distanceInKm = totalDistance / 1000; // distance from API is in meters, convert to km
            distanceInKm = Math.round(distanceInKm * 10) / 10; // round to one decimal place


            console.log("distance, duration", distanceInKm, duration);

            const cost = (distanceInKm * COST_PER_KM[vehicleCategory?.toUpperCase()] +
                TAXI_STARTING_FEE[vehicleCategory?.toUpperCase()] +
                duration.toFixed(0) * COST_PER_MIN[vehicleCategory?.toUpperCase()]).toFixed(2);

            console.log('Route details:', distanceInKm, cost);
            return {duration: duration.toFixed(0), distance: distanceInKm.toFixed(1), cost};
        } else {
            console.error('Error response from Google Directions API:', data.status);
            return null;
        }
    } catch (error) {
        console.error('Error fetching data from Google Directions API:', error);
        return null;
    }
};

export const updateDriverWithEstimates = async (pickup_location, driver) => {
    let estimates = {
        ...driver?.estimates,
        estimated_time: null,
        estimated_cost: null
    }
    try {
        if (pickup_location.coordinates.latitude && pickup_location.coordinates.longitude) {
            const origin = {
                latitude: pickup_location.coordinates.latitude,
                longitude: pickup_location.coordinates.longitude
            };
            const destination = {
                latitude: driver.location.coordinates.latitude,
                longitude: driver.location.coordinates.longitude
            };
            const routeDetails = await fetchRouteDetailsFromPointToDriver(origin, destination, driver?.vehicles[0].category);

            if (routeDetails) {
                estimates.estimated_time = routeDetails.duration;
                estimates.estimated_cost = routeDetails.cost;

                // console.log("DRIVER estimates to pickup location: ", estimates);
            }
        }
    } catch (error) {
        console.error("Error fetching route details: ", error);
    }

    const updatedDriver = {
        ...driver,
        estimates: estimates
    };

    return updatedDriver;
};