import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/img/logo.png";
import '../css/Sidebar.css';

import { setUserData, setUserToken } from "../../store/actions";
import { PAGES, USER_ROLE } from "../../constants/enums";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome,
    faUsers,
    faTaxi,
    faComments,
    faBuilding,
    faChartBar,
    faMapMarkedAlt,
    faCar,
    faTruck,
    faBiking,
    faClipboardList
} from '@fortawesome/free-solid-svg-icons';
import {clearUserState} from "../../store/slices/userSlice";
import {clearState} from "../../store/slices/adminSlice";

function Sidebar(props) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    const [userRole, setUserRole] = useState(user.user_role);
    const [displaySidebar, setDisplaySidebar] = useState(props.display);

    const onLogout = () => {
        dispatch(setUserData(null));
        dispatch(setUserToken(null));
        dispatch(clearUserState())
        dispatch(clearState())
    };

    const navigate = useNavigate();
    const onNavigateTo = useCallback((path) => {
        navigate(path);
    }, [navigate]);

    const onNavigateAction = (path) => {
        onNavigateTo(path);
    };

    const onNavigateActionReset = (path, value_to_reset) => {
        window[value_to_reset] = null;
        onNavigateTo(path);
    };

    useEffect(() => {
        setDisplaySidebar(props.display);
    }, [props.display]);

    let classNameLi = '';

    const renderAdminSidebar = () => {
    if (userRole !== USER_ROLE.ADMIN) {
        return null;
    }

    return (
        <div className={'sidebar'}>
            <div onClick={() => onNavigateAction(PAGES.DASHBOARD_TAXI)} className={'logo'} style={{ alignSelf: 'center' }}>
                <img src={logo} alt="logo" width={'220px'} />
            </div>
            <div className={'divider'} style={{ marginBottom: '12px' }} />
            <ul>
                <li className={classNameLi}>
                    <a onClick={() => onNavigateAction(PAGES.DASHBOARD_TAXI)}>
                        <FontAwesomeIcon icon={faHome} className={'icon'} size='2x'/>
                        DASHBOARD
                    </a>
                    <ul style={{paddingLeft: '20px'}}>
                        <li className={classNameLi} style={{fontSize: '1.2em'}}>
                            <a onClick={() => onNavigateAction(PAGES.DASHBOARD_TAXI)}>
                                <FontAwesomeIcon icon={faTaxi} className={'icon icon-small'} size='2x'/>
                                Taxi
                            </a>
                        </li>
                        <li className={classNameLi} style={{fontSize: '1.2em'}}>
                            <a onClick={() => onNavigateAction(PAGES.DASHBOARD_TRANSFER)}>
                                <FontAwesomeIcon icon={faTruck} className={'icon icon-small'} size='2x'/>
                                Transfer
                            </a>
                        </li>
                        <li className={classNameLi} style={{fontSize: '1.2em'}}>
                            <a onClick={() => onNavigateAction(PAGES.DASHBOARD_DELIVERY)}>
                                <FontAwesomeIcon icon={faBiking} className={'icon icon-small'} size='2x'/>
                                Delivery
                            </a>
                        </li>
                    </ul>
                </li>
                <li className={classNameLi}>
                    <a onClick={() => {}}>
                        <FontAwesomeIcon icon={faCar} className={'icon'} size='2x'/>
                        DRIVERS
                    </a>
                    <ul style={{paddingLeft: '20px'}}>
                        <li className={classNameLi} style={{fontSize: '1.2em', width: "max-content"}}>
                            <a onClick={() => onNavigateAction(PAGES.DRIVERS_LIST)}>
                                <FontAwesomeIcon icon={faTaxi} className={'icon icon-small'} size='2x'/>
                                Taxi drivers
                            </a>
                        </li>
                        <li className={classNameLi} style={{fontSize: '1.2em', width: "max-content"}}>
                            <a onClick={() => onNavigateAction(PAGES.DELIVERY_DRIVERS_LIST)}>
                                <FontAwesomeIcon icon={faBiking} className={'icon icon-small'} size='2x'/>
                                Delivery drivers
                            </a>
                        </li>
                    </ul>
                </li>
                <li className={classNameLi}>
                    <a onClick={() => onNavigateAction(PAGES.USERS_LIST)}>
                        <FontAwesomeIcon icon={faUsers} className={'icon'} size='2x'/>
                        USERS LIST
                    </a>
                </li>
                <li className={classNameLi}>
                    <a onClick={() => onNavigateAction(PAGES.FEEDBACKS)}>
                        <FontAwesomeIcon icon={faComments} className={'icon'} size='2x'/>
                        FEEDBACKS
                    </a>
                </li>
                <li className={classNameLi}>
                    <a onClick={() => onNavigateAction(PAGES.TAXI_ZONES)}>
                        <FontAwesomeIcon icon={faMapMarkedAlt} className={'icon'} size='2x'/>
                        TAXI ZONES
                    </a>
                </li>
                <li className={classNameLi}>
                    <a onClick={() => onNavigateAction(PAGES.MERCHANTS)}>
                        <FontAwesomeIcon icon={faBuilding} className={'icon'} size='2x'/>
                        MERCHANTS
                    </a>
                </li>
                {/*<li className={classNameLi}>*/}
                {/*    <a onClick={() => onNavigateAction(PAGES.STATISTICS)}>*/}
                {/*        <FontAwesomeIcon icon={faChartBar} className={'icon'} size='2x'/>*/}
                {/*        STATISTICS*/}
                {/*    </a>*/}
                {/*</li>*/}
                <li className={classNameLi}>
                    <a onClick={() => onNavigateAction(PAGES.LOST_ITEMS)}>
                        <FontAwesomeIcon icon={faClipboardList} className={'icon'} size='2x'/>
                        LOST AND FOUND
                    </a>
                </li>
            </ul>
            <div className={'btn-logout'} onClick={onLogout}><p>LOGOUT</p></div>
        </div>
    );
};

    const renderSidebar = () => {
        return <>
            {renderAdminSidebar()}
        </>
    }

    if (!displaySidebar) {
        return <></>;
    }

    return <>
        {renderSidebar()}
    </>;
}

export default Sidebar;
