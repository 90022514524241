import React, { useState } from 'react';
import Input from './Input';
import Button from './Button';
import './../css/ContactForm.css';
import colors from "../../constants/colors";
import RadioButtons from "./RadioButtons";
import {
    dayOptions, getRegionsList,
    getTaxiPreferencesOptions, getVehicleColors,
    languageOptions,
    licenceTypeOptions
} from "../../constants/keyValueOptions";
import {
    convertImages,
    convertLanguagesToObject,
    convertObjectToRadioButtonsList,
    convertTaxiRequirementsToObject, isValidPhoneNumber, validateEmail
} from "../../lib/helpers";
import AddressInput from "./AddressInput";
import GeneralPicker from "./GeneralPicker";
import {carBrands, carModelsData} from "../../data/carBrands";
import {cityCodeOptions, vehicleCategoryOptions, vehicleClassOptions} from "../../data/carValues";
import DatePickerComponent from "./DatePicker";
import WorkingHours from "./WorkingHours";
import PhoneInputComponent from "./PhoneInput";
import UploadFile from "./UploadFile";
import CustomModal from "./CustomModal";

const formatFile = (files, index = 0, defaultName) => {
    return files?.length > index ? [{
        document_id: files[index]?.document_id,
        file_id: files[index]?.file_id || null,
        url: files[index]?.url,
        name: defaultName,
        mime_type: files[index]?.mime_type
    }] : [];
};

const updateFile = (prevFile, newFile) => {
    return prevFile ? {
        ...prevFile,
        url: newFile?.url || prevFile.url,
        name: newFile?.name || prevFile.name,
        mime_type: newFile?.mime_type || prevFile.mime_type,
        base64: newFile?.base64 || null
    } : newFile;
};

const ContactForm = ({ initialData = {}, onSave, waitingForResponse }) => {

    const {
        vehicles = [],
        user = {},
        documents = {}
    } = initialData || {};

    const vehicle = vehicles.length > 0 ? vehicles[0] : null;
    const address = user?.addresses?.[0]?.address || null;
    const nationalIdFiles = user?.documents?.national_id?.files || [];
    const profilePictureFiles = user?.documents?.profile_picture?.files || [];
    const vehicleInsuranceFiles = vehicle?.documents?.vehicle_insurance?.files || [];
    const vehicleTechInspectionFiles = vehicle?.documents?.vehicle_technical_inspection?.files || [];
    const drivingLicenceFiles = documents?.driving_license?.files || [];
    const transferLicenceFiles = documents?.passenger_transfer_license?.files || [];
    const taxiLicenceFiles = documents?.taxi_licence?.files || [];
    const healthDeclarationFiles = documents?.health_declaration?.files || [];


    // User files
    const [frontIdPicture, setFrontIdPicture] = useState(formatFile(nationalIdFiles, 0, 'Front_id.jpg'));
    const [backIdPicture, setBackIdPicture] = useState(formatFile(nationalIdFiles, 1, 'Back_id.jpg'));
    const [profilePicture, setProfilePicture] = useState(formatFile(profilePictureFiles, 0, 'Profile_picture.jpg'));

    // Document files
    const [frontDrivingLicence, setFrontDrivingLicence] = useState(formatFile(drivingLicenceFiles, 0, 'Front_driving_license.jpg'));
    const [backDrivingLicence, setBackDrivingLicence] = useState(formatFile(drivingLicenceFiles, 1, 'Back_driving_license.jpg'));
    const [passengerTransferLicencePhoto, setPassengerTransferLicencePhoto] = useState(formatFile(transferLicenceFiles, 0, 'Passenger_transfer_licence.jpg'));
    const [taxiLicencePhoto, setTaxiLicencePhoto] = useState(formatFile(taxiLicenceFiles, 0, 'Taxi_licence.jpg'));
    const [healthDeclarationFile, setHealthDeclarationFile] = useState(formatFile(healthDeclarationFiles, 0, 'Health_declaration.jpg'));

    // Vehicle files
    const [vehicleInsurance, setVehicleInsurance] = useState(formatFile(vehicleInsuranceFiles, 0, 'Vehicle_insurance.jpg'));
    const [technicalInspection, setTechnicalInspection] = useState(formatFile(vehicleTechInspectionFiles, 0, 'Vehicle_technical_inspection.jpg'));


    const [spokenLanguagesObject, setSpokenLanguagesObject] = useState(initialData?.user?.spoken_languages || {});
    const [spokenLanguagesList, setSpokenLanguagesList] = useState(convertObjectToRadioButtonsList(languageOptions,spokenLanguagesObject) || []);

    const [firstName, setFirstName] = useState(initialData?.user?.first_name || "");
    const [lastName, setLastName] = useState(initialData?.user?.last_name || "");
    const [fullTelephone, setFullTelephone] = useState(initialData?.user?.telephone || "");
    const [telephone, setTelephone] = useState(initialData?.user?.telephone_number || "");
    const [countryCode, setCountryCode] = useState(initialData?.user?.telephone_code || "");

    const [email, setEmail] = useState(initialData?.user?.email || "");
    const [personalAddress, setPersonalAddress] = useState(initialData?.user?.addresses[0]?.address?.address || "");
    const [personalAddressObject, setPersonalAddressObject] = useState(initialData?.user?.addresses[0]?.address || {});
    const [dateOfBirth, setDateOfBirth] = useState(initialData?.user?.date_of_birth || "");

    // Driver's licence
    const [driversLicenceNumber, setDriversLicenceNumber] = useState(initialData?.documents?.driving_license?.additional_info?.drivers_licence_number || "");
    const [licenceTypes, setLicenceTypes] = useState(initialData?.documents?.driving_license?.additional_info?.drivers_licence_type || []);
    const [driversLicenceExpDate, setDriversLicenceExpDate] = useState(initialData?.documents?.driving_license?.expiration_date || '');

    // taxi licencing and permits
    const [transferLicenseExpDate, setTransferLicenseExpDate] = useState(initialData?.documents?.passenger_transfer_license?.expiration_date || "");
    const [taxiLicenceExpDate, setTaxiLicenceExpDate] = useState(initialData?.documents?.taxi_licence?.expiration_date || "");
    const [regions, setRegions] = useState(initialData?.regions || [])

    // Driver data
    const [workingHours, setWorkingHours] = useState(initialData?.working_hours || {});
    const [rideRequirementsObject, setRideRequirementsObject] = useState(initialData?.ride_requirements || {});
    const [rideRequirementsList, setRideRequirementsList] = useState(convertObjectToRadioButtonsList(getTaxiPreferencesOptions(),rideRequirementsObject || []))

    // Vehicle information
    const [make, setMake] = useState(vehicle ? vehicle?.make : "");
    const [model, setModel] = useState(vehicle ? vehicle?.model : "");

    const [modal, setModal] = useState(null)

    const [licencePlateNumber, setLicencePlateNumber] = useState(() => {
        if (vehicle && vehicle?.license_plate) {
            return vehicle?.license_plate.split('-')[1] || "";
        }
        return "";
    });

    const [cityCode, setCityCode] = useState(() => {
        if (vehicle && vehicle?.license_plate) {
            return vehicle?.license_plate.split('-')[0] || "";
        }
        return "";
    });

    const [vehicleColor, setVehicleColor] = useState(vehicle ? vehicle?.color : "");
    const [vehicleClass, setVehicleClass] = useState(vehicle ? vehicle?.class : "");
    const [vehicleCategory, setVehicleCategory] = useState(vehicle ? vehicle?.category : "");

    const [vehicleRegNumber, setVehicleRegNumber] = useState(vehicle?.documents?.vehicle_registration?.additional_info?.registration_number || "");
    const [vehicleManufacturedYear, setVehicleManufacturedYear] = useState(vehicle?.documents?.vehicle_registration?.additional_info?.year || "");

    // Vehicle Insurance
    const [vehicleInsuranceNumber, setVehicleInsuranceNumber] = useState(vehicle?.documents?.vehicle_insurance?.additional_info?.insurance_number || "");
    const [vehicleInsuranceExpDate, setVehicleInsuranceExpDate] = useState(vehicle?.documents?.vehicle_insurance?.expiration_date || "");

    // Technical assessment
    const [technicalAssessmentExpDate, setTechnicalAssessmentExpDate] = useState(vehicle?.documents?.vehicle_technical_inspection?.expiration_date || "");


    const handleSave = () => {
        const isValidEmail = validateEmail(email)
        const isPhoneValid = isValidPhoneNumber(fullTelephone)

        if (!isValidEmail) {
            setModal('invalid_email')
            return
        }
        if (!isPhoneValid) {
            setModal('invalid_phone')
            return
        }

        const updatedData = {
            user: {
                user_id: initialData?.user?.user_id,
                first_name: firstName,
                last_name: lastName,
                telephone: fullTelephone,
                telephone_number: telephone,
                telephone_code: countryCode,
                email: email,
                date_of_birth: dateOfBirth,
                spoken_languages: convertLanguagesToObject(spokenLanguagesList),
            },
            driver:{
                business_id: initialData?.business_id,
                driver_id: initialData?.driver_id,
                working_hours: workingHours,
                ride_requirements: convertTaxiRequirementsToObject(rideRequirementsList),
                regions: regions,
            },
            address: personalAddressObject?.coordinates
                ? {
                    address_id: address?.address_id,
                    address: personalAddressObject?.address,
                    latitude: String(personalAddressObject?.coordinates?.latitude),
                    longitude: String(personalAddressObject?.coordinates?.longitude)
                }
                : personalAddressObject,
            documents: [

                // DRIVER DOCUMENTS
                initialData?.documents?.driving_license && {
                    document_id: initialData?.documents?.driving_license?.document_id,
                    expiration_date: driversLicenceExpDate,
                    additional_info: {
                        drivers_licence_number: driversLicenceNumber,
                        drivers_licence_type: licenceTypes,
                    },
                },
                initialData?.documents?.passenger_transfer_license && {
                    document_id: initialData?.documents?.passenger_transfer_license?.document_id,
                    expiration_date: transferLicenseExpDate,
                },
                initialData?.documents?.taxi_licence && {
                    document_id: initialData?.documents?.taxi_licence?.document_id,
                    expiration_date: taxiLicenceExpDate,
                },

                // VEHICLE DOCUMENTS
                vehicle?.documents?.vehicle_registration && {
                    document_id:  vehicle?.documents?.vehicle_registration?.document_id,
                    additional_info: {
                        registration_number: vehicleRegNumber,
                        year: vehicleManufacturedYear,
                    },
                },
                vehicle?.documents?.vehicle_insurance && {
                    document_id: vehicle?.documents?.vehicle_insurance?.document_id,
                    expiration_date: vehicleInsuranceExpDate,
                    additional_info: {
                        insurance_number: vehicleInsuranceNumber,
                    },
                },
                vehicle?.documents?.vehicle_technical_inspection && {
                    document_id:  vehicle?.documents?.vehicle_technical_inspection?.document_id,
                    expiration_date: technicalAssessmentExpDate,
                },

            ].filter(Boolean), // to filter out undefined values
            vehicle:  {
                vehicle_id: vehicle?.vehicle_id,
                make: make,
                model: model,
                license_plate: `${cityCode}-${licencePlateNumber.toUpperCase()}`,
                color: vehicleColor,
                class: vehicleClass,
                category: vehicleCategory,
            },
            files: [
                ...frontIdPicture,
                ...backIdPicture,
                ...profilePicture,
                ...frontDrivingLicence,
                ...backDrivingLicence,
                ...passengerTransferLicencePhoto,
                ...taxiLicencePhoto,
                ...healthDeclarationFile,
                ...vehicleInsurance,
                ...technicalInspection
            ].filter(Boolean),
        };

        onSave(updatedData);
    };


    const handleMultipleItemSelection = (entry, items, setSelectedItems) => {
        if (entry) {
            const isSelected = items.some(item => item === entry.value);
            if (isSelected) {
                setSelectedItems(prev => prev.filter(item => item !== entry.value));
            } else {
                setSelectedItems(prev => [...prev, entry.value]);
            }
        }
    };

    const handleRemoveMultipleSelection = (value, setSelection) => {
        setSelection(prev => prev.filter(item => item !== value));
    };

    const handleSingleItemSelection = (entry, setSelectedItem) => {
        if (entry) {
            setSelectedItem(entry.value)
        }
    };

    const renderLicenceTypes = () => {
       return selectableList(
           licenceTypeOptions,
           licenceTypes,
           setLicenceTypes,
           handleMultipleItemSelection,
           handleRemoveMultipleSelection,
           "Select licence type"
       )
    };

    const renderRegions = () => {
        return selectableList(
            getRegionsList,
            regions,
            setRegions,
            handleMultipleItemSelection,
            handleRemoveMultipleSelection,
            "Select working region"
        )
    };

    const selectableList = (
                                items,
                                selectedItems,
                                setSelectedItems,
                                onSelect,
                                onRemove,
                                label,
                                displayProperty = "name",
                                valueProperty = "value"
                            ) => {
        return (
            <div>
                <GeneralPicker
                    items={items}
                    onSelect={(value) => onSelect(value, selectedItems, setSelectedItems)}
                    displayProperty={displayProperty}
                    valueProperty={valueProperty}
                    label={label}
                />
                <div>
                    {selectedItems.length > 0 && (
                        <ul style={{ paddingLeft: '6px' }}>
                            {selectedItems.map(value => {
                                const selectedItem = items.find(item => item.value === value);
                                return (
                                    <li
                                        style={{
                                            display: "flex",
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            marginBottom: 2,
                                        }}
                                        key={value}
                                    >
                                        {selectedItem ? selectedItem.name : value}
                                        <Button
                                            cancel
                                            action={() => onRemove(value)}
                                        />
                                    </li>
                                );
                            })}

                        </ul>
                    )}
                </div>
            </div>
        );
    };


    const renderUserColumn = () => {
        return <div className="driver-info-column">
            <h4>Personal Information</h4>
            <div className={'double-input'}>
                <Input
                    style={inlineInputStyle}
                    label="First Name"
                    value={firstName}
                    onChange={(value) => setFirstName(value)}
                />
                <Input
                    style={inlineInputStyle}
                    label="Last Name"
                    value={lastName}
                    onChange={(value) => setLastName(value)}
                />
            </div>

            <PhoneInputComponent
                setCountryCode={setCountryCode}
                setFullTelephone={setFullTelephone}
                setTelephone={setTelephone}
                value={fullTelephone}
                label={'Phone number'}
            />


            <Input
                style={inlineInputStyle}
                label="Email"
                type="email"
                value={email}
                onChange={(value) => setEmail(value)}
            />

            <AddressInput
                label="Personal Address"
                value={personalAddress}
                shouldAutocomplete={true}
                placeholder="Enter personal address..."
                setAddressData={setPersonalAddress}
                setAddressObject={(value) => setPersonalAddressObject(value)}
            />

            <DatePickerComponent
                title={'Date of birth'}
                yellow={true}
                date={dateOfBirth}
                setDate={(value) => {
                    setDateOfBirth(value)
                }}
                formatDate={false}
                placeholder={'Select birth date'}
            />

            <RadioButtons
                title={'Languages Spoken'}
                items={languageOptions}
                value={spokenLanguagesList}
                onSelectionChange={setSpokenLanguagesList}
            />

            {renderUserDocuments()}

        </div>
    }

    const renderDriverColumn = () => {
        return <div className="driver-info-column">
            <h4>Driver Information</h4>
            <Input
                style={inlineInputStyle}
                label="Driver's Licence Number"
                value={driversLicenceNumber}
                onChange={(value) => setDriversLicenceNumber(value)}
            />

            <DatePickerComponent
                title={"Drivers Licence Expiration Date"}
                yellow={true}
                date={driversLicenceExpDate}
                setDate={(value) => {
                    setDriversLicenceExpDate(value)
                }}
                formatDate={false}
            />

            <UploadFile
                title={'Front driving licence'}
                value={frontDrivingLicence[0]}
                handleFile={(newData) =>
                    setFrontDrivingLicence((prevData) => {
                        return prevData.length > 0 ? [updateFile(prevData[0], newData)] : [newData];
                    })}
                border
            />

            <UploadFile
                title={'Back driving licence'}
                value={backDrivingLicence[0]}
                handleFile={(newData) =>
                    setBackDrivingLicence((prevData) => {
                        return prevData.length > 0 ? [updateFile(prevData[0], newData)] : [newData];
                    })}
                border
            />


            {renderLicenceTypes()}
            {renderRegions()}

            {passengerTransferLicencePhoto.length !== 0 && <UploadFile
                title={'Transfer licence'}
                value={passengerTransferLicencePhoto[0]}
                handleFile={(newData) =>
                    setPassengerTransferLicencePhoto((prevData) => {
                        return prevData.length > 0 ? [updateFile(prevData[0], newData)] : [newData];
                    })}
                border
            />}

            {taxiLicencePhoto.length !== 0 && <UploadFile
                title={'Taxi licence'}
                value={taxiLicencePhoto[0]}
                handleFile={(newData) =>
                    setTaxiLicencePhoto((prevData) => {
                        return prevData.length > 0 ? [updateFile(prevData[0], newData)] : [newData];
                    })}
                border
            />}

            {taxiLicenceExpDate &&
                <DatePickerComponent
                    title={"Taxi Licence Expiration Date"}
                    yellow={true}
                    date={taxiLicenceExpDate}
                    setDate={(value) => {
                        setTaxiLicenceExpDate(value)
                    }}
                    formatDate={false}
                />
            }

            {transferLicenseExpDate &&
                <DatePickerComponent
                    title={"Transfer Licence Expiration Date"}
                    yellow={true}
                    date={transferLicenseExpDate}
                    setDate={(value) => {
                        setTransferLicenseExpDate(value)
                    }}
                    formatDate={false}
                />
            }


            <RadioButtons
                title={'Ride Requirements'}
                items={getTaxiPreferencesOptions()}
                value={rideRequirementsList}
                onSelectionChange={setRideRequirementsList}
            />

        </div>
    }

    const renderVehicleColumn = () => {
        return <div className="driver-info-column">
            <h4>Vehicle Information</h4>
            <div className={'double-input'} key={make}>
                <GeneralPicker
                    items={carBrands}
                    value={make}
                    onSelect={(value) => handleSingleItemSelection(value, setMake)}
                    displayProperty={'label'}
                    valueProperty={'value'}
                    label={'Make'}
                />
                <GeneralPicker
                    items={carModelsData[make] || []}
                    value={model.charAt(0).toUpperCase() + model.slice(1).toLowerCase()}
                    onSelect={(value) => handleSingleItemSelection(value, setModel)}
                    displayProperty={'label'}
                    valueProperty={'value'}
                    label={'Model'}
                />
            </div>
            <GeneralPicker
                items={getVehicleColors()}
                value={vehicleColor}
                onSelect={(value) => handleSingleItemSelection(value, setVehicleColor)}
                displayProperty={'value name'}
                valueProperty={'value'}
                label={'Vehicle color'}
            />

            <div className={'double-input'}>
                <GeneralPicker
                    items={vehicleClassOptions}
                    value={vehicleClass}
                    onSelect={(value) => setVehicleClass(value)}
                    displayProperty={'name'}
                    valueProperty={'value'}
                    label={'Class'}
                />

                <GeneralPicker
                    items={vehicleCategoryOptions}
                    value={vehicleCategory}
                    onSelect={(value) => setVehicleCategory(value)}
                    displayProperty={'name'}
                    valueProperty={'value'}
                    label={'Category'}
                />


            </div>


            <p className={'p-label'}>Licence plate</p>
            <div
                style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%'}}>
                <div style={{width: '30%'}}>
                    <GeneralPicker
                        customStyle={{width: '100%'}}
                        items={cityCodeOptions}
                        value={cityCode}
                        onSelect={(value) => setCityCode(value?.value)}
                        displayProperty={'name'}
                        valueProperty={'value'}
                    />
                </div>
                <div style={{width: '70%', marginBottom: 4, marginLeft: 4}}>
                    <Input
                        style={{width: '100%', color: colors.black}}
                        value={licencePlateNumber}
                        onChange={(value) => setLicencePlateNumber(value)}
                    />
                </div>
            </div>
            {renderVehicleRegistration()}

        </div>

    }

    const renderVehicleRegistration = () => {
        return <div>
        {/*// <div className="driver-info-column">*/}
            <h4>Vehicle Registration</h4>
            <Input
                style={inlineInputStyle}
                label="Manufactured Year"
                value={vehicleManufacturedYear}
                onChange={(value) => setVehicleManufacturedYear(value)}
            />
            <Input
                style={inlineInputStyle}
                label="Vehicle Registration Number"
                value={vehicleRegNumber}
                onChange={(value) => setVehicleRegNumber(value)}
            />
            <h4>Vehicle Insurance</h4>
            <Input
                style={inlineInputStyle}
                label="Vehicle Insurance Number"
                value={vehicleInsuranceNumber}
                onChange={(value) => setVehicleInsuranceNumber(value)}
            />

            <UploadFile
                title={'Vehicle insurance'}
                value={vehicleInsurance[0]}
                handleFile={(newData) =>
                    setVehicleInsurance((prevData) => {
                        return prevData.length > 0 ? [updateFile(prevData[0], newData)] : [newData];
                    })}
                border
            />

            <DatePickerComponent
                title="Vehicle Insurance Expiration Date"
                yellow={true}
                date={vehicleInsuranceExpDate}
                setDate={(value) => {
                    setVehicleInsuranceExpDate(value)
                }}
                formatDate={false}
            />

            <h4>Technical Assessment</h4>

            <UploadFile
                title={'Technical inspection'}
                value={technicalInspection[0]}
                handleFile={(newData) =>
                    setTechnicalInspection((prevData) => {
                        return prevData.length > 0 ? [updateFile(prevData[0], newData)] : [newData];
                    })}
                border
            />

            <DatePickerComponent
                title="Technical Assessment Expiration Date"
                yellow={true}
                date={technicalAssessmentExpDate}
                setDate={(value) => {
                    setTechnicalAssessmentExpDate(value)
                }}
                formatDate={false}
            />
        </div>
    }

    const inlineInputStyle = {
        width: '100%',
        color: colors.black
    }

    const renderUserDocuments = () => {
        return (
            <>
                <h4>Documents</h4>
                <UploadFile
                    title={'Front ID picture'}
                    value={frontIdPicture[0]}
                    handleFile={(newData) =>
                        setFrontIdPicture((prevData) => {
                            return prevData.length > 0 ? [updateFile(prevData[0], newData)] : [newData];
                        })}
                    border
                />

                <UploadFile
                    title={'Back ID picture'}
                    value={backIdPicture[0]}
                    handleFile={(newData) =>
                        setBackIdPicture((prevData) => {
                            return prevData.length > 0 ? [updateFile(prevData[0], newData)] : [newData];
                        })}
                    border
                />

                <UploadFile
                    title={'Profile picture'}
                    value={profilePicture[0]}
                    handleFile={(newData) =>
                        setProfilePicture((prevData) => {
                            return prevData.length > 0 ? [updateFile(prevData[0], newData)] : [newData];
                        })}
                    border
                />
            </>
        )
    }

    return (
        <div className="contact-form">
            <div className="form-grid">

                {renderUserColumn()}
                {renderDriverColumn()}
                {renderVehicleColumn()}


                <div>
                    <h4>Working Hours</h4>
                    <WorkingHours
                        days={dayOptions}
                        selectedDays={workingHours}
                        setSelectedDays={(value) => {
                            setWorkingHours(value)
                        }}
                    />

                    <h4>Health declaration</h4>
                    <UploadFile
                        value={healthDeclarationFile[0]}
                        handleFile={(newData) =>
                            setHealthDeclarationFile((prevData) => {
                                return prevData.length > 0 ? [updateFile(prevData[0], newData)] : [newData];
                            })}
                        border
                    />
                </div>


            </div>

            <div className="button-container-form">
                <Button
                    text={'SAVE'}
                    action={handleSave}
                    disabled={waitingForResponse}
                    loading={waitingForResponse}
                    style={{
                        backgroundColor: colors.white,
                        borderColor: colors.yellow,
                        borderWidth: 2,
                        borderStyle: 'solid',
                        borderRadius: 10
                    }}
                    styleText={{color: colors.yellow}}
                />
            </div>

            <CustomModal
                visible={modal === 'invalid_email'}
                title={"Invalid Email"}
                description={"Please input a valid email address."}
                button1Text={ "OK"}
                button1CallAction={() => setModal(null)}
                onClose={() => setModal(null)}
                yellow
            />

            <CustomModal
                visible={modal === 'invalid_phone'}
                title={"Invalid Phone Number"}
                description={"Please input a valid phone number."}
                button1Text={"OK"}
                button1CallAction={() => setModal(null)}
                onClose={() => setModal(null)}
                yellow
            />


        </div>
    );
};

export default ContactForm;
