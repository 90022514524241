import React, {useEffect, useState} from 'react';
import '../css/DataSquare.css';
import driverApi from "../../apis/driverApi";
import taxiApi from "../../apis/taxiApi";
import {TAXI_ORDER_STATUS} from "../../constants/enums"; // Assuming you have a CSS file for styling

export const formatCurrency = (amount) => `${parseFloat(amount).toFixed(2)}€`;

export const renderDriversListDataSquares = (l10nText, activeDrivers, totalDrivers, onlinePercentage, completedDrives, amountEarnedToday) => {

    return (
        <div className="data-squares-row">
            <DataSquare value={`${activeDrivers}/${totalDrivers}`} label={l10nText.active || "Active"} />
            <DataSquare value={`${onlinePercentage || 0}%`} label={l10nText.online || "Online/Offline"} />
            <DataSquare value={completedDrives} label={l10nText.completed_drives || "Today's Completed Drives"} />
            <DataSquare value={formatCurrency(amountEarnedToday)} label={l10nText.earned_today || "Earned Today"} />
        </div>
    );
};

export const renderDriversEarningsListDataSquares = (l10nText, style, todaysEarnings, weeklyEarnings, monthlyEarnings, totalEarnings) => {

    return (
        <div className="data-squares-row" style={{...style}}>
            <DataSquare value={formatCurrency(todaysEarnings)} label={l10nText.todays_earnings || "Today's Earnings"} />
            <DataSquare value={formatCurrency(weeklyEarnings)} label={l10nText.weekly_earnings || "Weekly Earnings"} />
            <DataSquare value={formatCurrency(monthlyEarnings)} label={l10nText.monthly_earnings || "Monthly Earnings"} />
            <DataSquare value={formatCurrency(totalEarnings)} label={l10nText.total_earnings || "Total Earnings"} />
        </div>
    );
};

export const renderDashboardTaxiDataSquares = (l10nText, style, minimumActiveDrivers, activeDrivers, totalRegisteredDrivers, completedRidesToday, drivers) => {
    const onOrderCount = drivers.filter(driver => driver.on_order).length;
    const notOnOrderCount = activeDrivers - onOrderCount;

    return (
        <div className="data-squares-row" style={{...style}}>
            <DataSquare value={minimumActiveDrivers}
                        label={l10nText.minimum_active_drivers || "Minimum Active Drivers"}/>
            <DataSquare value={`${activeDrivers} / ${totalRegisteredDrivers}`}
                        label={l10nText.active_drivers || "Active / Total Drivers"}/>
            <DataSquare value={`${onOrderCount} / ${notOnOrderCount}`}
                        label={l10nText.on_order || "Drivers on Order"}/>
            <DataSquare value={completedRidesToday}
                        label={l10nText.completed_rides_today || "Completed Rides Today"}/>
        </div>
    );
};

export const renderUsersListDataSquares = (l10nText, totalUsers, usersJoinedToday, usersJoinedLast7Days, usersJoinedLastWeek, usersJoinedLastMonth, usersJoinedThisYear) => {
    return (
        <div className="data-squares-row">
            <DataSquare value={usersJoinedToday} label={l10nText.users_joined_today || "Users Joined Today"} />
            <DataSquare value={usersJoinedLast7Days} label={l10nText.users_joined_last_7_days || "Users Joined Last 7 Days"} />
            <DataSquare value={usersJoinedLastWeek} label={l10nText.users_joined_last_week || "Users Joined Last Week"} />
            <DataSquare value={usersJoinedLastMonth} label={l10nText.users_joined_last_month || "Users Joined Last Month"} />
            <DataSquare value={usersJoinedThisYear} label={l10nText.users_joined_this_year || "Users Joined This Year"} style={{ marginRight: 0 }} />
            |
            <DataSquare value={totalUsers} label={l10nText.total_users || "Total Users"} />
        </div>
    );
};

export const renderLostAndFoundSquares = (l10nText, foundItems, lostItems) => {
    return (
        <div className="data-squares-row">
            <DataSquare value={lostItems} label={l10nText?.lostItems || "Total Lost Items"} />
            <DataSquare value={foundItems} label={l10nText?.foundItems || "Total Found Items"} />
        </div>
    );
};

export const renderBusinessesDataSquares = (
    l10nText,
    registeredBusinessUsers,
    popularRestaurants,
    newRestaurants,
    businessesOfferingDailyMeals,
    businessesWithSeatingReservation
) => {
    return (
        <div className="data-squares-row">
            <DataSquare value={`${registeredBusinessUsers}`} label={l10nText?.registered_users || "Registered Business Users"} />
            <DataSquare value={`${popularRestaurants}`} label={l10nText?.popular || "Popular Restaurants"} />
            <DataSquare value={`${newRestaurants}`} label={l10nText?.new || "New Restaurants"} />
            <DataSquare value={`${businessesOfferingDailyMeals}`} label={l10nText?.offering_daily_meals || "Businesses Offering Daily Meals"} />
            <DataSquare value={`${businessesWithSeatingReservation}`} label={l10nText?.seating_reservation || "Businesses with Seating Reservation"} />
        </div>
    );
};

const DataSquare = ({ value, label, style }) => {
    return (
        <div className="data-square" style={style}>
            <div className="data-value">{value}</div>
            <div className="data-label">{label}</div>
        </div>
    );
};



export default DataSquare;
