import React, { useState, useEffect } from 'react';
import googleMapsApi from "../../apis/googleMaps";
import Input from "./Input";
import colors from "../../constants/colors";

const AddressInput = ({ setAddressObject, setAddressData, shouldAutocomplete, placeholder = '', value, style, label }) => {

    const fetchAddressData = async (address) => {
        try {
            const response = await googleMapsApi.fetchAddressData(address);
            const { addressData } = response;
            setAddressObject(addressData);
        } catch (error) {
            console.error('Error fetching address data:', error);
        }
    };


    return (
            <Input
                autocomplete={shouldAutocomplete}
                value={value}
                onPressGooglePlacesAutocomplete={(address) => {
                    setAddressData(address)
                    if (address.length >= 3) {
                        fetchAddressData(address)
                    }
                }}
                onChange={(address) => {
                    setAddressData(address)
                    if (address.length >= 3) {
                        fetchAddressData(address)
                    }
                }}
                onInputEnd={(address) => {
                    setAddressData(address)
                }}
                label={label}
                placeholder={placeholder}
                style={{width: '100%', marginBottom: '10px', color: colors.black, ...style}}
            />
    );
};

export default AddressInput;
