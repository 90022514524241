export const isNumber = (value) => {
    return !isNaN(value);
};

export const isISODate = (value) => {
    return !isNaN(Date.parse(value));
};

export const formatDateTimeShort = (dateString) => {
    const date = new Date(dateString);

    const formattedDate = new Intl.DateTimeFormat('sl-SI', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).format(date);

    const formattedTime = new Intl.DateTimeFormat('sl-SI', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    }).format(date);

    // Combine date and time with "|"
    return `${formattedDate} | ${formattedTime}`;
};

export const formatDateTime = (dateString) => {
    const date = new Date(dateString);

    const formattedDate = new Intl.DateTimeFormat('sl-SI', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }).format(date);

    const formattedTime = new Intl.DateTimeFormat('sl-SI', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    }).format(date);

    // Combine date and time with "|"
    return `${formattedDate} | ${formattedTime}`;
};

export const formatDate = (dateString) => {
    const date = new Date(dateString);

    const formattedDate = new Intl.DateTimeFormat('sl-SI', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }).format(date);

    // Combine date and time with "|"
    return `${formattedDate}`;
};

export const formatDateISO= (isoDate) => {
    return new Date(isoDate).toLocaleString('sl-SI', { timeZone: 'Europe/Ljubljana' });
};

export const getLocalISOTime = (now) => {
    if (!now) {
        now = new Date();
    }
    const timezoneOffsetInMilliseconds = now.getTimezoneOffset() * 60000;

    return new Date(now.getTime() - timezoneOffsetInMilliseconds).toISOString().slice(0, -1);
}

/**
 * Converts a date string and time string into a timestamp.
 *
 * @param {string} departureDate - The departure date in the format "17 September 2024".
 * @param {string} departureTime - The departure time in the format "12:03".
 * @returns {number} - The resulting timestamp in milliseconds.
 */
export const convertToTimestamp = (departureDate, departureTime) => {
    // Parse the date and time
    const date = new Date(departureDate + ' ' + departureTime);

    // Check if the date parsing was successful
    if (isNaN(date.getTime())) {
        throw new Error('Invalid date or time format');
    }

    // Return the timestamp in milliseconds
    return date.getTime();
}

/**
 * Converts a date string and time string into an ISO 8601 timestamp.
 *
 * @param {string} departureDate - The departure date in the format "17 September 2024".
 * @param {string} departureTime - The departure time in the format "12:03".
 * @returns {string} - The resulting ISO 8601 timestamp.
 */
export const convertToIsoTimestamp = (departureDate, departureTime) => {
    if (!departureTime && !departureDate){
        return ''
    }
    const dateTimeString = `${departureDate} ${departureTime}`;

    const date = new Date(dateTimeString);

    if (isNaN(date.getTime())) {
        throw new Error('Invalid date or time format');
    }

    // Return the ISO 8601 string
    return date.toISOString();
}

