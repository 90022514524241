import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import '../css/Input.css';
import '../css/DatePicker.css';
import Button from "./Button";
import { fetchPlacesPredictions, renderPlacesAPISuggestions } from "../../lib/gApiHelpers";
import { valueExists } from "../../lib/helpers";
import { L10N, L10N_APP_TEXTS } from "../../l10n/l10n";

function Input(props) {
    const [input, setInput] = useState('');
    const [predictions, setPredictions] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [buttonText, setButtonText] = useState('edit');
    const language = useSelector(state => state.user.selectedLanguage);
    const [selectedLanguage, setSelectedLanguage] = useState(language ? language : L10N.si.value);

    const preventShowingPredictionsCondition = () => {
        return input && props.autocomplete && predictions.length > 0 && input === predictions[0].description;
    };

    useEffect(() => {
        if (language) {
            setSelectedLanguage(language);
            setButtonText(disabled ? L10N_APP_TEXTS[language].EDIT : L10N_APP_TEXTS[language].SAVE?.toLowerCase());
        }
    }, [language, disabled]);

    useEffect(() => {
        if (props.user && props.email) {
            setInput(props.email);
        }
    }, [props.user, props.email]);

    useEffect(() => {
        if (props.value || props.value === "") {
            setInput(props.value);
        }
    }, [props.value]);

    useEffect(() => {
        if (props.button) {
            setDisabled(buttonText === 'edit');
        }
    }, [props.button, buttonText]);

    useEffect(() => {
        if (props.email) {
            setInput(props.email);
        }
        if (props.password) {
            setInput(props.password);
        }
    }, [props.email, props.password]);

    useEffect(() => {
        if (props.autocomplete && valueExists(input)) {
            fetchPlacesPredictions(input?.trim(), setPredictions).then(r => console.log(r));
        } else {
            setPredictions([]);
        }
    }, [input, props.autocomplete]);

    useEffect(() => {
        if (preventShowingPredictionsCondition()) {
            setPredictions([]);
        }
    }, [input, predictions]);

    const onActionSubmit = (event) => {
        if (props.onSubmit) {
            event.preventDefault();
            props.onSubmit(input);
        }
    };

    const onInputChange = (value) => {
        const { onChange } = props;
        setInput(value);
        onChange(value);
    }

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent any action on Enter key press
        }
    };

    let className = 'input-default';
    if (props.white) {
        className += ' input-white';
    }
    if (props.login) {
        className += ' input-login';
    }
    if (props.loadUserActivation) {
        className += ' input-loadUserActivation';
    }
    if (props.shadow) {
        className += ' input-shadow';
    }
    if (props.textarea) {
        className += ' input-textarea';
    }
    if (props.price) {
        className += ' input-price';
    }
    if (props?.date_picker_input) {
        className += 'date-picker-input'
    }

    let type = 'text';
    if (props.password) {
        type = 'password';
    } else if (props.email) {
        type = 'email';
    }

    let inputContent = () => {
        if (props.textarea) {
            return (
                <>
                    <textarea
                        disabled={props.disabled ? props.disabled : disabled}
                        className={className}
                        placeholder={props.placeholder}
                        style={props.style}
                        value={input ? input : ''}
                        onBlur={props.onBlur}
                        onChange={(event) => onInputChange(event.target.value)}
                        onKeyDown={onKeyDown} // Prevent action on Enter key
                    />
                    {props.button ? (
                        <Button
                            text={props.button ? buttonText : ''}
                            onClick={() => {
                                if (props.button) {
                                    setDisabled(!disabled);
                                }
                                if (props.button && !disabled && props.onAction) {
                                    console.log("SUBMIT DATA");
                                    props.onAction(input);
                                }
                            }}
                        />
                    ) : null}
                </>
            );
        } else {
            return (
                <>
                    <input
                        disabled={props.disabled ? props.disabled : disabled}
                        type={type}
                        className={className}
                        placeholder={props.placeholder}
                        style={props.style}
                        value={input ? input : ''}
                        onBlur={props.onBlur}
                        name={props.login && props.email ? "email" : ""}
                        autoComplete={props.login && props.password ? "current-password" : props.login && props.email ? "email" : "off"}
                        onChange={(event) => onInputChange(event.target.value)}
                        onKeyDown={onKeyDown} // Prevent action on Enter key
                    />
                    {props.button ? (
                        <Button
                            text={props.button ? buttonText : ''}
                            onClick={() => {
                                if (props.button) {
                                    setDisabled(!disabled);
                                }
                                if (props.button && !disabled && props.onAction) {
                                    console.log("SUBMIT DATA");
                                    props.onAction(input);
                                }
                            }}
                        />
                    ) : null}
                </>
            );
        }
    };

    return (
        <form onSubmit={onActionSubmit}>
            <label className={'input-label'}>
                {props.label && <p style={props?.labelStyle} className={'p-label'}>{props.label}</p>}
                {inputContent()}
                {!preventShowingPredictionsCondition() &&
                    renderPlacesAPISuggestions(predictions, setPredictions, props.onPressGooglePlacesAutocomplete)}
            </label>
        </form>
    );
}

export default Input;