import {getColorByValue} from "./dataParsingHelpers";
import Map from '../components/js/Map';
import {formatDateISO, formatDateTimeShort, isISODate} from "./dateUtils";
import React from "react";
import {TAXI_ORDER_STATUS} from "../constants/enums";
import colors from "../constants/colors";
import Button from "../components/js/Button";

export const getExpirationStatus = (date) => {
    const currentDate = new Date();
    const expirationDate = new Date(date);

    const differenceInDays = Math.ceil((expirationDate - currentDate) / (1000 * 60 * 60 * 24));

    if (differenceInDays > 30) {
        return 'valid'; // Green
    } else if (differenceInDays <= 30 && differenceInDays > 5) {
        return 'expiring-soon'; // Yellow
    } else if (differenceInDays <= 5 && differenceInDays >= 0) {
        return 'expiring-very-soon'; // Orange
    } else {
        return 'expired'; // Red
    }
};

export const renderCellContent = (key, value) => {
    // Handle different cases dynamically
    if (key === 'is_scheduled' && value === true) {
        return <span className="scheduled-tag">Scheduled</span>;
    }
    if (key.includes("_id")) {
        return value.slice(0, 6) + "...";
    }
    if (key.endsWith('_expiration_date')) {
        const status = getExpirationStatus(value);
        return <span className={`status-dot ${status}`} />;
    } else if (key.toLowerCase().includes('telephone') || key.toLowerCase().includes('phone_number')) {
        return <a href={`tel:${value}`} style={{ color: 'inherit', textDecoration: 'none' }}>{value}</a>;
    } else if (key === 'is_online') {
        return <span className={`status-dot ${value ? 'online' : 'offline'}`} />;
    } else if (key === 'vehicle_color') {
        const colorValue = getColorByValue(value);
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                    style={{
                        width: '20px',
                        height: '20px',
                        borderRadius: '10%',
                        backgroundColor: colorValue,
                        marginRight: '8px',
                        border: "1px solid black"
                    }}
                />
                {value}
            </div>
        );
    }
    // Detect and format dates dynamically
    else if (isISODate(value)) {
        return formatDateISO(value);
    } else {
        return value;
    }
};

export const renderTaxiOrderExtendedContent = (order, setModal, setTaxiOrder, additionalData) => {
    console.log("ORDER EXTENDED VIEW: ", order);

    /*
    *  additionalData = {
    *   waitingForResponse: true,
    *   cancellingOrderId: null
    * }
    * */

    const handleSetModal = (data) => {
        if (setModal) setModal(data);
    };

    const handleSetTaxiOrder = (data) => {
        if (setTaxiOrder) setTaxiOrder(data);
    };

    return (
        <div className="order-extended-content" style={{display: 'flex', justifyContent: 'space-between'}}>
            {/* Left Column */}
            <div style={{paddingRight: '20px', width: '330px'}}> {/* Fixed width for the first column */}
                <strong>Order details:</strong>
                <div>
                    <strong>Order ID:</strong> {order?.order_id}
                </div>
                <div style={{padding: '12px 0'}}>
                    <div>
                        <strong>Driver:</strong> {order?.driver?.user?.first_name} {order?.driver?.user?.last_name} | {order.driver?.user?.telephone}
                    </div>
                    <div>
                        <strong>Vehicle:</strong> {order.driver?.vehicles[0]?.make} {order.driver?.vehicles[0]?.model} |
                        [{order.driver?.vehicles[0]?.license_plate}]
                    </div>

                    <div style={{padding: '8px 0'}}>
                        {order?.route?.map((point, index) => (
                            <div key={index}>
                                {index === 0 && <div className={'custom-row'}
                                                     style={{justifyContent: 'flex-start', flexWrap: 'wrap'}}>
                                    <strong>Pickup Location:</strong> {point.address}</div>}
                                {index > 0 && index < order.route.length - 1 && order.route.length > 2 && (
                                    <div className={'custom-row'}
                                         style={{justifyContent: 'flex-start', flexWrap: 'wrap'}}>
                                        <strong>Stop {index}:</strong> {point.address}</div>
                                )}
                                {(index === order.route.length - 1 && order.route.length !== 1) &&
                                    <div className={'custom-row'}
                                         style={{justifyContent: 'flex-start', flexWrap: 'wrap'}}><strong>Destination
                                        Location: </strong>{point.address}</div>}
                            </div>
                        ))}
                    </div>
                    <div>
                        <strong>Passenger:</strong> {order?.user?.first_name} {order.user.last_name} | {order.user.telephone}
                    </div>
                    <div><strong>Payment:</strong> <strong>{order.payment?.price}€</strong> via {order.payment?.type}
                    </div>
                </div>

                <div style={{padding: '8px 0'}}>
                    <strong>Ride Preferences:</strong>
                    <div>Adults: <strong>{order.preferences?.adults || 0}</strong></div>
                    <div>Children (above 140 cm): <strong>{order.preferences?.children_above_140 || 0}</strong></div>
                    <div>Children (under 140 cm): <strong>{order.preferences?.children_under_140 || 0}</strong></div>
                    {order?.scheduled_order && (
                        <>
                            <div>Departure Date: {order.preferences?.departure_date || 'N/A'}</div>
                            <div>Departure Time: {order.preferences?.departure_time || 'N/A'}</div>
                        </>
                    )}
                </div>

                <div style={{padding: '8px 0'}}>
                    <strong>Ride Requirements:</strong>
                    <div>Air conditioning: {order.preferences?.ride_requirements?.air_conditioning ?
                        <strong>Yes</strong> : 'No'}</div>
                    <div>Child seats: {order.preferences?.ride_requirements?.child_seats ?
                        <strong>Yes</strong> : 'No'}</div>
                    <div>Luggage: {order.preferences?.ride_requirements?.luggage ? <strong>Yes</strong> : 'No'}</div>
                    <div>Quiet ride: {order.preferences?.ride_requirements?.quiet_ride ?
                        <strong>Yes</strong> : 'No'}</div>
                    <div>Traveling with a pet: {order.preferences?.ride_requirements?.traveling_with_pet ?
                        <strong>Yes</strong> : 'No'}</div>
                    <div>Wheelchair accessibility: {order.preferences?.ride_requirements?.wheelchair_accessibility ?
                        <strong>Yes</strong> : 'No'}</div>
                </div>

                {( order.status === TAXI_ORDER_STATUS.DRIVING ||
                    order.status === TAXI_ORDER_STATUS.WAITING ||
                    order.status === TAXI_ORDER_STATUS.ACCEPTED) && (
                    <Button
                        loading={additionalData?.waitingForResponse && additionalData?.cancelingOrderId === order.order_id}
                        spinnerColor={colors.red}
                        cancel
                        action={() => {
                            handleSetTaxiOrder(order);
                            handleSetModal('cancelOrder');
                        }}
                    />
                )}
            </div>

            {/* Middle Column */}
            <div style={{
                flex: 1,
                maxWidth: '260px',
                paddingLeft: '18px',
                paddingRight: '18px',
                borderLeft: '1px solid #ccc'
            }}>
                <div style={{padding: '0px 0'}}>
                    <strong>Order Timeline:</strong>
                    <ul style={{listStyleType: 'none', paddingLeft: '0'}}>
                        {order.timeline.map((event, index) => (
                            <li key={index} style={{marginBottom: '6px'}}>
                                <div style={{fontSize: "0.93em"}}><strong>{event.status}:</strong></div>
                                <div>
                                    {event.location?.address &&  event?.location?.address.length > 30
                                        ? `${event.location.address.slice(0, 30)}...`
                                        :  event.location?.address || 'Location not available'}
                                </div>

                                <div>{new Date(event.location.timestamp).toLocaleString('sl-SI', {timeZone: 'Europe/Ljubljana'})}</div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {/* Right Column - Map Component */}
            <div style={{flex: 1, paddingLeft: '24px', borderLeft: '1px solid #ccc'}}>
                <strong>Order Route:</strong>
                <div style={{marginTop: '20px', height: '400px', width: '550px'}}>
                    <Map orders={[order]}
                         drivers={order?.driver ? [order?.driver] : []}
                         display_speed_location_history={true}
                    />
                </div>
            </div>
        </div>
    );
};

export const renderDeliveryOrderExtendedContent = (order) => {
    console.log("DELIVERY ORDER EXTENDED VIEW: ", order);

    return (<div className="order-extended-content" style={{display: 'flex', justifyContent: 'space-between'}}>
        {/* Left Column */}
        <div style={{paddingRight: '20px', width: '300px'}}>
            <strong>Order details:</strong>

            <div style={{padding: '12px 0'}}>
                <div><strong>Delivery Address:</strong> {order?.delivery_location?.address}</div>
                <div><strong>Pickup Address:</strong> {order?.pickup_location?.address}</div>
                <div><strong>Business Name:</strong> {order?.business?.name || 'N/A'}</div>
                <div><strong>Business Phone:</strong> {order?.business?.telephone || 'N/A'}</div>

                <div><strong>Is Daily Meal:</strong> {order?.is_daily_meal ? 'Yes' : 'No'}</div>
            </div>


            <div style={{padding: '8px 0'}}>
                <div style={{paddingBottom: '4px'}}><strong>Delivery Details:</strong></div>
                <div>Distance: <strong>{order?.details?.distance}</strong></div>
                <div style={{paddingBottom: '6px'}}>Duration: <strong>{order?.details?.duration}</strong></div>
                <div>Delivery Cost: <strong>{order?.details?.delivery_cost}€</strong></div>
                <div style={{paddingBottom: '6px'}}>Delivery
                    Earnings: <strong>{order?.details?.delivery_earnings}€</strong></div>
                <div>Expected Delivery
                    At: <strong>{formatDateTimeShort(order?.details?.customer_expected_delivery_at)}</strong></div>
            </div>

            <div style={{padding: '8px 0'}}>
                <div style={{paddingBottom: '4px'}}><strong>Ordered items:</strong></div>
                <ul style={{listStyleType: 'none', paddingLeft: '0', margin: 0}}>
                    {order?.items.map((item, index) => (<li key={index}>
                        <div>{item.names.en} - Price: <strong>{item.price}€</strong></div>
                    </li>))}
                </ul>
            </div>


            <div style={{padding: '8px 0'}}>
                <div style={{paddingBottom: '4px'}}><strong>Payment:</strong></div>
                <div>Amount: <strong>{order?.payment?.cash?.amount}€</strong></div>
                <div>Type: <strong>{order?.payment?.type}</strong></div>
                <div>Status: <strong>{order?.payment?.status}</strong></div>
            </div>

            <div style={{padding: '8px 0'}}>
                <div style={{paddingBottom: '4px'}}><strong>Comments:</strong></div>
                <div>
                    Courier Instructions:
                    <span className="p-note">
                        {order?.courier_instructions?.text || 'No instructions'}</span>
                </div>
                <div>
                    Restaurant Message:
                    <span className="p-note">
                        {order?.restaurant_message?.text || 'No message'}</span>
                </div>
            </div>
        </div>

        {/* Middle Column */}
        <div style={{
            flex: 1, maxWidth: '260px', paddingLeft: '18px', paddingRight: '18px', borderLeft: '1px solid #ccc'
        }}>
            <div style={{padding: '0px 0'}}>
                <strong>Order timeline:</strong>
                <ul style={{listStyleType: 'none', paddingLeft: '0'}}>
                    {order?.timeline.map((event, index) => (<li key={index} style={{marginBottom: '6px'}}>
                        <div style={{fontSize: "0.93em"}}><strong>{event.status}:</strong></div>
                        <div>{event.location?.address || 'Location not available'}</div>
                        <div>{new Date(event.location.timestamp).toLocaleString('sl-SI', {timeZone: 'Europe/Ljubljana'})}</div>
                    </li>))}
                </ul>
            </div>
        </div>

        {/* Right Column - Map Component */}
        <div style={{flex: 1, paddingLeft: '24px', borderLeft: '1px solid #ccc'}}>
            <strong>Order Route:</strong>
            <div style={{marginTop: '20px', height: '400px', width: '550px'}}>
                <Map orders={[order]} drivers={order?.delivery_driver_id ? [order?.delivery_driver_id] : []}/>
            </div>
        </div>
    </div>);
};