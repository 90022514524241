import axios from "../lib/axios";

const listOnlineDrivers = async () => {
    try {
        const resp = await axios.get("/delivery_drivers/online");
        return resp.data;
    } catch (err) {
        return err;
    }
};

const listDeliveryDrivers = async () => {
    try {
        const resp = await axios.get("/delivery_drivers");
        return resp.data;
    } catch (err) {
        return err;
    }
}

const getDeliveryDriverByUserId = async (user_id) => {
    try {
        const resp = await axios.get(`/delivery_drivers/user/${user_id}`);
        return resp.data;
    } catch (err) {
        return false;
    }
}

const getDeliveryDriverById = async (deliveryDriverId) => {
    try {
        const resp = await axios.get(`/delivery_drivers/${deliveryDriverId}`);
        return resp.data;
    } catch (err) {
        return false;
    }
};

const updateDeliveryDriverOnlineStatus = async (driverId, isOnline) => {
    try {
        const resp = await axios.patch(`/delivery_drivers/online`, {
            delivery_driver_id: driverId,
            online: isOnline,
        });
        return resp.data;
    } catch (err) {
        console.error("Error updating driver's online status:", err);
        return false;
    }
};

const updateDeliveryDriverLocation = async (delivery_driver_id, location) => {
    try {
        const resp = await axios.patch("/delivery_drivers/location", {
            delivery_driver_id: delivery_driver_id,
            location: location,
        });
        return resp.data;
    } catch (err) {
        return false;
    }
};

const updateDeliveryDriver = async (delivery_driver_id, data) => {
    try {
        const resp = await axios.patch(`/delivery_drivers/update/${delivery_driver_id}`, data);
        return resp.data;
    } catch (err) {
        return false;
    }
};

const updateDeliveryDriverByUserId = async (data) => {
    try {
        const resp = await axios.patch(`/delivery_drivers/update/user`, data);
        return resp.data;
    } catch (err) {
        return false;
    }
};


const DeliveryDriverApi = {
    getDeliveryDriverById,
    getDeliveryDriverByUserId,
    listDeliveryDrivers,
    updateDeliveryDriverOnlineStatus,
    updateDeliveryDriverLocation,
    updateDeliveryDriver,
    updateDeliveryDriverByUserId,
    listOnlineDrivers
}
export default DeliveryDriverApi;