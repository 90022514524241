// noinspection JSValidateTypes

import React, {useEffect, useState} from 'react';
import {GoogleMap, useJsApiLoader, Marker, DirectionsRenderer, InfoWindow} from '@react-google-maps/api';
import MapLegend from "./MapLegend";
import Colors from "../../constants/colors";
import DriverApi from "../../apis/driverApi";
import Button from "./Button";
import {TAXI_ORDER_STATUS} from "../../constants/enums";
import DatePickerComponent from "./DatePicker";
import '../css/Map.css';
import {getColorByValue} from "../../lib/dataParsingHelpers";
import {getStartAndEndOfDay, getStepByZoomLevel, onZoomChanged} from "../../lib/MapUtils";

const REACT_APP_GOOGLE_MAPS_APIKEY = process.env.REACT_APP_GOOGLE_MAPS_APIKEY;

const containerStyle = {
    width: '100%',
    height: '400px',
};

const defaultCenter = {
    lat: 46.056946, // Ljubljana's latitude
    lng: 14.505751, // Ljubljana's longitude
};

const Map = ({
                 orders = [],
                 drivers = [],
                 display_driver_activity_legend = false,
                 display_speed_location_history = false,
                 display_24h_location_history = false
             }) => {
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: REACT_APP_GOOGLE_MAPS_APIKEY,
        libraries: ['places', 'directions'],
    });

    const [zoomLevel, setZoomLevel] = useState(12);
    console.log('ZOOM LEVEL', zoomLevel)

    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [map, setMap] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(defaultCenter);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [historyLocations, setHistoryLocations] = useState([]);
    const [dailyHistoryLocations, setDailyHistoryLocations] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [currentView, setCurrentView] = useState('orderRoute');

    const onLoad = React.useCallback(function callback(map) {
        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    useEffect(() => {
        if (selectedDate && display_24h_location_history && drivers.length > 0) {
            fetchDailyDriverHistoryLocations(drivers[0].driver_id, selectedDate).catch(console.error);
        }
    }, [selectedDate, display_24h_location_history, drivers]);

    useEffect(() => {
        if (orders?.length > 0 && map) {
            const order = orders[0];
            renderActiveOrder(order).catch(console.error);
        }
    }, [orders, map]);

    useEffect(() => {
        fetchHistoryLocations()
    }, [display_speed_location_history]);


    useEffect(() => {
        // Locate the user's current location when the map loads
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCurrentLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                    map?.panTo({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                },
                () => {
                    console.error('Error fetching location');
                }
            );
        }
    }, [map]);

    const fetchHistoryLocations = () => {
        if (display_speed_location_history && orders?.length > 0) {
            const timeline = orders[0].timeline || [];
            const acceptedStatus = timeline.find(t => t.status === TAXI_ORDER_STATUS.ACCEPTED);
            const completedStatus = timeline.find(t => t.status === TAXI_ORDER_STATUS.COMPLETED);
            if (acceptedStatus && completedStatus) {
                fetchDriverHistoryLocations(orders[0].driver_id, acceptedStatus.location.timestamp, completedStatus.location.timestamp).catch(console.error);
                fetchDailyDriverHistoryLocations(orders[0].driver_id, completedStatus.location.timestamp).catch(console.error);
            }
        }
    }

    const fetchDriverHistoryLocations = async (driverId, startTime, endTime) => {
        try {
            const response = await DriverApi.getDriverHistoryLocations(driverId, startTime, endTime);
            if (response && !response.error) {
                console.log('DAILY LOCATIONS', response.locations)
                setHistoryLocations(response.locations);
            }
        } catch (error) {
            console.error('Error fetching driver history locations:', error);
        }
    };

    const fetchDailyDriverHistoryLocations = async (driverId, date) => {
        try {
            const {start, end} = getStartAndEndOfDay(date);
            const response = await DriverApi.getDriverHistoryLocations(driverId, start.toISOString(), end.toISOString());
            if (response && !response.error) {
                console.log("DAILY LOCATIONS", response.locations)
                setDailyHistoryLocations(response.locations);
            }
        } catch (error) {
            console.error('Error fetching daily driver history locations:', error);
        }
    };

    const renderActiveOrder = async (order) => {
        if (order.route && order.route.length > 1) {
            try {
                const directionsService = new window.google.maps.DirectionsService();
                const result = await directionsService.route({
                    origin: {
                        lat: order.route[0].coordinates.latitude,
                        lng: order.route[0].coordinates.longitude,
                    },
                    destination: {
                        lat: order.route[order.route.length - 1].coordinates.latitude,
                        lng: order.route[order.route.length - 1].coordinates.longitude,
                    },
                    waypoints: order.route.slice(1, -1).map((point) => ({
                        location: {
                            lat: point.coordinates.latitude,
                            lng: point.coordinates.longitude,
                        },
                        stopover: true,
                    })),
                    travelMode: window.google.maps.TravelMode.DRIVING,
                });

                if (result.status === 'OK') {
                    setDirectionsResponse(result);
                } else {
                    console.error('Directions request failed due to ', result.status);
                    setDirectionsResponse(null);
                }
            } catch (error) {
                console.error('Error fetching directions: ', error);
                setDirectionsResponse(null);
            }
        } else {
            console.warn('Order route is invalid or missing');
            setDirectionsResponse(null);
        }
    };


    const renderDriversMarkers = () => {
        return drivers?.map((driver, index) => {
            const isOnline = driver.online;
            const isOnOrder = driver.on_order;
            const dotColor = isOnOrder ? Colors.yellowDark : isOnline ? Colors.green : Colors.red;

            // Check if driver location and coordinates are defined
            const driverLocation = driver.location?.coordinates;
            if (!driverLocation) {
                console.warn(`Driver location is missing for driver ID: ${driver.driver_id}`);
                return null; // Skip rendering this driver if location is missing
            }

            return (
                <React.Fragment key={index}>
                    <Marker
                        position={{
                            lat: driverLocation.latitude,
                            lng: driverLocation.longitude,
                        }}
                        icon={{
                            path: window.google.maps.SymbolPath.CIRCLE,
                            fillColor: dotColor,
                            fillOpacity: 1,
                            scale: 8,
                            strokeColor: 'white',
                            strokeWeight: 2,
                        }}
                        onClick={() => setSelectedDriver(driver)}
                    />

                    {selectedDriver && selectedDriver.driver_id === driver.driver_id && ( // Show InfoWindow for the selected driver
                        <InfoWindow
                            position={{
                                lat: driverLocation.latitude,
                                lng: driverLocation.longitude,
                            }}
                            onCloseClick={() => setSelectedDriver(null)} // Close InfoWindow
                        >
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div>
                                    <strong>{driver?.user?.first_name} {driver?.user?.last_name}</strong>
                                    <strong>
                                        <a href={`tel:${driver?.user?.telephone_number}`}
                                           style={{textDecoration: 'none', color: Colors.yellow, marginLeft: '4px'}}>
                                            {driver?.user?.telephone_number}
                                        </a>
                                    </strong>
                                    <br/>
                                    <>
                                        {(() => {
                                            const vehicleColor = driver.vehicles[0]?.color;
                                            const colorValue = getColorByValue(vehicleColor);
                                            return (
                                                <div style={{display: 'flex', alignItems: 'center', marginTop: '6px'}}>
                                                    {driver.vehicles[0]?.make} {driver.vehicles[0]?.model}
                                                    <div
                                                        style={{
                                                            width: '12px',
                                                            height: '12px',
                                                            borderRadius: '10%',
                                                            backgroundColor: colorValue,
                                                            marginRight: '6px',
                                                            marginLeft: '6px',
                                                            border: "1px solid black"
                                                        }}
                                                    />
                                                    {vehicleColor}
                                                </div>
                                            );
                                        })()}
                                    </>
                                    [{driver.vehicles[0]?.license_plate}]<br/>
                                    <br/>
                                </div>
                            </div>
                        </InfoWindow>
                    )}
                </React.Fragment>
            );
        });
    };

    const renderHistoryLocationOnMap = () => {
        const getColorByScore = (score) => {
            // Normalize the score between 0 and 1 for color mapping
            const normalizedScore = Math.min(score, 1); // Normalize score to be within 0 to 1

            // Map the normalized score to RGB color
            const red = Math.floor(255 * normalizedScore);
            const green = Math.floor(255 * (1 - normalizedScore));
            const blue = 0; // Static blue component

            return `rgb(${red}, ${green}, ${blue})`;
        };

        const step = getStepByZoomLevel(zoomLevel);
        const filteredLocations = historyLocations.filter((_, index) => index % step === 0);

        return filteredLocations.map((location, index) => {
            const dotColor = getColorByScore(location?.performance_score);

            return (
                <Marker
                    key={index}
                    position={{
                        lat: location.latitude,
                        lng: location.longitude,
                    }}
                    icon={{
                        path: window.google.maps.SymbolPath.CIRCLE,
                        fillColor: dotColor,
                        fillOpacity: 1,
                        scale: 6,
                        strokeWeight: 0,
                    }}
                />
            );
        });
    };

    const renderDailyHistoryLocationMap = () => {
        if (dailyHistoryLocations.length === 0) return null;

        const step = getStepByZoomLevel(zoomLevel);
        const filteredLocations = dailyHistoryLocations.filter((_, index) => index % step === 0);

        const gradientColors = (i, total) => {
            const ratio = i / (total - 1);
            // Interpolating the RGB values between yellow (255, 255, 0) and purple (128, 0, 128)
            const red = Math.floor(255 * (1 - ratio) + 128 * ratio);
            const green = Math.floor(255 * (1 - ratio));
            const blue = Math.floor(128 * ratio);

            return `rgb(${red}, ${green}, ${blue})`;
        };

        const path = filteredLocations.map(location => ({
            lat: location.latitude,
            lng: location.longitude
        }));

        return filteredLocations.map((location, index) => {
            const dotColor = gradientColors(index, path.length);

            return (
                <Marker
                    key={index}
                    position={{
                        lat: location.latitude,
                        lng: location.longitude,
                    }}
                    icon={{
                        path: window.google.maps.SymbolPath.CIRCLE,
                        fillColor: dotColor,
                        fillOpacity: 1,
                        scale: 6,
                        strokeWeight: 0,
                    }}
                />
            );
        });
    };

    const handleDailyDriverHistoryClick = () => {
        setCurrentView('dailyHistory');
    };
    const handleOrderRouteClick = () => {
        setCurrentView('orderRoute');
    };
    const handleDriverHistoryClick = () => {
        setCurrentView('driverHistory');
    };

    return isLoaded ? (
        <div className="map-container">
            <MapLegend
                type={!currentView || display_driver_activity_legend ? 'driver_legend' :
                    currentView === 'dailyHistory' ? '24h_location_history_scatter_plot' :
                        currentView === 'driverHistory' ? 'speed_location_history_scatter_plot' :
                            currentView === 'orderRoute' ? 'order_route' :
                                ''
                }
            />

            {display_speed_location_history && (
                <div className="toggle-buttons">
                    <Button
                        className={`button-toggle_layers button-container ${currentView === 'orderRoute' ? 'selected' : 'default'} white-border`}
                        text="Order Route"
                        action={handleOrderRouteClick}
                        style={{borderTopLeftRadius: '8px'}}
                    />
                    <Button
                        className={`button-toggle_layers button-container ${currentView === 'driverHistory' ? 'selected' : 'default'} white-border`}
                        text="Driver History"
                        action={handleDriverHistoryClick}
                    />
                    <Button
                        className={`button-toggle_layers button-container ${currentView === 'dailyHistory' ? 'selected' : 'default'} white-border`}
                        text="Driver Daily History"
                        action={handleDailyDriverHistoryClick}
                        style={{borderTopRightRadius: '8px'}}
                    />
                </div>
            )}

            {display_24h_location_history && currentView === 'dailyHistory' && (
                <div className="date-picker-wrapper">
                    <DatePickerComponent
                        date={selectedDate}
                        setDate={(date) => {
                            setSelectedDate(date);
                            if (selectedDate && currentView === 'dailyHistory' && drivers.length > 0) {
                                fetchDailyDriverHistoryLocations(drivers[0].driver_id, selectedDate);
                            }
                        }}
                        title="Select Date"
                        placeholder="Select a date"
                        yellow={true}
                    />
                </div>
            )}

            <GoogleMap
                mapContainerStyle={containerStyle}
                center={currentLocation}
                zoom={zoomLevel}
                onZoomChanged={() => onZoomChanged(map, setZoomLevel)}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{
                    gestureHandling: 'cooperative'
                }}
            >
                {currentView === 'driverHistory' ? renderHistoryLocationOnMap() : currentView === 'dailyHistory' ? renderDailyHistoryLocationMap() : renderDriversMarkers()}
                {currentView !== 'driverHistory' && currentView !== 'dailyHistory' && directionsResponse && (
                    <DirectionsRenderer
                        directions={directionsResponse}
                        options={{
                            polylineOptions: {
                                strokeColor: Colors.yellow,
                                strokeWeight: 5,
                            },
                        }}
                    />
                )}
            </GoogleMap>
        </div>
    ) : (
        <></>
    );
};

export default Map;
