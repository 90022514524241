import React, { useEffect, useState } from 'react';
import Input from "./Input";
import '../css/Input.css';
import colors from "../../constants/colors";

const GeneralPicker = ({ items, onSelect, displayProperty, valueProperty, label, autofilter = false, searchInput = false, value, customStyle = {} }) => {

    const [selectedItem, setSelectedItem] = useState(value ? value : '');
    const [searchText, setSearchText] = useState('');
    const [filteredItems, setFilteredItems] = useState(items);

    useEffect(() => {
        if (filteredItems.length > 0 && autofilter) {
            setSelectedItem(filteredItems[0][valueProperty]);
            // onSelect(filteredItems[0]);
        }
    }, [filteredItems]);

    const getProperty = (obj, path) => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    };

    const formatDisplayLabel = (item) => {
        const properties = displayProperty.split(' ');
        return properties.map(prop => getProperty(item, prop)).filter(value => value).join(' ');
    };

    const handleSearch = (text) => {
        const filtered = items.filter(item => {
            const propertyValue = formatDisplayLabel(item);
            return propertyValue && propertyValue.toLowerCase().includes(text.toLowerCase());
        });
        setFilteredItems(filtered.length === 0 ?  items : filtered);
        setSearchText(text);
        if (autofilter) {
            onSelect(filtered[0]);
            setSelectedItem(filtered[0]?.[valueProperty] || '');
        }
    };

    const handleSelectionChange = (value) => {
        setSelectedItem(value);
        const selectedObject = items.find(item => item[valueProperty] === value);
        if (selectedObject) {
            onSelect(selectedObject);
        }
    };

    return (
        <div className="general-picker-container">
            {label && <p className={'p-label'}>{label}</p>}
            <div className="search-container" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {/* Input Component for search */}
                {searchInput && (
                    <Input
                        value={searchText}
                        placeholder="Search..."
                        onChange={(e) => handleSearch(e)}
                        style={{ width: '100%', marginRight: '10px', backgroundColor: colors.white, height: '34px', marginBottom: 0, color: colors.black }}
                    />
                )}

                {/* Dropdown Component */}
                <select
                    value={selectedItem}
                    onChange={(event) => handleSelectionChange(event.target.value)}
                    className="picker"
                    style={{ width: '100%', height: '34px', ...customStyle }}
                >
                    <option key="empty" value="" style={{ color: colors.text }}>Select...</option>
                    {(filteredItems.length === 0 ? items : filteredItems).map((item, index) => (
                        <option  style={{ color: colors.text }} key={`${item[valueProperty]}-${index}`} value={item[valueProperty]}>
                            {formatDisplayLabel(item)}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default GeneralPicker;
