import React, { useEffect, useState } from 'react';
import deliveryApi from '../../apis/deliveryApi';
import DynamicTable from "./DynamicTable";
import {formatDateTime, formatDateTimeShort} from "../../lib/dateUtils";

const DeliveryDriverCompletedOrders = ({ deliveryDriverId }) => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const fetchCompletedOrders = async () => {
            try {
                const response = await deliveryApi.getCompletedDeliveryOrdersByDeliveryDriverId(deliveryDriverId);
                if (response && !response.error) {
                    console.log(response, "orders");
                    setOrders(response);
                }
            } catch (error) {
                console.error('Error fetching completed orders:', error);
            }
        };

        fetchCompletedOrders();
    }, [deliveryDriverId]);

    const columnsConfig = {
        order_id: 'Order ID',
        delivery_address: 'Delivery Address',
        business_name: 'Business Name',
        delivery_cost: 'Delivery Cost',
        delivery_earnings: 'Earnings',
        distance: 'Distance',
        date: 'Date',
    };

    const parseOrderData = orders.map(order => {
        const deliveryAddress = order.delivery_location.address;
        const businessName = order.business?.name || 'N/A';
        const distance = order.details.distance;

        const parsedData = {
            order_id: order.order_id,
            delivery_address: deliveryAddress,
            business_name: businessName,
            delivery_cost: `${order.details.delivery_cost}€`,
            delivery_earnings: `${order.details.delivery_earnings}€`,
            distance: distance,
            date: formatDateTimeShort(order.created_at),
            ...order
        };

        return parsedData;
    });

    return (
        <div>
            <h3>Completed Delivery Orders</h3>
            <DynamicTable
                data={parseOrderData}
                columnsConfig={columnsConfig}
                rowsPerPage={12}
                extendable={true}
                viewType="delivery_order_view"
            />
        </div>
    );
};

export default DeliveryDriverCompletedOrders;