import React from 'react';
import '../css/MapLegend.css';
import {getLocalisedTexts} from "../../l10n/localisations/languages";

const MapLegend = ({type}) => {
    const l10nText = getLocalisedTexts('DRIVER_LEGEND');

    const renderDriverLegend = () => (
        <div className="driver-legend">
            <div className="legend-item">
                <span className="status-dot offline"></span> {l10nText.offline || 'Red: Driver is offline.'}
            </div>
            <div className="legend-item">
                <span className="status-dot online"></span> {l10nText.online || 'Green: Driver is online.'}
            </div>
            <div className="legend-item">
                <span className="status-dot on-order"></span> {l10nText.on_order || 'Yellow: Driver is on order.'}
            </div>
        </div>
    );

    const render24hDriverLocationHistoryLegend = () => (
        <div className="driver-legend" style={{bottom: -36}}>
            <div className="legend-item">
                <span className="status-dot yellow"></span> {l10nText.yellow || 'Yellow: Oldest location shared.'}
            </div>
            <div className="legend-item">
                <span className="status-dot purple"></span> {l10nText.purple || 'Purple: Newest location shared.'}
            </div>
        </div>
    );

    const renderDriverLocationSpeedScatterPlotLegend = () => (
        <div className="driver-legend" style={{bottom: -36}}>
            <div className="legend-item">
                <span className="status-dot red"></span> {l10nText.red || 'Red: Speed < 30 km/h.'}
            </div>
            <div className="legend-item">
                <span className="status-dot green"></span> {l10nText.green || 'Green: Speed > 30 km/h.'}
            </div>
        </div>
    );

    const renderOrderRouteLegend = () => (
        <div className="driver-legend" style={{bottom: -36}}>
            <div className="legend-item">
                <span className="status-dot pin"></span> {l10nText.route_stop || 'A, B, ...: Route stops.'}
            </div>
            <div className="legend-item">
                <div className="yellow-line"></div>
                {l10nText.yellow_line || 'Yellow line: Route path.'}
            </div>
        </div>
    );

    const renderLegend = () => {
        switch (type) {
            case 'driver_legend':
                return renderDriverLegend();
            case 'speed_location_history_scatter_plot':
                return renderDriverLocationSpeedScatterPlotLegend();
            case '24h_location_history_scatter_plot':
                return render24hDriverLocationHistoryLegend();
            case 'order_route':
                return renderOrderRouteLegend();
            default:
                return null; // No legend to display
        }
    };

    return (
        <div>
            {renderLegend()}
        </div>
    );
};

export default MapLegend;