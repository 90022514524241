import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageWrapper from '../components/js/PageWrapper';
import Header from '../components/js/Header';
import PageTabs from '../components/js/PageTabs';
import UserApi from '../apis/userApi';
import UserBasicInfo from "../components/js/UserBasicInfo";
import Reviews from '../components/js/Reviews';
import UserCompletedOrders from "../components/js/UserCompletedOrders";

const User = () => {
    const { user_id } = useParams();

    const tabs = ['BASIC_INFO', 'COMPLETED_ORDERS', 'REVIEWS'];

    const [userData, setUserData] = useState(null);
    const [currentTab, setCurrentTab] = useState('BASIC_INFO');
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await UserApi.getUserById(user_id);
                if (response && !response.error) {
                    setUserData(response);
                    const reviewsResponse = await UserApi.getReviewsByUserId(user_id);
                    setReviews(reviewsResponse);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [user_id]);

    const handleTabChange = (selectedTab) => {
        setCurrentTab(selectedTab);
    };

    return (
        <PageWrapper>
            <div className="body">
                <Header title={`User: ${userData?.first_name} ${userData?.last_name}`} />
                <PageTabs
                    tabs={tabs}
                    initialTab={currentTab}
                    onTabChange={handleTabChange}
                    pageContext="USER"
                />

                <div>
                    {currentTab === 'BASIC_INFO' && <UserBasicInfo userData={userData} />}
                    {currentTab === 'COMPLETED_ORDERS' && <UserCompletedOrders userId={user_id} />}
                    {currentTab === 'REVIEWS' && <Reviews reviews={reviews} />}
                </div>
            </div>
        </PageWrapper>
    );
};

export default User;