import axios from "../lib/axios";

const fetchAddressData = async (address) => {
    try {
        console.log(address, 'fetch data')
        const response = await axios.post('/google_maps/geocode_address', { address });
        console.log(response.data, 'fetch data')
        return response.data
    } catch (error) {
        console.error('Error fetching address data:', error);
    }
};

const GoogleMapsApi = {
   fetchAddressData
}
export default GoogleMapsApi;