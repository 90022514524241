import React from "react";
import moment from "moment";
import '../css/Documents.css';

function Documents(props) {
    const documents = props.documents;
    console.log("documents", documents);
    if (!documents) return null
    return <div className={"documents"} style={props.style}>
            {documents.map((document, index) => {
                return <div key={index} className={"document"}>
                    <h4 className={"document-title"}>{document.document_type}</h4>
                    <div>{document.expiration_date && <div className={"document-expiration"}>Expiration date: {moment(document.expiration_date).format("DD/MM/YYYY")}</div>}</div>
                    <div className={"document-files"}>
                        {document.files.map((file, index) => {
                            return <div key={index} className={"document-file"}>
                                {file.file_type === "IMAGE" && <a href={file.url} target="_blank" rel="noreferrer">
                                    <img src={file.url} alt={file.name} className={"file"}/>
                                </a>}
                                {file.file_type === "PDF" && <a href={file.url} target="_blank" rel="noreferrer">
                                    <iframe src={file.url} title={file.name} className={"file"}/>
                                </a>}
                            
                            </div>
                        })}
                    </div>
                </div>
            })}
    </div>
}

export default Documents;