import React, {useEffect, useState} from 'react';
import DynamicTable from "../components/js/DynamicTable";
import {formatDateTime} from "../lib/dateUtils";
import {getLostItems} from "../apis/lostItemApi";
import PageWrapper from "../components/js/PageWrapper";
import Header from "../components/js/Header";
import {renderLostAndFoundSquares} from "../components/js/DataSquare";
import {getLocalisedTexts} from "../l10n/localisations/languages";
import {LOST_ITEM_STATUS} from "../constants/enums";

const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

const LostAndFound = () => {
    const l10nText = getLocalisedTexts('USERS_LIST');
    const [lostItems, setLostItems] = useState([]);
    const [foundItems, setFoundItems] = useState([]);
    const [returnedItems, setReturnedItems] = useState([]);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const items = await getLostItems();
                setLostItems(items.filter(item => !item?.found && item?.status === LOST_ITEM_STATUS.REPORTED));
                setFoundItems(items.filter(item => item?.found && item?.status === LOST_ITEM_STATUS.REPORTED));
                setReturnedItems(items.filter(item => item?.status === LOST_ITEM_STATUS.RESOLVED));
            } catch (error) {
                console.error('Error fetching lost and found items:', error);
            }
        };

        fetchItems();
    }, []);

    const columnsConfig = {
        lost_item_id: 'ID',
        description: 'Description',
        reported_by: 'Reported By',
        created_at: 'Reported At',
        image: 'Image',
    };

    const parseItemData = (items) => items.map(item => {
        const firstImage = item.documents.length > 0 && item.documents[0].files.length > 0
            ? item.documents[0].files[0].url
            : '';

        return {
            lost_item_id: item?.lost_item_id,
            description: truncateText(item.description, 50),
            created_at: formatDateTime(item.created_at),
            reported_by: `${item.user.first_name} ${item?.user?.last_name}`,
            image: firstImage ?
                <img src={firstImage} alt="Item" style={{width: 100, height: 100, objectFit: 'cover'}}/> : 'No image',
            documents: item.documents,
            found: item.found,
            status: item.status,
            user: item.user,
            updated_at: formatDateTime(item.updated_at),
        };
    });

    const onStatusUpdate = (id) => {
        setReturnedItems(prevReturnedItems => {
            const updatedItem = [...lostItems, ...foundItems].find(item => item.lost_item_id === id);
            if (updatedItem) {
                updatedItem.status = LOST_ITEM_STATUS.RESOLVED;
            }
            return prevReturnedItems.concat(updatedItem);
        });

        setLostItems(prevLostItems => prevLostItems.filter(item => item.lost_item_id !== id));
        setFoundItems(prevFoundItems => prevFoundItems.filter(item => item.lost_item_id !== id));
    };

    const actionMap = {
        lost_items_view: {
            onStatusUpdate: onStatusUpdate
        },
    };

    return (
        <PageWrapper>
            <div className="body">
                <Header title="Lost And Found"/>

                {renderLostAndFoundSquares(l10nText, foundItems.length, lostItems.length)}
                <div>
                    <h3>Lost Items</h3>
                    <DynamicTable
                        data={parseItemData(lostItems)}
                        columnsConfig={columnsConfig}
                        rowsPerPage={10}
                        extendable={true}
                        viewType='lost_items_view'
                        actionMap={actionMap.lost_items_view}
                    />

                    <h3>Found Items</h3>
                    <DynamicTable
                        data={parseItemData(foundItems)}
                        columnsConfig={columnsConfig}
                        rowsPerPage={10}
                        extendable={true}
                        viewType='lost_items_view'
                        actionMap={actionMap.lost_items_view}
                    />

                    <h3>Resolved Items</h3>
                    <DynamicTable
                        data={parseItemData(returnedItems)}
                        columnsConfig={columnsConfig}
                        rowsPerPage={10}
                        extendable={true}
                        viewType='lost_items_view'
                        actionMap={actionMap.lost_items_view}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

export default LostAndFound;
