import axios from "../lib/axios";

const loadMerchantBusinesses = async (onResponse) => {
    try {
        const resp = await axios.get("/business/businesses/merchant")
        return resp.data;
    } catch (err) {
        console.error("Error loading merchant businesses:", err);
        return null;
    }
};

const loadMerchantBusinessesWithDailyMeals = async () => {
    try {
        const resp = await axios.get("/business/businesses/merchants/daily-meals");
        return resp.data;
    } catch (err) {
        console.error("Error loading merchant businesses with daily meals:", err);
        return null;
    }
};

const loadTaxiBusinesses = async (onResponse) => {
    try {
        const resp = await axios.get("/business/businesses/taxi", {
            onResponse
        });
        return resp.data;
    } catch (err) {
        console.error("Error loading taxi businesses:", err);
        return null;
    }
};

const getBusinessDocumentsByType = async (businessId, type) => {
    try {
        const resp = await axios.get(`documents/business/${businessId}/type/${type}`);
        return resp.data;
    } catch (err) {
        console.error("Error loading business documents:", err);
        return null;
    }
};

const reviewBusiness = async (review) => {
    try {
        const resp = await axios.post("/business/review", review);
        return resp.data;
    } catch (err) {
        console.error("Error reviewing business:", err);
        return null;
    }
};

const updateBusiness = async (data) => {
    try {
        const resp = await axios.patch("/business", data);
        return resp.data;
    } catch (err) {
        console.error("Error reviewing business:", err);
        return null;
    }
};

const manualSortScheduledUsers = async (data) => {
    try {
        const resp = await axios.post("/business/scheduled_users/sorting", {
            sorted_user_ids: data
        });
        return resp.data;
    } catch (err) {
        console.error("Error reviewing business:", err);
        return null;
    }
};

const addScheduledUserSortingType = async (data) => {
    try {
        const resp = await axios.post("/business/scheduled_users/sorting/type", {
            sorting_type: data
        });
        return resp.data;
    } catch (err) {
        console.error("Error reviewing business:", err);
        return null;
    }
};


const BusinessApi = {
    loadMerchantBusinesses,
    loadMerchantBusinessesWithDailyMeals,
    loadTaxiBusinesses,
    getBusinessDocumentsByType,
    reviewBusiness,
    updateBusiness,
    manualSortScheduledUsers,
    addScheduledUserSortingType
};

export default BusinessApi