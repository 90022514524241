import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import Sidebar from "./Sidebar";
import {setShowSidebar} from "../../store/slices/adminSlice";
import useWindowDimensions from '../../helpers/useWindowDimensions';
import colors from "../../constants/colors";

export let SIDEBAR_WIDTH = 220;

const Page = ({
                  relative = true,
                  centered = false,
                  fileUpload = false,
                  className = '',
                  style = {},
                  pageStyle = {},
                  onToggleSidebar = null,
                  children
              }) => {
    const dispatch = useDispatch();

    const showSidebar = useSelector(state => state.admin.showSidebar);
    const sideBarWidthDefault = showSidebar ? SIDEBAR_WIDTH : 0;
    const [sidebarWidth, setSidebarWidth] = useState(sideBarWidthDefault);
    const { height, width } = useWindowDimensions();
    let pageClasses = 'page';
    pageClasses += relative ? ' relative' : '';
    pageClasses += centered ? ' centered' : '';
    pageClasses += fileUpload ? ' file-upload' : '';

    useEffect(() => {
        // Retrieve the actual sidebar width from CSS variables after the component mounts
        const rootStyles = getComputedStyle(document.documentElement);
        const sidebarWidthPx = rootStyles.getPropertyValue('--sidebar-width').trim();
        SIDEBAR_WIDTH = parseInt(sidebarWidthPx, 10) || SIDEBAR_WIDTH; // Parse the number from the CSS variable

        setSidebarWidth(showSidebar ? SIDEBAR_WIDTH : 0);
    }, [showSidebar]);

    useEffect(() => {
        if (onToggleSidebar) {
            onToggleSidebar(showSidebar);
        }
        setSidebarWidth(showSidebar ? SIDEBAR_WIDTH : 0);
    }, [showSidebar, onToggleSidebar]);

    const toggleSidebar = () => dispatch(setShowSidebar(!showSidebar));
    console.log("width", width)
    return (
        <div className={className} style={style}>
            {showSidebar && <Sidebar display={showSidebar}/>}
            <div className={pageClasses} style={{...pageStyle, left: `${sidebarWidth}px`, width: `${width - sidebarWidth}px`}}>
                <div style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '36px', left: "15px"}}
                     onClick={toggleSidebar}>
                    <FontAwesomeIcon icon={faArrowLeft} size="lg" title="icon edit"
                                     rotation={!showSidebar ? 180 : undefined}/>
                </div>
                {children}
            </div>
        </div>
    );
};

export default Page;
