import React, { useState } from 'react';
import PageWrapper from '../components/js/PageWrapper';
import Header from '../components/js/Header';
import PageTabs from '../components/js/PageTabs';
import { useNavigation } from '../context/NavigationContext';
import DriversList from "../components/js/lists/DriversList";
import DriversDocumentsExpirationList from "../components/js/lists/DriversDocumentsExpirationList";
import DriversEarningsList from "../components/js/lists/DriversEarningsList";
import DriversDocumentsList from '../components/js/lists/DriversDocumentsList';
const Drivers = () => {
    const { onNavigateTo } = useNavigation();

    const tabs = ['DRIVERS_LIST', 'DRIVERS_EARNINGS', 'DRIVERS_DOCUMENTS_EXPIRATION'];
    const [currentTab, setCurrentTab] = useState(tabs[0]);

    const handleTabChange = (selectedTab) => {
        setCurrentTab(selectedTab);
    };

    return (
        <PageWrapper>
            <div className="body">
                <Header title="Dashboard" />
                <PageTabs
                    tabs={tabs}
                    initialTab={currentTab}
                    onTabChange={handleTabChange}
                    pageContext="DASHBOARD"
                />

                <div className="custom-row">

                    {currentTab === 'DRIVERS_LIST' && (
                        <div>
                            <DriversList />
                        </div>
                    )}
                    {currentTab === 'DRIVERS_EARNINGS' && (
                        <div>
                            <DriversEarningsList />
                        </div>
                    )}
                    {currentTab === 'DRIVERS_DOCUMENTS_EXPIRATION' && (
                        <div>
                            <DriversDocumentsExpirationList />
                        </div>
                    )}
                </div>
            </div>
        </PageWrapper>
    );
};

export default Drivers;
