import axios from "../lib/axios";

// Register a new driver
const registerDriver = async (
    firstName,
    lastName,
    dateOfBirth,
    email,
    password,
    phone,
    telephoneCode,
) => {
    try {
        const resp = await axios.post("/drivers/create", {
            first_name: firstName,
            last_name: lastName,
            date_of_birth: dateOfBirth,
            email: email,
            password: password,
            telephone: phone,
            telephone_number: phone,
            telephone_code: telephoneCode,
            user_role: 'DRIVER'
        });
        return resp.data;
    } catch (err) {
        return false;
    }
};
const listDrivers = async () => {
    try {
        const resp = await axios.get("/drivers");
        return resp.data;
    } catch (err) {
        return err;
    }
};

const listOnlineDrivers = async () => {
    try {
        const resp = await axios.get("/drivers/online");
        return resp.data;
    } catch (err) {
        return false;
    }
};

const getAvailableDrivers = async () => {
    try {
        const resp = await axios.get("/drivers/available");
        return resp.data;
    } catch (err) {
        return false;
    }
};

const getDriverById = async (driverId) => {
    try {
        const resp = await axios.get(`/drivers/${driverId}`);
        return resp.data;
    } catch (err) {
        return false;
    }
};

const getDriverLocation = async (driverId) => {
    try {
        const resp = await axios.get(`/drivers/${driverId}/location`);
        return resp.data;
    } catch (err) {
        return false;
    }
};

const updateDriver = async (driver) => {
    try {
        const resp = await axios.patch("/drivers", { ...driver });
        return resp.data;
    } catch (err) {
        return false;
    }
};

const editDriver = async (data) => {
    try {
        const resp = await axios.patch("/drivers/edit", data);
        return resp.data;
    } catch (err) {
        return false;
    }
};

const updateDriverLocation = async (driverId, location) => {
    try {
        const resp = await axios.patch("/drivers/location", {
            driver_id: driverId,
            location: location,
        });
        return resp.data;
    } catch (err) {
        return false;
    }
};

const updateDriverOnlineStatus = async (driverId, isOnline) => {
    try {
        const resp = await axios.patch(`/drivers/online`, {
            driver_id: driverId,
            online: isOnline,
        });
        return resp.data;
    } catch (err) {
        console.error("Error updating driver's online status:", err);
        return false;
    }
};

const getDriverByUserId = async (userId) => {
    try {
        const resp = await axios.get(`/drivers/${userId}`);
        return resp.data;
    } catch (err) {
        return false;
    }
}

export const updateDriverRideRequirements = async (driver_id, rideRequirements) => {
    try {
        const response = await axios.patch('/drivers/ride_requirements', {
            driver_id: driver_id,
            ride_requirements: rideRequirements});
        return response.data;
    } catch (error) {
        return error;
    }
};

const getDrivers = async () => {
    try {
        const resp = await axios.get("/drivers/full");
        return resp.data;
    } catch (err) {
       throw err;
    }
};

const getDriverHistoryLocations = async (driver_id, start_time, end_time) => {
    try {
        const resp = await axios.get(`/drivers/${driver_id}/history_location/${start_time}/${end_time}`);
        return resp.data;
    } catch (err) {
        throw err;
    }
}


const DriverApi = {
    registerDriver,
    updateDriverOnlineStatus,
    listDrivers,
    listOnlineDrivers,
    getAvailableDrivers,
    getDriverById,
    getDriverLocation,
    updateDriver,
    updateDriverLocation,
    getDriverByUserId,
    updateDriverRideRequirements,
    getDrivers,
    getDriverHistoryLocations,
    editDriver
}
export default DriverApi;