import React from 'react';
import Button from './Button';
import '../css/Numbering.css';

const Numbering = ({ title, value, onUpdate, green, red, yellow, disabled, preview }) => {
    const getPlusColor = () => {
        if (green) return '#7BAE37';
        if (yellow) return '#FCCB1F';
        if (red) return '#C90000';
        return 'black';
    };

    const getMinusColor = () => {
        if (green) return 'rgba(123,174,55,0.5)';
        if (yellow) return 'rgba(252,203,31,0.37)';
        if (red) return 'rgba(201,0,0,0.33)';
        return 'black';
    };

    const increment = () => {
        onUpdate(value + 1);
    };

    const decrement = () => {
        if (value > 0) {
            onUpdate(value - 1);
        }
    };

    return (
        <div className="numbering-container">
            <span className="numbering-title">{title}</span>
            <div className={`numbering-buttons-container ${preview ? 'preview' : ''}`}>
                {!preview && (
                    <Button
                        text="-"
                        style={{
                            backgroundColor: getMinusColor(),
                            width: '25px',
                            height: '25px',
                            borderRadius: '100%',
                            marginLeft: '10px',
                            color: 'white',
                        }}
                        action={decrement}
                    />
                )}
                <span className="numbering-number">{value}</span>
                {!preview && (
                    <Button
                        text="+"
                        style={{
                            backgroundColor: !disabled ? getPlusColor() : getMinusColor(),
                            width: '25px',
                            height: '25px',
                            borderRadius: '100%',
                            color: 'white',
                        }}
                        action={increment}
                    />
                )}
            </div>
        </div>
    );
};

export default Numbering;
