import React, { useState } from "react";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import CustomModal from "./CustomModal";
import {isValidPhoneNumber} from "../../lib/helpers";
import './../css/Input.css'

const PhoneInputComponent = (props) => {
    const [phone, setPhone] = useState(props?.value || '');
    const [countryCode, setCountryCode] = useState(props?.inputCode ? props?.inputCode.toLowerCase() : "si");
    const [dialCode, setDialCode] = useState('');
    const [modal, setModal] = useState(null);

    const setTelephone = () => {
        const phoneWithoutCode = phone.replace(dialCode, '').trim();
        return { phoneWithoutCode, dialCode }
    };

    const handlePhoneChange = (value, country) => {
        setPhone(value);
        setCountryCode(country.country.iso2);
        const dialCode = country.inputValue.split(" ")[0];
        setDialCode(dialCode);
    };

    const handleBlur = () => {
        if (!phone) {
            setModal('empty-phone');
            return;
        }

        if (!isValidPhoneNumber(phone)) {
            setModal('invalid-phone');
            return;
        }

        const { phoneWithoutCode, dialCode } = setTelephone(phone);
        props?.setCountryCode(countryCode)
        props?.setFullTelephone(phone)
        props?.setTelephone(phoneWithoutCode)
        console.log(countryCode, phone, phoneWithoutCode, dialCode)
    };

    return (
        <div className="phone-input-container">

            {props.label && <p className={'p-label'}>{props.label}</p>}

            <PhoneInput
                defaultCountry={countryCode}
                value={phone}
                onChange={handlePhoneChange}
                onBlur={handleBlur}
                placeholder={props.placeholder || "Enter phone number"}
                className="phone-input"
            />

            <CustomModal
                visible={modal === 'empty-phone'}
                title="Validation Error"
                description="Phone number cannot be empty!"
                button1Text="OK"
                button1CallAction={() => setModal(null)}
                onClose={() => setModal(null)}
            />

            <CustomModal
                visible={modal === 'invalid-phone'}
                title="Validation Error"
                description="Phone number is not valid!"
                button1Text="OK"
                button1CallAction={() => setModal(null)}
                onClose={() => setModal(null)}
            />
        </div>
    );
};

export default PhoneInputComponent;
