import React, {useEffect, useState} from 'react';
import {getLocalisedTexts} from "../../l10n/localisations/languages";
import {renderDashboardTaxiDataSquares, renderDriversEarningsListDataSquares} from "../../components/js/DataSquare";
import driverApi from "../../apis/driverApi";
import taxiApi from "../../apis/taxiApi";
import {DELIVERY_ORDER_STATUS, TAXI_ORDER_STATUS} from "../../constants/enums";
import deliveryDriverApi from "../../apis/deliveryDriverApi";
import deliveryApi from "../../apis/deliveryApi";

const DeliveryDashboardTab = ({daily_meals = false}) => {
    const l10nText = getLocalisedTexts('DASHBOARD');

    const [drivers, setDrivers] = useState([]);
    const [activeDrivers, setActiveDrivers] = useState(0);
    const [minimumActiveDrivers, setMinimumActiveDrivers] = useState(0);
    const [totalRegisteredDrivers, setTotalRegisteredDrivers] = useState(0);
    const [completedRidesToday, setCompletedRidesToday] = useState(0);
    const [todaysEarnings, setTodaysEarnings] = useState(0);
    const [weeklyEarnings, setWeeklyEarnings] = useState(0);
    const [monthlyEarnings, setMonthlyEarnings] = useState(0);
    const [totalEarnings, setTotalEarnings] = useState(0);

    useEffect(() => {
        async function fetchActiveDeliveryDrivers() {
            try {
                const drivers = await deliveryDriverApi.listOnlineDrivers()
                if (drivers && !drivers.error) {
                    setActiveDrivers(drivers.length);
                    if (minimumActiveDrivers < activeDrivers) {
                        setMinimumActiveDrivers(drivers.length)
                    }
                } else {
                    setActiveDrivers(0);
                }
            } catch (e) {
                console.error('Error fetching drivers:', e);
                setActiveDrivers(0);
            }
        }

        const fetchRegisteredDrivers = async () => {
            try {
                const drivers = await deliveryDriverApi.listDeliveryDrivers()
                if (drivers && !drivers.error) {
                    setDrivers(drivers)
                    setTotalRegisteredDrivers(drivers.length);
                } else {
                    setDrivers([])
                    setTotalRegisteredDrivers(0);
                }
            } catch (e) {
                console.error(e);
                setTotalRegisteredDrivers(0);
            }
        };

        const fetchDeliveryOrders = async () => {
            try {
                const orders = await deliveryApi.getDeliveryOrders()
                if (orders && !orders.error) {
                    let completedOrders = orders.filter(order => order.status === DELIVERY_ORDER_STATUS.COMPLETED);

                    completedOrders = completedOrders.filter(order => order?.is_daily_meal === daily_meals);

                    const now = new Date();
                    const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
                    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);

                    let todaysEarnings = 0;
                    let weeklyEarnings = 0;
                    let monthlyEarnings = 0;
                    let totalEarnings = 0;
                    let completedRidesToday = 0;

                    completedOrders.forEach(order => {
                        const timeline = order.timeline.find(item => item.status === DELIVERY_ORDER_STATUS.COMPLETED);
                        const timestamp = timeline?.location?.timestamp;

                        if (timestamp) {
                            const orderDate = new Date(timestamp);
                            const daysDiff = Math.floor((now - orderDate) / (1000 * 60 * 60 * 24));
                            const weeksDiff = Math.floor(daysDiff / 7);
                            const monthsDiff = Math.floor(daysDiff / 30);

                            const orderAmount = (parseFloat(order.details.delivery_cost) + (parseFloat(order.details.delivery_earnings) || 0)) || 0;

                            totalEarnings += orderAmount;

                            if (orderDate >= startOfDay && orderDate <= endOfDay) {
                                completedRidesToday += 1;
                                todaysEarnings += orderAmount;
                            }

                            if (weeksDiff === 0) {
                                weeklyEarnings += orderAmount;
                            }

                            if (monthsDiff === 0) {
                                monthlyEarnings += orderAmount;
                            }
                        }
                    });

                    setTodaysEarnings(todaysEarnings.toFixed(2));
                    setWeeklyEarnings(weeklyEarnings.toFixed(2));
                    setMonthlyEarnings(monthlyEarnings.toFixed(2));
                    setTotalEarnings(totalEarnings.toFixed(2));
                    setCompletedRidesToday(completedRidesToday);
                }
            } catch (error) {
                console.error('Error fetching delivery orders:', error);
            }
        };

        fetchDeliveryOrders()
        fetchRegisteredDrivers()
        fetchActiveDeliveryDrivers();

        const intervalId = setInterval(() => {
            fetchActiveDeliveryDrivers();
            fetchRegisteredDrivers();
            fetchDeliveryOrders()
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);


    return (
        <div>
            <div className="list-header">
                {renderDashboardTaxiDataSquares(
                    l10nText,
                    {margin: 0, zoom: 0.9, marginRight: -4},
                    minimumActiveDrivers,
                    activeDrivers,
                    totalRegisteredDrivers,
                    completedRidesToday,
                    drivers)}
                |
                {renderDriversEarningsListDataSquares(
                    l10nText,
                    {margin: 0, zoom: 0.9, marginLeft: 8},
                    todaysEarnings, weeklyEarnings, monthlyEarnings, totalEarnings
                )}
            </div>
            <div className="list-header">
            </div>
        </div>
    );
};

export default DeliveryDashboardTab;
