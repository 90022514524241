export const en = {
    CRM: {
        EDIT: 'edit',
        SAVE: 'save',
        PLACEHOLDER_EMAIL: 'E-mail',
        PLACEHOLDER_PASSWORD: 'Password',
        COPIED: 'Link copied',
        LOGIN_EMAIL: 'Enter your email',
        LOGIN_PASSWORD: 'Enter password',
        LOGIN_REMEMBER: 'Remember me',
        LOGIN_BUTTON: 'SIGN IN',
        NEW_PASSWORD: 'Enter new password',
        RESET_PASSWORD_BUTTON: 'RESET PASSWORD',
        PASSWORD_SUCCESSFULLY_RESET: 'Password successfully reset',
        PASSWORD_RESET_LINK_EXPIRED: 'The password reset link has expired. Please request a new link.',
        PASSWORD_INVALID: 'Password is not valid. Please enter only letters and numbers, without special characters.'
    },
    PAGE_TABS: {
        DASHBOARD: {
            DASHBOARD: 'Dashboard',
            DRIVERS_LIST: 'Drivers List',
            DRIVERS_EARNINGS: 'Drivers Earnings',
            DRIVERS_DOCUMENTS: 'Drivers Documents',
            DRIVERS_DOCUMENTS_EXPIRATION: 'Drivers Documents Expiration',
            DELIVERY_DRIVERS_LIST: 'Delivery Drivers List',
            DELIVERY_DRIVERS_EARNINGS: 'Delivery Drivers Earnings',
            DELIVERY_DRIVERS_DOCUMENTS: 'Delivery Drivers Documents',
            DELIVERY_DRIVERS_DOCUMENTS_EXPIRATION: 'Delivery Drivers Documents Expiration',
            DAILY_MEALS: 'Daily meals',

        },
        DRIVER: {
            BASIC_INFO: 'Basic Info',
            COMPLETED_ORDERS: 'Completed Orders',
            REVIEWS: 'Reviews',
            DOCUMENTS: 'Documents',
            DOCUMENTS_EXPIRATION_DATES: 'Documents Expiration Dates',
            EDIT_DRIVER: 'Edit Driver',
        },
        USERS: {
            USERS_LIST: 'Users List',
            PREVIEW: 'Preview'
        },
        USER: {
            USERS_LIST: 'Users List',
            PREVIEW: 'Preview',
            BASIC_INFO: 'Basic Information',
            COMPLETED_ORDERS: 'Completed Orders',
            REVIEWS: 'Reviews'
        }
    },
    DASHBOARD: {
        title: 'Dashboard',
        minimum_active_drivers: 'Minimum Active Drivers',
        active_drivers: 'Active Drivers',
        total_registered_drivers: 'Total Number Of Drivers',
        completed_rides_today: 'Completed Rides Today',
        total_earnings_today: 'Total Earnings For Today',
        on_order: 'Active Drivers on Order'
    },
    DRIVER_LIST: {
        title: 'Drivers List',
        activity: 'Online',
        status: 'Status',
        license_number: 'License number',
        driver: 'Driver',
        date_of_birth: 'Date of Birth',
        telephone: 'Telephone',
        vehicle_type: 'Vehicle type',
        vehicle_make_model: 'Vehicle Make/Model',
        vehicle_color: 'Vehicle Color',
        vehicle_license_plate: 'Vehicle license plate',
        class: 'Category of driver',
        active: 'Active',
        online: 'Online/Offline',
        completed_drives: "Today's Completed Drives",
        earned_today: 'Earned Today',
    },
    USERS_LIST: {
        title: 'Users List',
        name: 'Name',
        email: 'Email',
        phone: 'Phone',
        status: 'Status',
        active: 'Active',
    },
    DRIVER_EARNINGS: {
        delivery_driver: 'Delivery Driver',
        title: 'Driver Earnings',
        activity: 'Activity',
        license_number: 'License number',
        driver: 'Driver',
        number_of_rides: 'Number of Rides',
        hourly_earnings: 'Hourly Earnings',
        distance_time_travelled: 'Distance and Time Travelled',
        earnings_fee: 'Earnings Fee',
        todays_earnings: "Today's Earnings",
        weekly_earnings: 'Weekly Earnings',
        monthly_earnings: 'Monthly Earnings',
        total_earnings: 'Total Earnings',
    },
    DRIVER_DOCUMENTS: {
        title: 'Driver Documents Expiration Dates',
        car_number_plate: 'Car Number Plate',
        courier: 'Courier',
        phone_number: 'Phone Number',
        drivers_license: 'Driving License',
        vehicle_registration: 'Vehicle Registration',
        permit: 'Permit',
        technical_assignment: 'Technical Assessment',
    },
    TABLE_LEGEND: {
        valid: 'Green: Document is valid and up to date.',
        expiring_soon: 'Yellow: Document validity will expire in 30 days.',
        expiring_very_soon: 'Orange: Valid for 5 more days.',
        expired: 'Red: Document is invalid and needs immediate attention.',
    },
    DRIVER_LEGEND: {
        online: 'Green: Driver is online.',
        on_order: 'Yellow: Driver is on order.',
        offline: 'Red: Driver is offline.',
    },
    CUSTOM_MODAL: {
        driverRejected: {
            title: 'Order Rejected',
            description: {
                default: 'The current taxi driver rejected the order.',
                withReason: 'The current taxi driver rejected the order due to:',
            }
        },
        rideRequirements: {
            title: 'No drivers available',
            description: 'Due to your order\'s specific ride requirements, no drivers are available. Would you be okay with default ride preferences regarding vehicle class and category?',
        },
        driverCanceled: {
            title: 'Driver Canceled',
            description: {
                default: 'The current taxi driver canceled. A new driver is being delegated for your order.',
                withReason: 'The current taxi driver canceled due to:',
                newDriver: 'A new driver is being delegated for your order.',
            },
        },
        taxiTypeModal: {
            title: 'No drivers available',
            description: 'Currently, no drivers meet your specific preferences.\nChoose one of the following options:\n',
            button1Text: 'Select Available Taxi',
            button2Text: 'Reset Vehicle Preferences',
            or: 'OR',
        },
        confirmDriverSelection: {
            title: 'Confirm Driver Selection',
            description: (driver) =>
                driver && driver.driver_id
                    ? `You have selected ${driver.user.first_name} ${driver.user.last_name} with the following details:\n\n` +
                    `• Vehicle: ${driver.vehicles[0]?.make} ${driver.vehicles[0]?.model} (${driver.vehicles[0]?.color}), ${driver.vehicles[0]?.category}\n` +
                    `• Capacity: ${driver.vehicles[0]?.capacity}\n` +
                    `• Estimated Cost: $${driver.estimates?.estimated_cost}\n` +
                    `• Estimated Time: ${driver.estimates?.estimated_time} minutes\n\n` +
                    `Do you want to confirm this driver?`
                    : '',
        },
        pleaseWait: {
            title: 'Processing Your Request',
            description: 'Your selection is being processed. Please wait a moment while we connect you with a driver who will be with you shortly. Thank you for your patience!',
        },
    },
    COLORS: {
        S0B: "plain - WHITE - LIGHT",
        E0B: "metallic - WHITE - LIGHT",
        S0M: "plain - WHITE - MEDIUM",
        E0M: "metallic - WHITE - MEDIUM",
        S0D: "plain - WHITE - DARK",
        E0D: "metallic - WHITE - DARK",
        S1B: "plain - YELLOW - LIGHT",
        E1B: "metallic - YELLOW - LIGHT",
        S1M: "plain - YELLOW - MEDIUM",
        E1M: "metallic - YELLOW - MEDIUM",
        S1D: "plain - YELLOW - DARK",
        E1D: "metallic - YELLOW - DARK",
        S2B: "plain - ORANGE - LIGHT",
        E2B: "metallic - ORANGE - LIGHT",
        S2M: "plain - ORANGE - MEDIUM",
        E2M: "metallic - ORANGE - MEDIUM",
        S2D: "plain - ORANGE - DARK",
        E2D: "metallic - ORANGE - DARK",
        S3B: "plain - RED - LIGHT",
        E3B: "metallic - RED - LIGHT",
        S3M: "plain - RED - MEDIUM",
        E3M: "metallic - RED - MEDIUM",
        S3D: "plain - RED - DARK",
        E3D: "metallic - RED - DARK",
        S4B: "plain - PURPLE - LIGHT",
        E4B: "metallic - PURPLE - LIGHT",
        S4M: "plain - PURPLE - MEDIUM",
        E4M: "metallic - PURPLE - MEDIUM",
        S4D: "plain - PURPLE - DARK",
        E4D: "metallic - PURPLE - DARK",
        S5B: "plain - BLUE - LIGHT",
        E5B: "metallic - BLUE - LIGHT",
        S5M: "plain - BLUE - MEDIUM",
        E5M: "metallic - BLUE - MEDIUM",
        S5D: "plain - BLUE - DARK",
        E5D: "metallic - BLUE - DARK",
        S6B: "plain - GREEN - LIGHT",
        E6B: "metallic - GREEN - LIGHT",
        S6M: "plain - GREEN - MEDIUM",
        E6M: "metallic - GREEN - MEDIUM",
        S6D: "plain - GREEN - DARK",
        E6D: "metallic - GREEN - DARK",
        S7B: "plain - GRAY - LIGHT",
        E7B: "metallic - GRAY - LIGHT",
        S7M: "plain - GRAY - MEDIUM",
        E7M: "metallic - GRAY - MEDIUM",
        S7D: "plain - GRAY - DARK",
        E7D: "metallic - GRAY - DARK",
        S8B: "plain - BROWN - LIGHT",
        E8B: "metallic - BROWN - LIGHT",
        S8M: "plain - BROWN - MEDIUM",
        E8M: "metallic - BROWN - MEDIUM",
        S8D: "plain - BROWN - DARK",
        E8D: "metallic - BROWN - DARK",
        S9B: "plain - BLACK - LIGHT",
        E9B: "metallic - BLACK - LIGHT",
        S9M: "plain - BLACK - MEDIUM",
        E9M: "metallic - BLACK - MEDIUM",
        S9D: "plain - BLACK - DARK",
        E9D: "metallic - BLACK - DARK"
    },
    ALLERGENS: {
        1: "Gluten",
        2: "Crustaceans",
        3: "Eggs",
        4: "Fish",
        5: "Peanut",
        6: "Soy",
        7: "Milk",
        8: "Nut",
        9: "Celery",
        10: "Mustard",
        11: "Sesame",
        12: "Sulphite",
        13: "Lupin",
        14: "Molluscs"
    },
    RESPONSES: {
        errorUnexpected: "An unexpected error occurred. Please try again.",
        passwordRequirements: "Your password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one number.",
        passwordsDoNotMatch: "Passwords do not match.",
    },
    ADD_CONTACT: {
        failedToSendCode: 'Failed to send verification code. Please try again.',
        invalidVerificationCode: 'Invalid verification code. Please try again.',
        resetPasswordSuccessText: "We have sent you an email with instructions on how to reset your password.",
        resetPassword: 'Reset password',
        requestPasswordReset: 'Request password reset',
        failedToVerify: 'Failed to verify code. Please try again.',
        enterCode: "Enter code",
        enterPhone: "Enter phone",
        verifyCode: "Verify Code",
        sendCode: 'Send Code',
        thankYou: 'Thank you!',
        close: 'Close',
        yourPhoneIsVerified: 'Your phone number has been verified.',
        verifyPhone: 'Verify Phone Number',
        vehicleClass: 'Select vehicle class',
        vehicleCategory: 'Select vehicle category',
        restaurantTitle: 'Add Contact Personnel',
        taxiTitle: 'Add Drivers',
        transferTitle: 'Add Drivers',
        addContact: 'Add Contact',
        addDriver: 'Add Driver',
        companyRole: 'Company role',
        fName: "First Name",
        lName: "Last Name",
        phoneNumber: "Phone Number",
        email: 'email',
        operatingAddress: 'operatingAddress',
        birth: 'Date of birth',
        day: 'Day',
        month: 'Month',
        year: 'Year'
    },
    GENERAL: {
        standard: 'standard',
        confirm: "Confirm",
        continue: "Continue",
        cancel: "Cancel",
        save: "Save",
        rate: "Rate",
        skip: "Skip",
        finish: "Finish",
        checkout: "Go to checkout",
        select: "Select",
        addYourAddress: "Add your address",
        ok: "Ok",
        writeYourMessage: "Write your message here...",
        close: "Close",
        remove: "Remove",
        signOut: "Sign out",
        singIn: "Sign in",
        signUp: "Sign up",
        yes: "Yes",
        no: "No",
        male: "Male",
        female: "Female",
        request_sms_code: "Request Code"
    },
    LANGUAGE_SELECT: {
        title: 'Choose your language',
        confirm: 'Select'
    },
    SIGN_UP: {
        title: "Sign up",
        fName: "First Name",
        lName: "Last Name",
        email: "Email",
        phoneNumber: "Phone Number",
        password: "Password",
        signUp: "Sign up",
        signIn: "Sign in",
        existingAccount: "Already have an account?",

    },
    SIGN_IN: {
        rememberMe: "Remember me",
        validationError: 'Validation Error',
        enterValidData: 'Please enter a valid email and password',
        title: 'Sign-In',
        emailOrPhone: 'Email or Telephone',
        password: 'Password',
        signIn: 'Sign in',
        signUp: 'Sign up',
        noAccount: "Don't have an account?",
        forgotPassword: "Forgot password?"
    },
    REGISTRATION: {
        title: 'Welcome',
        profilePicture: 'Add profile picture',
        address: 'Address (optional)',
        city: 'City (optional)',
        options: 'Select your options',
        pet: 'Traveling with a pet',
        luggage: 'I have luggage',
        ac: 'Air conditioning',
        child: 'Child Seats',
        wheelchair: 'Wheelchair accessibility',
        noRadio: 'Silent ride',
        assistance: 'Passenger assistance',
        saveButton: 'Save'
    },
    CANCEL_ORDER: {
        title: "Cancel Order",
        description: "Please select a reason for canceling the order:",
        confirmButton: "Confirm",
        cancelButton: "Cancel",
        cancelOrderButton: "Cancel Order",
        cancellationReasonTitle: "Cancellation Reason",
        reasons: {
            changed_mind: "Changed my mind",
            found_alternative: "Found an alternative",
            driver_too_long: "Driver is taking too long",
            incorrect_pickup: "Incorrect pickup location",
            other: "Other"
        },
        taxiCanceledTitle: "Order Canceled by Klikni Taxi",
    },
    CREATE_ORDER: {
        title: "Create New Order",
        description: "Please enter the user's telephone number to create a new order:",
        confirmButton: "Confirm",
        cancelButton: "Cancel",
        phoneNumberLabel: "Telephone Number",
        phoneNumberPlaceholder: "Enter telephone number"
    },
    NOTIFICATION_PREFERENCES: {
        titleWhich: "Which notifications would you like to receive?",
        requests: "Ride Requests",
        acceptance: "Ride Acceptance",
        driverArrival: "Driver Arrival",
        completion: "Ride Completion",
        promos: "Promotions and Discounts*",
        ratings: "Ratings and Feedback",
        news: "News and Updates",
        titleHow: "How would you like to be notified?",
        telephone: "Telephone",
        whatsapp: "Whatsapp",
        viber: "Viber",
        telegram: "Telegram",
        wechat: "WeChat",
        saveButton: "Save"
    },
    TAXI_SELECTION_MAP: {
        titleWhere: "Where would you like to go?",
        titleAddStopover: "Add a stop",
        stopover: "Stop location",
        stopoverPlaceholder: "Set a stop location",
        pickUp: "Pick-up location",
        pickUpPlaceholder: "Set pick-up location",
        dropOff: "Drop-off location",
        dropOffPlaceholder: "Set drop-off location",
        continueButton: "Continue",
        estimates: "Estimates",
        cca: "cca.",
        travelTime: "travel time",
        price: "price",
        enterpickupLocation: "Enter pick-up location",
        deleteStop: "Delete selected stop?",
        sureToDelete: "Do you really want to delete this stop?",
        deleteText: "Delete",
        addressNotRecognized: "Address not recognized",
        provideValid: "Please provide a valid address",
        addStop: "Add a stop",
    },
    TAXI_SELECTION_MODAL: {
        title: "Taxi in your area",
        estTime: "Est. time to arrival",
        stationWagon: "Station wagon",
        sedan: "Sedan",
        minivan: "Minivan",
        suv: "SUV",
    },
    BOOK_TAXI: {
        taxiOnItsWay: 'Taxi is on its way',
        title: "Choose ride requirements",
        titleReview: "Review ride requirements",
        numAdults: "Number of adults",
        numChildrenSmall: "Number of children (<140cm)",
        numChildrenBig: "Number of children (>140cm)",
        requirements: "Requirements",
        scheduleRideLabel: "Do you want to schedule a taxi ride?",
        scheduleRide: "Schedule ride",
        findTransferButton: "Find transfer",
        luggage: "Luggage",
        children: "Children",
        wheelchair: "Wheelchair accessibility",
        pet: "Pet friendly",
        ac: "Air conditioning",
        noRequirements: "No extra requirements were set.",
        departureDate: "Set departure date",
        departureDatePlaceholder: "Departure date",
        departureTime: "Set departure time",
        departureTimePlaceholder: "Departure time",
        repeatRide: "REPEAT RIDE",
        doNotRepeat: "Do not repeat",
        monday: "Every Monday",
        tuesday: "Every Tuesday",
        wednesday: "Every Wednesday",
        thursday: "Every Thursday",
        friday: "Every Friday",
        saturday: "Every Saturday",
        sunday: "Every Sunday",
        repeatDuration: "Repeat duration",
        oneWeek: "1 week",
        twoWeeks: "2 week",
        threeWeeks: "3 week",
        oneMonth: "1 month",
        backButton: "Back",
        requestRideButton: "Request taxi ride",
        timeArrival: "Time to arrival (cca)",
        travelTime: "Travel time",
        cca: "cca"
    },
    PENDING_RIDE: {
        cca: "cca.",
        travelTime: "Travel time",
        timeArrival: "Time to arrival",
        cancellation: "In the case of a cancellation we will bill you 10€",
        confirmButton: "Confirm",
    },
    TAXI_RIDE: {
        labelArrived: "Your taxi has arrived",
        labelThanks: "Thank you for riding with us",
        labelDriving: "Driving to next destination",
        taxiRide: "Taxi ride",
        labelTaxiWaiting: "Your taxi has arrived",
        labelTaxiAccepted: "Taxi is driving to your location. Be ready.",
    },
    TAXI_RIDE_BOTTOM: {
        didYouEnjoy: 'Did you enjoy your taxi experience?',
        drivingSkills: "Driving Skills",
        professionalism: "Professionalism",
        vehicleCondition: "Vehicle Condition",
        knowledgeRoutes: "Knowledge of Routes",
        customerService: "Customer Service",
        punctuality: "Punctuality",
        opinionPlaceholder: "Write your opinion here...",
        rate: "Rate",
        skip: "Skip",
        totalCost: "Total cost",
        tip: "Tip",
        pay: "Pay",
        cancel: "Cancel",
        modalTitle: "If you enjoyed your experience, consider leaving a tip to show your appreciation. Your generosity is greatly appreciated.?",
    },
    PAYMENT_SELECT: {
        enterExactAmount: 'Enter exact amount',
        selectPayment: "Select payment",
        cash: "Cash",
        exactAmount: 'I will pay with exact amount.',
        changeNeeded: 'Change needed.',
        tapToAddValues: 'Tap to add values.',
        amountDue: 'Amount due:',
        change: 'Amount of change you will receive back:'
    },
    RECEIPT: {
        thankYou: "Thank you for riding with us",
        thankYouDelivery: "Thank you for your order",
        pickup: "Pick up at",
        deliveryFee: "Delivery cost",
        deliverTo: "Deliver to",
        discount: "Discount",
        subtotal: "Subtotal",
        total: "Total (including tax)",
        payment: "Payment",
        delivery: "Delivery",
        tipAmount: "Tip amount",
        vat: "VAT",
        finishButton: "Finish",
        trackButton: "Track your order"
    },
    SEARCHING_DRIVER: {
        searching: "Waiting for an available driver to respond"
    },
    WAITING_MERCHANT: {
        waiting: "Waiting for the restaurant to respond on your order"
    },
    TAXI_ACCEPTED: 'Taxi is on its way to you.',
    TAXI_ARRIVED: 'Taxi arrived to destination.',
    TAXI_WAITING: 'Taxi arrived and waiting.',
    TAXI_DRIVING: 'Driving to next destination.',
    EN_ROUTE: 'En route',
    RIDE_COMPLETE: 'Ride complete',
    RATE_DRIVE: 'Rate drive',
    SELECT_PAYMENT: 'Payment select',
    WELCOME_SCREEN: {
        title: "Welcome",
        services: "Services",
        chooseService: "You can choose your desired service on the home screen as well."
    },
    DELIVERY_START: {
        title: "Welcome, what are we in the mood for today?",
        restaurantBars: "Restaurants & Bars",
        scheduledMeals: "Scheduled meals",
        klikniMarket: "Klikni market",
        catering: "Catering"
    },
    SCHEDULED_MEALS: {
        title: "Choose duration of scheduled meals delivery",
        optionOne: "7-14 days",
        optionTwo: "15+ days",
    },
    DIETARY_GUIDELINES: {
        title: "Are you following any specific dietary guidelines?",
        gallbladder: 'Gallbladder',
        glutenFree: 'Gluten-free',
        fit: 'Fit',
        vegetarian: 'Vegetarian',
        vegan: 'Vegan',
        diabetes: 'Diabetes',
        diverses: 'Diverse',
        balanced: 'Balanced',
        dietaryPlan: 'Dietary plan',
    },
    FOOD_SELECTION: {
        searchPlaceholder: "What would you like to order",
        searchingNear: "Searching near",
        addAddress: "Add your address",
        continueButton: "Continue"
    },
    CATEGORIES: {
        fastFood: "Fast food",
        pizza: "Pizza",
        burger: "Burger",
        salads: "Salads",
        soups: "Soups",
        vegetarian: "Vegetarian",
        vegan: "Vegan",
        bakery: "Bakeries",
        sandwiches: "Sandwiches",
        bbq: "BBQ",
        steak: "Steak",
        chicken: "Chicken",
        kebab: "Kebab",
        fish: "Fish",
        sushi: "Sushi",
        bowl: "Poke bowl",
        seafood: "Seafood",
        risotto: "Risotto",
        italian: "Italian",
        mexican: "Mexican",
        chinese: "Chinese",
        japanese: "Japanese",
        thai: "Thai",
        korean: "Korean",
        indian: "Indian",
        arabic: "Arabic",
        kosher: "Kosher",
        halal: "Halal",
        breakfast: "Breakfast",
        lunch: "Lunch",
        gourmetCuisine: "Fine dining",
        traditional: "Traditional",
        healthy: "Healthy",
        snacksExtras: "Snacks & extras",
        desserts: "Desserts",
        iceCream: "Ice cream",
        hotDrinks: "Coffee & tea",
        pubs: "Pubs",
        wine: "Wine",
        cocktail: "Cocktails",
        drinks: "Soft drinks",
        kidsMeals: "Kids meals"

    },
    DELIVERY_HOME: {
        addressAdd: "Add your address",
        deliverTo: "Deliver to",
        orderFrom: "Order from your favorite restaurants",
        searchPlaceholder: "What would you like to order?",
        newRestaurants: "New restaurants on Klikni",
        popularNow: "Popular now",
        favoriteRestaurants: "My favourites",
        restaurants: "Current restaurant offers",
        discountRestaurants: "Discount deals",
        scheduledMealRestaurants: "Scheduled meals only"
    },
    RESTAURANT: {
        open: "Open",
        closes: "Closes at",
        delivery: "Delivery in",
        seats: "Seats",
        people: "people",
        bookTable: "Book a table",
        searchPlaceholder: "Search...",
        popular: "Popular",
        new: "New",
        discount: "Discount",
        titleOpenOrder: "Would you like to proceed with your order?",
        descriptionOpenOrder: "You have items saved in your cart for this restaurant. You can either continue with this order or begin a new one.",
        button1: "Continue order",
        button2: "Cancel",
        noMatchFound: "Your search did not match any dishes in our menu. Please try again with different keywords."
    },
    FOOD_ITEM_MODAL: {
        ingredients: "Ingredients",
        extras: "Extras",
        sides: "Side dishes",
        specialRequest: "Special request",
        placeholder: "Your message here...",
        addToOrder: "Add to order",
        noExtras: "I don't want any extras"
    },
    ORDER_DETAILS: {
        title: "Order details",
        addItems: "Add items to start a basket",
        basket: "Once you add items from a restaurant or store, your basket will appear here.",
        startShopping: "Start Shopping",
        checkoutButton: "Go to checkout"
    },
    ORDER_ITEM: {
        removeItem: "Remove this item?",
        comment: "Note to restaurant",
        extras: "Extras",
        sides: "Side dishes",
        placeholderDate: "Delivery date",
        placeholderTime: "Delivery time",
        addYourAddress: "Add your address",
        with: "With",
        added: "added"
    },
    VIEW_ORDER: "View order",
    ORDER_DETAILS_BOTTOM: {
        subtotal: "Sub-Total",
        deliveryFee: "Delivery Charge",
        total: "Total Price",
        discount: "Discount"
    },
    CHECKOUT: {
        title: "Checkout",
        deliverTo: "Deliver to",
        addYourAddress: "Add Your Address",
        instructions: "Instructions to courier",
        placeholder: "Your note for the delivery courier...",
        placeOrder: "Place my order"
    },
    DELIVERY_OPTIONS: {
        deliver: "Deliver",
        pickup: "Pickup"
    },
    TRACK_ORDER: {
        contactRestaurant: 'Contact restaurant',
        pickup: 'Estimated time for pickup',
        track: "Track order",
        estimated: "Estimated time to arrival",
        contactDriver: "Contact driver",
        success: "Order successfully delivered",
        enjoy: "Enjoy your meal",
        finish: "Finish",
        delivered: "Delivered",
        min: "min",
    },
    SERVICES: {
        taxi: "Taxi",
        transfer: "Transfer",
        delivery: "Delivery",
        deposit: "Deposit",
    },
    FOOD_SELECTION_DETAILS: {
        title: "Search",
        placeholder: "What would you like to order?",
        categories: "Categories",
        distance: "Distance",
        availableRestaurants: "Available restaurants",
        noResult: "No relevant search results found",
        result: "Found menu items based on your search"
    },
    SCHEDULED_ORDER_DETAILS: {
        title: "Your order",
        addMore: "Add more with the button below",
        messageForRestaurant: "Add a message for the restaurant",
        placeholder: "Your message here...",
        checkoutButton: "Go to checkout",
        addToStart: "Add items to start a basket",
        basket: "Once you add items from a restaurant or store, your basket will appear here.",
        startShopping: "Start Shopping"
    },
    TRANSFER_BOOKING_BOTTOM: {
        private: 'Private',
        shuttle: 'Shuttle',
        departureDatePlaceholder: "Departure date",
        returnDatePlaceholder: "Return date",
        numberOfAdults: "Number of adults",
        numberOfChildren: "Number of children (1-7)",
        numberOfChildrenBig: "Number of children (7-12)",
        findTransfer: "Find transfer",
        requirements: "REQUIREMENTS",
        wifi: "Wi-Fi",
        outlets: "Outlets",
        onBoardRestrooms: "Onboard restrooms",
        luggage: "Luggage storage",
        ac: "Air conditioning",
        multilingual: "Multilingual staff",
    },
    AVAILABLE_TRANSFERS: "Available transfers",
    TRANSFER_TICKET: {
        direct: "Direct",
        stop: "Stop",
        stops: "Stops",
        buy: "Buy",
        modalQRTitle: "Your QR ticket",
        qrDescription: "Present your QR code to the driver on the day of the transfer.",
        rateTrip: "Rate trip",
        modalRateTitle: "Rate this trip",
        rateDescription: "What did you liked or disliked about the provided transfer?",
        noHistory: "You have not bought any tickets yet."
    },
    TRANSPORT_DETAILS: {
        buy: "Buy ticket",
        infoMissing: "You have some information missing on your profile, please update before you can continue buying your ticket."
    },
    TRANSFER_LIST: {
        duration: "Stop duration",
        pet: "Pet friendlly",
        wifi: "Free Wi-Fi"
    },
    CONTACT_DETAILS: "Contact details",
    REVIEW_PAY: {
        title: "Review and pay",
        subtotal: "Sub-Total",
        discount: "Discount",
        total: "Total",
        pay: "Pay",
        success: "Payment was successful",
        descriptionSuccess: "You can find your ticket under My trips tab.",
        myTrips: "My trips",
        failed: "Payment was unsuccesful!",
        descriptionFail: "Please update your payment method."
    },
    MY_TRIPS: {
        title: "My trips",
        upcoming: "Upcoming",
        history: "History",
    },
    LOCATION_MODAL: {
        location: "Location",
        savedLocations: "Saved locations",
        current: "Current location",
        addAddress: "Add address",
        enterAddress: "Enter your address",
    },
    SIDE_MENU: {
        yourMessageHere: 'Your message here...',
        notifyMe: 'Notify me',
        settings: "Settings",
        preferences: "Preferences",
        notifications: "Notifications",
        accountCards: "Account and Cards",
        rideHistory: "Ride history",
        paymentHistory: "Payment history",
        defaultService: "Default service",
        wallet: "Wallet",
        family: "Family",
        business: "Business",
        support: "Support",
        reportLostItem: "Report lost item",
        language: "Language",
        myProfile: "My profile",
        transferHistory: "My trips",
        chooseTheRadio: "Choose the radio",
        contacts: "Contacts",
        howSpicyTitle: "How spicy would you like your meals",
        whichNotificationsTitle: "Which notifications would you prefer to receive?",
        allergiesTitle: "Your safety matters! Please let us know about any allergies you have so we can provide suitable food options.",
        allergies: "Allergies",
        orderHistory: "Order history",
        selectService: "Select service",
        languages: "Languages",

    },
    SUPPORT: {
        title: "Support",
        faq: "Frequently Asked Questions",
        contact: "Contact us",
        lostItems: "Lost items",
        accident: "I was involved in an accident",
        privacyPolicy: "Privacy policy and ToS"
    },
    FAQ: {
        title: "FAQ",
        account: "Account",
        unblock: "Unblock account",
        changeNumber: "Change phone number",
        paymentPricing: "Payment and pricing",
        acceptedPayment: "Accepted payment methods",
        estimation: "Price estimation",
        taxiTax: "Taxi kilometer tax",
        deliveryTax: "Delivery kilometer tax",
        transferTax: "Transfer kilometer tax",
        fee: "Fee",
        cancellationFee: "Ride cancellation fee",
        damageFee: "Damage or cleaning fee",
        highwayFee: "Highway and tunnel fee",
    },
    CONTACT_US: {
        title: "Contact us",
        name: "Name",
        lName: "Last name",
        telephone: "Telephone",
        writeUs: "Write us",
        submit: "Submit",
        email: "Email",
    },
    LOST_ITEM: {
        title: "Report lost item",
        item: "Item description",
        itemDescription: "Please provide a detailed description of the lost item to help us identify it. Include any distinctive features such as color, size, brand, and where you think you might have left it.",
        placeholder: "Description of your lost item...",
        indicate: "Indicate the last date and time you remember having the item with you. This information is crucial for us to locate it more efficiently.",
        lastSeenDate: "Last seen date",
        lastSeenTime: "Last seen time",
        addPhoto: "Add photo",
        optionally: "Optionally, add a photo of the item or its last known location",
        report: "Report lost item",
    },
    ACCIDENT: {
        title: "Report accident",
        involved: "I was involved in an accident",
        description: "If you have recently been involved in a collision while using our taxi service, we encourage you to report the incident immediately. Provide us with a brief description of the incident. We kindly request you to include relevant details such as the date, time, and location of the accident, as well as any additional information that may assist us in understanding the situation better. Our team will promptly review your report and take appropriate actions to address the matter. Your safety and well-being are of utmost importance to us, and we are committed to providing the necessary support during this time.",
        date: "Date",
        time: "Time",
        place: "Place",
        hurt: "Have you been hurt",
        yes: "Yes",
        no: "No",
        how: "How did the accident occur?",
        submit: "Submit",
    },
    BOOK_TABLE: {
        showQRCode: 'Show your QR code to the restaurant personnel to validate your active reservation.',
        at: 'at',
        on: 'on',
        pendingRes: 'You have a pending reservation at',
        acceptedRes: 'You have an accepted reservation at',
        thankYouTableReservation: 'Thank you for your registration, we will reply to you shortly regarding the status of your registration.',
        title: "Table reservation",
        howMany: "How many guests?",
        whatTime: "What time?",
        date: "Which date?",
        submit: "Submit",
    },
    SIDE_MENU_OPTIONS: {
        dairyFree: 'Dairy-Free',
        passengerUpdates: 'Passenger Updates',
        inAppMessaging: 'In-App Messaging',
        promotionsAndDiscounts: 'Promotions And Discounts',
        ratingsAndFeedbacks: 'Ratings and Feedbacks',
        driverPerformance: 'Driver Performance',
        accountAndPaymentNotifications: 'Account And Payment Notifications',
        systemUpdatesAndMaintenance: 'System Updates And Maintenance',
        emergencyOrCriticalAlerts: 'Emergency Or Critical Alerts',

        spicy: "Spicy",
        vegetarian: "Vegetarian",
        vegan: "Vegan",
        nutFree: "Nut free",
        glutenFree: "Gluten free",
        diaryFree: "Diary free",
        organic: "Organic",
        notSpicy: "Not spicy",
        mild: "Mild",
        hot: "Hot",
        veryHot: "Very hot",
        orderConfirmation: "Order confirmation",
        orderUpdates: "Order updates",
        deliveryArrival: "Delivery arrival",
        realTimeTracking: "Real-Time Order Tracking",
        promosDiscounts: "Promotions And Discounts",
        paymentConfirmation: 'Payment Confirmation',
        ratingFeedback: 'Ratings And Feedback',
        newsUpdates: 'News And Updates',
        accUpdates: 'Account Updates',
        wifi: 'Wi-Fi',
        outlets: 'Outlets',
        onBoardRestrooms: 'Onboard restrooms',
        luggage: 'Luggage storage',
        ac: 'Air conditioning',
        multilingual: 'Multilingual staff',
        departureReminders: 'Departure reminders',
        realTimeUpdates: 'Real-Time Updates',
        bookingConfirmation: 'Booking confirmation',
        specialOffers: "Special Offers and Promotions",
        news: 'News',
        pet: "Travelling with a pet",
        emptyTrunk: "Empty Trunk",
        nonSmoking: "Non-smoking",
        childSeats: "Child seats",
        accessibility: "Accessibility",
        quiet: "Quiet ride",
        rideRequest: "Ride requests",
        rideAcceptance: "Ride acceptance",
        driverArrival: "Driver arrival",
        rideCompletion: "Ride completion",
        driversChoice: "Drivers choice",
        wheelchair: 'Wheelchair accessibility',
        noRadio: 'Silent ride',
        assistance: 'Passenger assistance',
    },
    PROVIDER_DETAILS: {
        address: "Address",
        openingHours: "Opening hours",
        telephone: "Telephone number",
        deliveryInfo: "Delivery information",
        delivery_cost: "Delivery cost",
        estimated: "Estimated delivery time",
        contactSupport: "Contact support",
    },
    OPENING_HOURS: {
        mon: "Monday",
        tue: "Tuesday",
        wed: "Wednesday",
        thu: "Thursday",
        fri: "Friday",
        sat: "Saturday",
        sun: "Sunday",
        closed: "Closed",
    },
    LEGAL_TERMS: {
        title: "Legal terms",
        tos: "Terms of use",
        privacy: "Privacy policy",
        generalTerms: "General terms of agreement"
    },
    MY_PROFILE: {
        title: "My profile",
        detailsMustMatch: "These details must match passport and photo ID",
        fName: "First name",
        lName: "Last name",
        phone: "Phone number",
        email: "Email",
        nationality: "Nationality",
        dob: "Date of birth",
        passportNum: "Passport number",
        issuingCountry: "Issuing country",
        expiryDate: "Expiry date",
        male: "Male",
        female: "Female",
    },
    PROFILE_SCREEN: {
        fullName: "Full name",
        tapToSet: "Tap to set",
        telephone: "Telephone",
        emailNotSet: "Email not set",
        homeAddress: "Home address",
        workAddress: "Work address",
        schoolAddress: "School address",
        chosenLanguage: "Chosen language",
        langNotSet: "Language not set",
        password: "Password",
        cards: "Cards",
        deleteAccount: "Delete account",
        change: "Change",
        email: "Email",
        title: "My profile",
    },
    RIDE_HISTORY: {
        title: "Ride history",
        noFamilyHistory: "No family member has made a ride history yet.",
        totalSpent: "Total spent",
    },
    PAYMENT_HISTORY: {
        title: "Payment history",
        noPaymentHistory: "No family member has a payment history yet.",
    },
    WALLET: {
        title: "Wallet",
        currBalance: "Current balance",
        addFunds: "Add funds",
        paymentMethod: "Payment methods",
        addPaymentMethod: "Add payment method",
        managePaymentMethod: "Manage payment methods",

    },
    CAR_TYPES: {
        sedan: "Sedan",
        minivan: "Minivan",
        suv: "SUV",
        caravan: "Estate wagon",
        van: "Van",
        minibus: "Minibus",
        bus: "Bus",
    },
    TAXI_OPTIONS: {
        start: "Start",
        perKm: "Per km",
        perMin: "Per min",
    },
    FAMILY: {
        title: "Family",
        description: "The app allows you to easily manage and assist your loved ones with transportation and deliveries. You can add family members and friends to your account, making it simple to order rides or arrange deliveries on their behalf.",
    },
    BAN_REMOVE_USER: {
        swipeLeft: "Swipe left for more options",
        areYouSureRemove: "Are you sure you want to remove the user?",
        invitationProcess: "You will have to go through the invitation process again.",
        no: "No",
        yes: "Yes",
        banUser: "Ban user?",
        areYouSureBan: "Are you sure you would like to ban this person, from accessing your wallet?",
        addMember: "Add member",
    },
    ADD_MEMBER: {
        title: "Add member",
        addPicture: "Add profile picture",
        fName: "First name",
        lName: "Last name",
        email: "Email",
        phoneNumber: "Phone number",
        signUp: "Sign up",
        titleModal: "New family member added",
        descriptionModal: "New family member successfully added."
    },
    BUSINESS: {
        title: "Business",
        description: "Merchants can input their clientele into the system, facilitating effortless transportation arrangements on their behalf. Providing a convenient way to manage transportation needs for their clientele, ensuring a seamless and efficient experience.",
    },
    LANGUAGES: {
        en: "English",
        si: "Slovenian",
        de: "German",
        fr: "French",
        esp: "Spanish",
        it: "Italian",
        srb: "Serbian",
        bih: "Bosnian",
        cro: "Croatian",
        ru: "Russian",
    },
    SIDE_MENU_NOTIFICATIONS: {},
    ADD_CREDIT_CARD: {
        title: "Add credit card",
        addCard: "Add card",
        cardNumber: "Card number",
        cardExpiry: "Exp.date",
        cardCvv: "CVV",
        cardHolder: "Card holder name",
        scanCard: "Scan card",
        addPaymentMethod: "Add payment method",
        managePaymentMethod: "Manage payment methods",
        infoMissing: "You have some information missing. Please fill in all fields to add a payment method."
    },
    ADD_FUNDS: {
        title: "Add Funds",
        selectPayment: "Select payment method",
        sendMoney: "Send money",
        infoMissing: "You have some information missing on your profile, please update before you can contiune buying your ticket.",
        usd: "USD",
        eur: "EUR",
        gbp: "GBP",
        enterAmount: "Enter amount",
    },
    BOOKING_ADDRESS: {
        addStop: "Add a stop",
        destinationUnknown: "Destination yet not known at the moment"
    },
    DAYS: {
        mon: 'Monday',
        tue: 'Tuesday',
        wed: 'Wednesday',
        thu: 'Thursday',
        fri: 'Friday',
        sat: 'Saturday',
        sun: 'Sunday',
        holidays: 'Holidays'
    },
    WORKING_HOURS: {
        title: 'Working Hours',
        infoMessage: 'Please select working days and enter working hours for each day.',
        question: '+ Double working hours?'
    },


}