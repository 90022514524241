import axios from "../lib/axios"
import {getCompletedTaxiOrdersByDriverId} from "./taxiApi";

export const sendDeliveryOrder = async (order) => {
    try {
        const resp = await axios.post(
            "/mobileApp/delivery/order",
            {
                ...order
            }
        )
        return resp.data
      
    } catch (err) {
        return false
    }
}

const createDeliveryOrder = async (order, user_id, return_url) => {
    try {
        const resp = await axios.post("/delivery/orders/order", {
            orderBody: order,
            user_id: user_id,
            return_url: return_url
        });
        return resp.data;
    } catch (error) {
       throw new Error(error);
    }
}

const getActiveDeliveryOrders = async () => {
    try {
        const resp = await axios.get("/delivery/orders/active");
        return resp.data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getCompletedDeliveryOrdersByDeliveryDriverId = async (delivery_driver_id) => {
    try {
        const resp = await axios.get(`/delivery/orders/completed/${delivery_driver_id}`)
        return resp.data;
    } catch (error) {
        return false;
    }
}

export const getDeliveryOrders = async () => {
    try {
        const resp = await axios.get("/delivery/orders/");
        return resp.data;
    } catch (error) {
        throw new Error(error);
    }
};


const getActiveDelivery = async (user_id) => {
    try {
        const resp = await axios.get(`/delivery/orders/active/${user_id}`)
        return resp.data;
    } catch (error) {
        throw new Error(error);
    }
}

const getCompletedDeliveryOrdersByUserId = async (user_id) => {
    try {
        const resp = await axios.get(`/delivery/orders/completed/user/${user_id}`)
        return resp.data;
    } catch (error) {
        return false;
    }
}

const loadDeliveryDriversWithDailyMeals = async () => {
    try {
        const resp = await axios.get("/delivery_drivers/daily-meals");
        return resp.data;
    } catch (err) {
        console.error("Error loading delivery drivers with daily meals:", err);
        return null;
    }
};

const updateDeliveryOrder = async (order) => {
    try {
        const resp = await axios.post("/delivery/orders/order/update", {
            order: order
        });
        return resp.data;
    } catch (error) {
        throw new Error(error);
    }
}

const DeliveryApi = {
    getActiveDeliveryOrders,
    getActiveDelivery,
    createDeliveryOrder,
    getCompletedDeliveryOrdersByUserId,
    getCompletedDeliveryOrdersByDeliveryDriverId,
    loadDeliveryDriversWithDailyMeals,
    updateDeliveryOrder,
    getDeliveryOrders
}

export default DeliveryApi;