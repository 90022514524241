import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './../css/DatePicker.css';
import Input from "./Input";
import colors from "../../constants/colors";

const DatePickerComponent = ({ date, setDate, title, placeholder, disabled, red, yellow, green, inputStyle, formatDate = true }) => {
    const [isDatePickerVisible, setDatePickerVisible] = useState(false);
    const [localDate, setLocalDate] = useState(date)


    const handleChange = (date) => {
        // setDate(date ? date.toISOString().split('T')[0] : '');
        const formattedDate = formatDateToCustomFormat(date)
        if (formatDate) {
            setDate(formattedDate)
        } else {
            setDate(date.toISOString())
        }
        setLocalDate(formattedDate)
        setDatePickerVisible(false);
    };

    function formatDateToCustomFormat(date) {
        if (!date) return '';

        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const formatter = new Intl.DateTimeFormat('en-GB', options); // Use 'en-GB' for "3 September 2024" format

        return formatter.format(new Date(date));
    }
    return (
        <div className="date-picker-container">
            {title && <p className={'p-label'}>{title}</p>}
            <div
                onClick={() => setDatePickerVisible(true)}
            >
                <Input
                    date_picker_input
                    placeholder={placeholder}
                    value={formatDateToCustomFormat(localDate) || ""}
                    style={inputStyle ? inputStyle : {fontSize: 14}}
                />
            </div>

            {/* Modal for DatePicker */}
            {isDatePickerVisible && (
                <div className="date-picker-modal" onClick={() => setDatePickerVisible(false)}>
                    <div className="date-picker-popup" onClick={(e) => e.stopPropagation()}>
                        <DatePicker
                            selected={date ? new Date(date) : null}
                            onChange={handleChange}
                            inline
                            className="react-datepicker"
                            calendarClassName={`calendar ${yellow ? 'yellow' : ''} ${red ? 'red' : ''} ${green ? 'green' : ''}`}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default DatePickerComponent;
