export const APP_SERVICES = {
    TAXI: 'Taxi',
    TRANSFER: 'Transfer',
    DELIVERY: 'Delivery',
}

export const TAX = {
    SLO: 0.22
};

export const COST_PER_KM = {
    STANDARD: 1.09,
    PREMIUM: 1.09,
}

export const COST_PER_MIN = {
    STANDARD: 0.2,
    PREMIUM: 0.2,
}

export const TAXI_STARTING_FEE = {
    STANDARD: 1.90,
    PREMIUM: 1.90,
}

export const VEHICLE_CATEGORY = {
    STANDARD: 'STANDARD',
    PREMIUM: 'PREMIUM',
    ANY: 'ANY'
}

export const VEHICLE_CLASS = {
    SEDAN: 'SEDAN',
    CARAVAN: 'CARAVAN',
    SUV: 'SUV',
    MINIVAN: 'MINIVAN',
    VAN: 'VAN',
    MINIBUS: 'MINIBUS',
    BUS: 'BUS',
    CARGO_VAN: 'CARGO_VAN',
    ANY: 'ANY'
}

export const USER_ROLE = {
    ADMIN: 'ADMIN',
    BUSINESS_OWNER: 'BUSINESS_OWNER',
    BUSINESS_MANAGER: 'BUSINESS_MANAGER',
    BUSINESS_CARETAKER: 'BUSINESS_CARETAKER',
    BUSINESS_USER: 'BUSINESS_USER',
    PERSONAL: 'PERSONAL',
    DRIVER: 'DRIVER',
    DELIVERY_DRIVER: 'DELIVERY_DRIVER',
    DISPATCHER: 'DISPATCHER',
}

export const USER_ROLES_OPTIONS = [
    {
        label: "ADMIN", value: "ADMIN"
    },
    {
        label: "DRIVER", value:  "DRIVER"
    },
    {
        label: "ENTITIES_MANAGER", value:  "ENTITIES_MANAGER"
    }];


export const SESSION_STORAGE_KEYS = {
    USER: '_user',
    USER_CARETAKER: '_user_caretaker',
    USER_TOKEN: '_user_token',
    USERS: '_users',
    CARETAKERS: '_caretakers',
    BARS: '_bars',
    CATEGORIES: '_categories',
    PRODUCTS: '_products',
    BAR_OWNER: '_bar_owner',
    BAR_REPRESENTATIVE: '_bar_user',
    BAR_CARETAKER: '_bar_caretaker',
    ACTIVE_EDIT_USER: '_user_edit',
    ACTIVE_BAR: '_bar_active',
    ACTIVE_CATEGORY: '_category_active',
    ACTIVE_PRODUCT: '_product_active',
    ACTIVE_AD: '_ad_active',
    L10N: 'local_l10n_key',
    DOWNLOAD_PROGRESS: '_dl_progress_',
    DOWNLOAD_STATUS: '_dl_status_'
};

export const PAGES = {
    LOGIN: '/login',
    DASHBOARD_TAXI: '/dashboard/taxi',
    DASHBOARD_DELIVERY: '/dashboard/delivery',
    DASHBOARD_TRANSFER: '/dashboard/transfer',
    USERS_LIST: '/users',
    DRIVERS_LIST: '/drivers',
    DRIVER: '/driver',
    DELIVERY_DRIVER: '/delivery-driver',
    USER: '/user',
    DELIVERY_DRIVERS_LIST: '/delivery-drivers',
    FEEDBACKS: '/feedbacks',
    TAXI_ZONES: '/taxi-zones',
    MERCHANTS: '/merchants',
    RESET: '/reset-password',
    REVIEWS: '/reviews',
    STATISTICS: '/statistics',
    LOST_ITEMS: '/lost-items',
}

export const
    TAXI_ORDER_STATUS = {
    PENDING: 'PENDING',
    ACCEPTED: 'TAXI_ACCEPTED',
    REJECTED: 'TAXI_REJECTED',
    CANCELED: 'CUSTOMER_CANCELED',
    TAXI_CANCELED: 'TAXI_CANCELED',
    WAITING: 'TAXI_WAITING',
    DRIVING: 'TAXI_DRIVING',
    ARRIVED: 'TAXI_ARRIVED',
    COMPLETED: 'TAXI_COMPLETED',

    // old statuses
    TAXI_ARRIVED: 'TAXI_ARRIVED',
    EN_ROUTE: 'EN_ROUTE',
    RIDE_COMPLETE: 'RIDE_COMPLETE',
    SELECT_PAYMENT: 'SELECT_PAYMENT',
    RATE_DRIVE: 'RATE_DRIVE',
}


export const DELIVERY_ORDER_STATUS = {
    PENDING: 'PENDING',
    ACCEPTED: 'DELIVERY_ACCEPTED',
    REJECTED: 'DELIVERY_REJECTED',
    CANCELED: 'DELIVERY_CANCELED',
    IN_DELIVERY: 'DELIVERY_IN_DELIVERY',
    DELAYED: 'DELIVERY_DELAYED',
    ARRIVED: 'DELIVERY_ARRIVED',
    DELIVERED: 'DELIVERY_DELIVERED',
    COMPLETED: 'DELIVERY_COMPLETED',
    PICKED_UP: 'DELIVERY_PICKED_UP',

    WAITING_FOR_CUSTOMER: 'WAITING_FOR_CUSTOMER',
}


export const PROVIDER_ORDER_STATUS = {
    PENDING: 'PENDING',
    ACCEPTED: 'MERCHANT_ACCEPTED',
    REJECTED: 'MERCHANT_REJECTED',
    CANCELED: 'CUSTOMER_CANCELED',
    PREPARING: 'MERCHANT_PREPARING',
    DELAYED: 'MERCHANT_DELAYED',
    READY_FOR_PICKUP: 'MERCHANT_READY_FOR_PICKUP',
    REFUNDED: 'MERCHANT_REFUNDED',
    HIGH_DEMAND: 'MERCHANT_HIGH_DEMAND',
}

export const TABLE_RESERVATION_STATUS = {
    PENDING: 'PENDING',
    ACCEPTED: 'TABLE_RESERVATION_ACCEPTED',
    REJECTED: 'TABLE_RESERVATION_REJECTED',
    CANCELED: 'TABLE_RESERVATION_CANCELED',
    COMPLETED: 'TABLE_RESERVATION_COMPLETED',
    EXPIRED: 'TABLE_RESERVATION_EXPIRED',
}

export const ErrorType = {
    SUCCESS: -1,
    UNAUTHORIZED: 0,
    ERROR_FETCHING_DATA: 1,
    USER_ACCESS_NOT_GRANTED: 2
}

export const CREATE_ACTION = {
    REGISTER_USER: 0,
    LOGIN_USER: 1,
    PASSWORD_RESET: 2,
    UPDATE_DATA: 3,
}


/* EXCEL SHEET NAMES & ORDER, DO NOT CHANGE INDEX ORDERING!*/
export const XLSX_SHEET_NAMES = {
    BARS: 'Bars',
    USERS: 'Users',
    CATEGORIES: 'Categories',
    PRODUCTS: 'Products',
}

export const XLSX_BARS_FIELDS = {
    EXTERNAL_ID: 0,
    NAME: 1,
    ADDRESS_STREET: 2,
    ADDRESS_STREET_NMB: 3,
    ADDRESS_POST_NUMBER: 4,
    ADDRESS_POST: 5,
    ADDRESS_REGION: 6,
    TAX_NUMBER: 7,
    EMAIL_PERSONNEL: 8,
    EMAIL_CARETAKER: 9,
    EMAIL_OWNER: 10,
    LEGAL_ENTITY: 11
}
export const XLSX_USERS_FIELDS = {
    EXTERNAL_ID: 0,
    FULL_NAME: 1,
    ADDRESS: 2,
    TELEPHONE: 3,
    EMAIL: 4,
    USER_ROLE: 5
}
export const XLSX_CATEGORIES_FIELDS = {
    NAME: 0,
    POSITION: 1,
    EN_NAME: 2,
    DE_NAME: 3,
    IT_NAME: 4,
    HR_NAME: 5,
}
export const XLSX_PRODUCTS_FIELDS = {
    EXTERNAL_ID: 0,
    NAME: 1,
    CATEGORY_LABEL: 2,
    PACKAGING: 3,
    MEASURE_UNIT: 4,
    ALERGENS: 5,
    ALCO_PERCENTAGE: 6,
    COMBO_OFFER: 7,
    SLO_DESC: 8,
    EN_NAME: 9,
    EN_DESC: 10,
    DE_NAME: 11,
    DE_DESC: 12,
    IT_NAME: 13,
    IT_DESC: 14,
    HR_NAME: 15,
    HR_DESC: 16,
    OWN_PRODUCT: 17
}

export const FILE_TYPE = {
    IMAGE: 'IMAGE',
    DOCUMENT: 'DOCUMENT',
};


export const ORDER_TYPE = {
    TAXI: 'TAXI',
    TRANSFER_PRIVATE: 'TRANSFER_PRIVATE',
    TRANSFER_SHUTTLE: 'TRANSFER_SHUTTLE',
    DELIVERY: 'DELIVERY',
}

export const PAYMENT_STATUS = {
    SUCCESSFUL: 'SUCCESSFUL',
    UNSUCCESSFUL: 'UNSUCCESSFUL',
    UNPAID: 'UNPAID'
}

export const PAYMENT_TYPE = {
    CASH: 'CASH',
    CREDIT_CARD: 'CARD',
    WALLET: 'WALLET'
}

export const LOST_ITEM_STATUS = {
    REPORTED: 'REPORTED',
    RESOLVED: 'RESOLVED'
}

export const SORTING_TYPE = {
    AUTOMATIC: 'AUTOMATIC',
    MANUAL: 'MANUAL'
}