import React, { useState } from 'react';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import './../css/TimePicker.css';
import colors from "../../constants/colors";
import Input from "./Input";

const TimePickerComponent = ({
                                 time,
                                 setTime,
                                 title,
                                 placeholder,
                                 disabled,
                                 customStyle
                             }) => {
    const [isTimePickerVisible, setTimePickerVisible] = useState(false);

    const handleTimeChange = (newTime) => {
        setTime(newTime);
    };

    const handleClickOutside = (event) => {
        if (!event.target.closest('.time-picker-modal') && !event.target.closest('.time-picker-input-container')) {
            setTimePickerVisible(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div  style={customStyle?.container}>
            <label className="time-picker-label" style={customStyle?.label}>{title}</label>

            <div
                onClick={() => setTimePickerVisible(true)}
                style={customStyle?.inputContainer}
            >
                <Input
                    date_picker_input
                    placeholder={placeholder}
                    value={time}
                    style={customStyle?.input}
                />
            </div>

            {isTimePickerVisible && (
                <div className="time-picker-modal-overlay" style={customStyle?.overlay}>
                    <div className="time-picker-modal" style={customStyle?.modal}>
                        <TimePicker
                            onChange={handleTimeChange}
                            value={time}
                            disableClock
                            format="HH:mm"
                            className="react-time-picker"
                            style={customStyle?.timePicker}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default TimePickerComponent;
