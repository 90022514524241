import React, {useState, useCallback, useEffect, useRef} from "react";
import Collapsible from "react-collapsible";
import {useNavigate} from "react-router-dom";
import {TailSpin} from "react-loader-spinner";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowUp, faArrowDown} from '@fortawesome/free-solid-svg-icons'
import '../css/Login.css';

import countryEN from "../assets/img/country_en.png";
import countrySI from "../assets/img/country_si.png";

import {useDispatch, useSelector} from "react-redux";
import logo from "../assets/img/logo.png";
import background from "../assets/img/background.jpg";

import Input from "../components/js/Input";
import Button from "../components/js/Button";

import colors from "../constants/colors";
import {ErrorType, PAGES} from "../constants/enums";
import Message from "../components/js/Message";
import {L10N} from "../l10n/l10n";
import {parseSelectedCountryLabel} from "../helpers/helpers";
import {setUserData} from "../store/slices/userSlice";
import {getL10N, setLanguage} from "../lib/languageHelpers";
import {getLocalisedTexts} from "../l10n/localisations/languages";
import UserApi from "../apis/userApi";
import Colors from "../constants/colors";


function Login(props) {
    let dispatch = useDispatch();
    const l10nText = getLocalisedTexts('CRM');

    const user = useSelector(state => state.user.user);
    const language = useSelector(state => state.user.language);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [error, setError] = useState(null);

    const [selectedLanguage, setSelectedLanguage] = useState(language ? language : L10N.en.value);
    const [isL10NOptionsOpen, setIsL10NOptionsOpen] = useState(false);

    const [loginSuccessful, setLoginSuccessful] = useState(false);

    const navigate = useNavigate();
    const onNavigateTo = useCallback((path) => {
        navigate(path);
    }, [navigate]);

    useEffect(() => {
        getL10N(true);
    }, []);

    useEffect(() => {
        if (loginSuccessful) {
            console.log('redirect..');
            onNavigateTo(PAGES.DASHBOARD_TAXI);
        }
    }, [loginSuccessful]);

    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, [])

    const onInputChange = (value, onInputAction) => {
        setError(null);
        onInputAction(value);
    }

    const onConfirmLoginUser = async () => {
        setLoadingSpinner(true);
        const onCleanup = () => {
            setTimeout(() => {
                setLoadingSpinner(false);
            }, 250);
        }
        try {
            console.log("API CALL")

            let userData = await UserApi.userLogin(email, password);
            console.log(userData);
            if (userData && !userData.error) {
                dispatch(setUserData({user: userData}));
                setLoginSuccessful(true);
            }
            onCleanup();
            console.log("Login new user successfully!", user);
        } catch (error) {
            console.error("Error logging-in new user..", error);
            setError(ErrorType.USER_ACCESS_NOT_GRANTED);
            setLoadingSpinner(false);
        }
    };

    const onResetPassword = () => {
        onNavigateTo(PAGES.RESET);
    };

    const displayCollapsibleL10NHeader = (status) => {
        let arrowStyle = {fontSize: '24px', color: colors.secondary};
        return (
            <div className={'collapsible-header l10n-options'} style={{justifyContent: 'flex-end'}}>
                {parseSelectedCountryLabel(selectedLanguage, setSelectedLanguage, () => onActionCollapseL10NOptions(false))}
                {status ? <FontAwesomeIcon icon={faArrowUp} style={arrowStyle}/> :
                    <FontAwesomeIcon icon={faArrowDown} style={arrowStyle}/>}
            </div>
        );
    }

    const onActionCollapseL10NOptions = (state) => {
        setIsL10NOptionsOpen(state);
    }

    const renderL10NOption = (l10nKey, imgData) => {
        return (
            <div onClick={() => {
                onActionCollapseL10NOptions(false)
                setSelectedLanguage(l10nKey)
                setLanguage(l10nKey);

            }}
                 className={'country-label-container'}
                 style={{alignSelf: 'center'}}>
                <img src={imgData} alt={l10nKey} width={'27px'}/>
            </div>
        );
    }

    const renderL10NOptions = () => {
        const showOtherAvailableL10N = (selectedLanguage) => {

            renderL10NOption(L10N.en.value, countryEN);
            renderL10NOption(L10N.si.value, countrySI);

            switch (selectedLanguage) {
                case L10N.si:
                    renderL10NOption(L10N.en.value, countryEN);
                    break;
                case L10N.en:
                default:
                    renderL10NOption(L10N.si.value, countrySI);
                    break;
            }
        };

        return (
            <Collapsible className={'l10n-options'}
                         transitionTime={400}
                         onClosing={() => onActionCollapseL10NOptions(false)}
                         onOpening={() => onActionCollapseL10NOptions(true)}
                         open={isL10NOptionsOpen}
                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                         trigger={displayCollapsibleL10NHeader(false)}
                         triggerWhenOpen={displayCollapsibleL10NHeader(true)}>
                <div className={'l10n-options-content'}>
                    {showOtherAvailableL10N(selectedLanguage)}
                    {selectedLanguage !== L10N.si.value && renderL10NOption(L10N.si.value, countrySI)}
                    {selectedLanguage !== L10N.en.value && renderL10NOption(L10N.en.value, countryEN)}
                </div>
            </Collapsible>
        );
    }

    return (
        <div ref={ref} className={'page login'} style={{backgroundImage: `url(${background})`}}>
            {renderL10NOptions()}
            <div className={'login-container'}>
                <div className={'logo'} style={{alignSelf: 'center'}}>
                    <img src={logo} alt="logo" height={'180px'}/>
                </div>
                <div className={'form-container'} style={{marginBottom: '0'}}>
                    <div className={'form_col'} style={{width: 'auto'}}>
                        <Input login email={email}
                               onChange={(value) => onInputChange(value, setEmail)}
                               onSubmit={() => {
                               }}
                               placeholder={l10nText.PLACEHOLDER_EMAIL}
                               label={l10nText.LOGIN_EMAIL}
                               style={{marginBottom: '10px'}}/>
                        <Input login password={password}
                               onChange={(value) => onInputChange(value, setPassword)} onSubmit={onConfirmLoginUser}
                               placeholder={l10nText.PLACEHOLDER_PASSWORD}
                               label={l10nText.LOGIN_PASSWORD}
                               style={{marginBottom: '0px'}}/>

                        <Button spinnerColor={Colors.green} loading={loadingSpinner} text={'LOGIN'} login action={onConfirmLoginUser}/>
                        <Button text={'FORGOT YOUR PASSWORD?'} info action={onResetPassword}/>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Login;