import React, { createContext, useState, useContext, useEffect } from 'react';
import CustomModal from '../components/js/CustomModal';
const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [modalConfig, setModalConfig] = useState({
        visible: false,
        title: '',
        description: '',
        button1Text: 'Close',
        button1CallAction: null,
        button2Text: '',
        button2CallAction: null,
    });

    const showNotification = ({
                                  title = '',
                                  description = '',
                                  button1Text = 'Close',
                                  button1CallAction = hideNotification,
                                  button2Text = '',
                                  button2CallAction = null,
                              }) => {
        setModalConfig({
            visible: true,
            title,
            description,
            button1Text,
            button1CallAction,
            button2Text,
            button2CallAction,
        });
    };

    const hideNotification = () => {
        setModalConfig((prevConfig) => ({ ...prevConfig, visible: false }));
    };

    return (
        <NotificationContext.Provider value={{ showNotification, hideNotification }}>
            {children}
            <CustomModal
                visible={modalConfig.visible}
                onClose={hideNotification}
                title={modalConfig.title}
                description={modalConfig.description}
                button1Text={modalConfig.button1Text}
                button1CallAction={modalConfig.button1CallAction}
                button2Text={modalConfig.button2Text}
                button2CallAction={modalConfig.button2CallAction}
            />
        </NotificationContext.Provider>
    );
};

export const useNotification = () => useContext(NotificationContext);
