import React, { useState, useEffect } from 'react';
import { getLocalisedTexts } from "../../../l10n/localisations/languages";
import DynamicTable from "../DynamicTable";
import { renderDriversListDataSquares } from "../DataSquare";
import deliveryDriverApi from "../../../apis/deliveryDriverApi";
import { getColorByValue } from "../../../lib/dataParsingHelpers";
import deliveryApi from "../../../apis/deliveryApi";
import colors from "../../../constants/colors";
import { DELIVERY_ORDER_STATUS, PAGES } from "../../../constants/enums";
import { Link } from "react-router-dom";

const DeliveryDriversList = () => {
    const l10nText = getLocalisedTexts('DRIVER_LIST');
    const [driversList, setDriversList] = useState([]);
    const [filteredDrivers, setFilteredDrivers] = useState([]);
    const [onlineDrivers, setOnlineDrivers] = useState(0);
    const [registeredDrivers, setRegisteredDrivers] = useState(0);

    const [completedDrives, setCompletedDrives] = useState(0);
    const [amountEarnedToday, setAmountEarnedToday] = useState(0);
    const onlinePercentage = ((onlineDrivers / registeredDrivers) * 100).toFixed(0);

    const fetchDeliveryOrders = async () => {
        try {
            const orders = await deliveryApi.getDeliveryOrders();
            if (orders && !orders.error) {
                const completedOrders = orders.filter(order => order.status === DELIVERY_ORDER_STATUS.COMPLETED);

                const now = new Date();
                const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
                const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);

                let todaysEarnings = 0;
                let completedRidesToday = 0;

                completedOrders.forEach(order => {
                    const timeline = order.timeline.find(item => item.status === DELIVERY_ORDER_STATUS.COMPLETED);
                    const timestamp = timeline?.location?.timestamp;

                    if (timestamp) {
                        const orderDate = new Date(timestamp);
                        const orderAmount = (parseFloat(order.details.delivery_cost) + (parseFloat(order.details.delivery_earnings) || 0)) || 0;

                        if (orderDate >= startOfDay && orderDate <= endOfDay) {
                            completedRidesToday += 1;
                            todaysEarnings += orderAmount;
                        }
                    }
                });

                setAmountEarnedToday(todaysEarnings.toFixed(2));
                setCompletedDrives(completedRidesToday);
            }
        } catch (error) {
            console.error('Error fetching delivery orders:', error);
        }
    };

    const fetchOnlineDrivers = async () => {
        try {
            const drivers = await deliveryDriverApi.listOnlineDrivers();
            if (drivers && !drivers.error) {
                setFilteredDrivers(drivers);
                setOnlineDrivers(drivers.length);
            } else {
                setOnlineDrivers(0);
            }
        } catch (e) {
            console.error(e);
            setOnlineDrivers(0);
        }
    };

    const fetchRegisteredDrivers = async () => {
        try {
            const drivers = await deliveryDriverApi.listDeliveryDrivers();
            console.log("DRIVERS", drivers)
            if (drivers && !drivers.error) {
                setDriversList(drivers);
                setRegisteredDrivers(drivers.length);
            } else {
                setDriversList([]);
                setRegisteredDrivers(0);
            }
        } catch (e) {
            console.error(e);
            setRegisteredDrivers(0);
        }
    };

    useEffect(() => {
        fetchOnlineDrivers();
        fetchRegisteredDrivers();
        fetchDeliveryOrders();

        const intervalId = setInterval(() => {
            fetchOnlineDrivers();
            fetchRegisteredDrivers();
            fetchDeliveryOrders();
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);

    const columnsConfig = {
        online: l10nText.activity || 'Online',
        driver: l10nText.driver || 'Driver',
        vehicle: l10nText.vehicle_make_model || 'Vehicle Make/Model',
        vehicle_color: l10nText.vehicle_color || 'Vehicle Color',
        license_plate: l10nText.vehicle_license_plate || 'License Plate',
        status: l10nText.status || 'Status',
        view: 'View',
    };

    const parseDriverData = driversList.map(delivery_driver => ({
        online: (
            <span className={`status-dot ${delivery_driver?.online ? 'online' : 'offline'}`} />
        ),
        driver: `${delivery_driver?.user?.first_name} ${delivery_driver?.user?.last_name}`,
        vehicle: `${delivery_driver?.vehicles[0]?.make} ${delivery_driver?.vehicles[0]?.model}`,
        vehicle_color: getColorByValue(delivery_driver?.vehicles[0]?.color),
        license_plate: delivery_driver?.vehicles[0]?.license_plate,
        status: (
            <div
                style={{
                    backgroundColor: !delivery_driver?.online ? colors.red : delivery_driver?.on_order ? '#28a745' : '#d5b715',
                    borderRadius: '8px',
                    textAlign: 'center',
                    padding: '2px 8px',
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: "13px"
                }}
            >
                {!delivery_driver?.online ? 'Offline' : delivery_driver?.on_order && delivery_driver?.online ? "On Delivery" : "Waiting"}
            </div>
        ),
        view: <Link to={`${PAGES.DELIVERY_DRIVER}/${delivery_driver.delivery_driver_id}`}>View</Link>,
    }));

    return (
        <div>
            <div className="list-header">
                <h3>{l10nText.title || 'Delivery Drivers List'}</h3>
                {renderDriversListDataSquares(l10nText, onlineDrivers, registeredDrivers, onlinePercentage, completedDrives, amountEarnedToday)}
            </div>
            <DynamicTable
                data={parseDriverData}
                columnsConfig={columnsConfig}
                rowsPerPage={8}
            />
        </div>
    );
};

export default DeliveryDriversList;