import React, {useState, useEffect} from 'react';
import DynamicTable from '../DynamicTable';
import {APP_SERVICES, ORDER_TYPE, TAXI_ORDER_STATUS} from '../../../constants/enums';
import taxiApi from '../../../apis/taxiApi';
import Map from '../Map';
import driverApi from "../../../apis/driverApi";
import CustomModal from "../CustomModal";
import {getLocalisedTexts} from "../../../l10n/localisations/languages";
import RadioButtons from "../RadioButtons";
import {getOrderCancellationReasons} from "../../../constants/keyValueOptions";
import {getLocalISOTime} from "../../../lib/dateUtils";
import colors from "../../../constants/colors";
import Input from "../Input";
import {isValidPhoneNumber} from "../../../lib/helpers";
import BookingTaxi from "../BookingTaxi";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "../Button";

const TaxiOrdersOverwatch = () => {
    const l10n = getLocalisedTexts();
    const l10nCancelOrder = l10n?.CANCEL_ORDER;
    const l10nCreateOrder = l10n?.CREATE_ORDER;

    const [pendingOrders, setPendingOrders] = useState([]);
    const [activeOrders, setActiveOrders] = useState([]);
    const [completedOrders, setCompletedOrders] = useState([]);
    const [canceledOrders, setCanceledOrders] = useState([]);
    const [selectedTaxiOrder, setSelectedTaxiOrder] = useState(null);
    const [selectedCancellationReason, setSelectedCancellationReason] = useState(null);
    const [modal, setModal] = useState(null);
    const [message, setMessage] = useState('');
    const isOtherSelected = Array.isArray(selectedCancellationReason) && selectedCancellationReason.some(reason => reason.value === 'other');
    const [userPhoneNumber, setUserPhoneNumber] = useState('');
    const [waitingForResponse, setWaitingForResponse] = useState(false)
    const [cancelingOrderId, setCancelingOrderId] = useState(null);
    const [additionalData, setAdditionalData] = useState({
        waitingForResponse: false,
        cancelingOrderId: null
    })

    const [drivers, setDrivers] = useState([]);

    const renderModals = () => {
        switch (modal) {
            case 'cancelOrder':
                return (
                    <CustomModal
                        visible={true}
                        title={l10nCancelOrder?.title}
                        description={l10nCancelOrder?.description}
                        button1Text={l10nCancelOrder?.confirmButton}
                        button1CallAction={handleConfirmCancellation}
                        button2Text={l10nCancelOrder?.cancelButton}
                        button2CallAction={() => {
                            setModal(null);
                            setSelectedCancellationReason([]);
                            setMessage('');
                        }}
                        onClose={() => {
                            setModal(null);
                            setSelectedCancellationReason([]);
                            setMessage('');
                        }}
                    >
                        <div className={'centered-component'}>
                            <RadioButtons
                                singleToggle
                                title={l10nCancelOrder?.cancellationReasonTitle}
                                items={getOrderCancellationReasons()}
                                value={selectedCancellationReason}
                                onSelectionChange={setSelectedCancellationReason}
                                style={{marginVertical: 12, marginTop: 24, marginLeft: 12}}
                            />
                            {isOtherSelected && (
                                <div className="input-container">
                                    <label className="label">{l10nCancelOrder?.reasons.other}</label>
                                    <Input
                                        multiline_short
                                        value={message}
                                        onChange={(value) => setMessage(value)}
                                        placeholder={l10n.GENERAL.writeYourMessage}
                                        style={{color: colors.black}}
                                    />
                                </div>
                            )}
                        </div>
                    </CustomModal>
                );
            case 'createOrder':
                return (
                    <CustomModal
                        visible={true}
                        title={l10nCreateOrder?.title}
                        description={l10nCreateOrder?.description}
                        button1Text={l10nCreateOrder?.confirmButton}
                        button1CallAction={handleCreateOrder}
                        button2Text={l10nCreateOrder?.cancelButton}
                        button2CallAction={() => setModal(null)}
                        onClose={() => setModal(null)}
                    >
                        <div className="input-container">
                            {/*<label className="label">{l10nCreateOrder?.phoneNumberPlaceholder}</label>*/}
                            <Input
                                value={userPhoneNumber}
                                onChange={setUserPhoneNumber}
                                placeholder={l10nCreateOrder?.phoneNumberPlaceholder}
                                keyboardType="phone-pad"
                                style={{color: colors.black, width: '95%'}}
                            />
                        </div>
                    </CustomModal>
                );
            case'successfulCancellation':
                return (
                    <CustomModal
                        visible={true}
                        title={'Successful Cancellation'}
                        description={'The order is canceled successfully.'}
                        button1Text={'OK'}
                        button1CallAction={() => setModal(null)}
                    />
                );
            case'selectReason':
                return (
                    <CustomModal
                        visible={true}
                        title={'Select Cancellation Reason'}
                        description={'In order to cancel an order, you have to select a reason for cancellation.'}
                        button1Text={'OK'}
                        button1CallAction={() => setModal(null)}
                        onClose={() => setModal(null)}
                    />
                );
            case 'createTaxiOrder':
                return (
                    <>
                        <CustomModal
                            visible={true}
                            title={'Create Taxi Order'}
                            closeButton={true}
                            onClose={() => setModal(null)}
                        >
                            <BookingTaxi
                                onClose={() => setModal(null)}
                                userPhoneNumber={userPhoneNumber}
                            />
                        </CustomModal>
                    </>
                );
            default:
                return null;
        }
    };

    const handleConfirmCancellation = async () => {
        if (!selectedTaxiOrder) return;

        const { order_id } = selectedTaxiOrder;
        setCancelingOrderId(order_id);

        try {
            setWaitingForResponse(true);
            setAdditionalData({
                cancelingOrderId: order_id,
                waitingForResponse: true
            })
            if (!selectedCancellationReason || selectedCancellationReason.length === 0) {
                setModal('selectReason');
                setWaitingForResponse(false);
                setAdditionalData({
                    cancelingOrderId: null,
                    waitingForResponse: false
                })
                return;
            }

            const { pickup_location, delivery_location, route, timeline } = selectedTaxiOrder;

            await onFinishOrder(order_id, route, timeline);
            await onCancelOrder(order_id, pickup_location, delivery_location, route);

            setPendingOrders(prevOrders => prevOrders.filter(order => order.order_id !== order_id));
            setActiveOrders(prevOrders => prevOrders.filter(order => order.order_id !== order_id));
        } catch (error) {
            console.error("Error confirming cancellation:", error);
        } finally {
            setWaitingForResponse(false);
            setAdditionalData({
                cancelingOrderId: null,
                waitingForResponse: false
            })
            setCancelingOrderId(null);
        }
    };


    const handleCreateOrder = async () => {
        if (!userPhoneNumber) {
            console.error("No telephone number provided");
        } else if (userPhoneNumber && isValidPhoneNumber(userPhoneNumber)) {
            try {
                setUserPhoneNumber(userPhoneNumber)
                setModal('createTaxiOrder');
            } catch (error) {
                console.error('Error creating order:', error);
            }
        } else {
            console.log('Not valid user number')
        }
    };

    const onFinishOrder = async (orderId, route, timeline) => {
        try {
            await taxiApi.updateCompleteTaxiRoute(orderId, route);
            await taxiApi.updateTaxiOrderTimeline(orderId, [
                ...timeline,
                {
                    status: TAXI_ORDER_STATUS.COMPLETED,
                    order_id: orderId,
                    location: {
                        timestamp: getLocalISOTime()
                    }
                }
            ]);
        } catch (error) {
            console.error("Error finishing order [route, timeline]:", error);
        }
    };

    const onCancelOrder = async (orderId, pickupLocation, deliveryLocation, route) => {
        try {
            const response = await taxiApi.cancelTaxiOrder(
                orderId,
                TAXI_ORDER_STATUS.CANCELED,
                selectedCancellationReason && selectedCancellationReason.some(reason => reason.value === 'other') ? message : selectedCancellationReason || null
            );
            if (response) {
                console.log("Order canceled successfully:", response);
                setSelectedCancellationReason([]);
                setMessage('');
                setModal('successfulCancellation');
            } else {
                console.error("Failed to cancel order:", response);
            }
        } catch (error) {
            console.error("Error canceling order:", error);
        }
    };

    const fetchRegisteredDrivers = async () => {
        try {
            const drivers = await driverApi.listDrivers();
            if (drivers && !drivers.error) {
                setDrivers(drivers);
            } else {
                setDrivers([]);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchRegisteredDrivers().then(() => console.log("All drivers fetched."));

        const intervalId = setInterval(() => {
            fetchRegisteredDrivers();
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);

    const fetchTaxiOrders = async () => {
        try {
            let response = await taxiApi.getTaxiOrders();
            response = response.filter(order => order.type === ORDER_TYPE.TAXI);
            if (response && !response.error) {

                const orders = response.map(order => ({
                    ...order,
                    order_id: order.order_id,
                    driver_name: `${order.driver?.user?.first_name || ''} ${order.driver?.user?.last_name || ''}`,
                    vehicle: `${order.driver?.vehicles[0]?.make || ''} ${order.driver?.vehicles[0]?.model || ''}`,
                    status: order.status,
                    created_at: order.created_at,
                    price: order.payment ? `${order.payment.price}€` : 'N/A',
                    pickup_location: order.pickup_location?.address || 'N/A',
                    delivery_location: order.delivery_location?.address || 'N/A',
                }));

                setPendingOrders(orders.filter(order => order.status === TAXI_ORDER_STATUS.PENDING));
                setActiveOrders(orders.filter(order =>
                    order.status === TAXI_ORDER_STATUS.DRIVING ||
                    order.status === TAXI_ORDER_STATUS.WAITING ||
                    order.status === TAXI_ORDER_STATUS.ACCEPTED ||
                    order.status === TAXI_ORDER_STATUS.ARRIVED ||
                    order.status === TAXI_ORDER_STATUS.TAXI_ARRIVED
                ));
                setCompletedOrders(orders.filter(order => order.status === TAXI_ORDER_STATUS.COMPLETED));
                setCanceledOrders(orders.filter(order =>
                    order.status === TAXI_ORDER_STATUS.CANCELED ||
                    order.status === TAXI_ORDER_STATUS.TAXI_CANCELED ||
                    order.status === TAXI_ORDER_STATUS.REJECTED
                ));
            }
        } catch (error) {
            console.error('Error fetching taxi orders:', error);
        }
    };

    useEffect(() => {
        fetchTaxiOrders().then(() => console.log('Taxi orders fetched.'));

        const intervalId = setInterval(fetchTaxiOrders, 10000); // Fetch orders every 10 seconds

        return () => clearInterval(intervalId);
    }, []);

    const activeColumnsConfig = {
        tags: '',
        order_id: 'Order ID',
        driver_name: 'Driver',
        vehicle: 'Vehicle',
        status: 'Status',
        created_at: 'Created',
        price: 'Price',
    };

    const completedColumnsConfig = {
        tags: '',
        order_id: 'Order ID',
        driver_name: 'Driver',
        vehicle: 'Vehicle',
        status: 'Status',
        created_at: 'Created',
        price: 'Price',
    };

    const pendingColumnsConfig = {
        tags: '',
        order_id: 'Order ID',
        user_name: 'User',
        status: 'Status',
        created_at: 'Created',
        cancel: 'Cancel',
    };

    const canceledRejectedColumnsConfig = {
        tags: '',
        order_id: 'Order ID',
        driver_name: 'Driver',
        vehicle: 'Vehicle',
        status: 'Status',
        created_at: 'Created',
    };

    const renderMap = () => {
        return (
            <div style={{marginBottom: '20px'}}>
                <Map
                    drivers={drivers}
                    display_driver_activity_legend={true}
                />
            </div>
        );
    };

    const renderPendingOrders = (data) => {
        const tags = [];
        if (data.is_scheduled) {
            tags.push(
                <span
                    key="scheduled-ride-tag"
                    style={{
                        backgroundColor: colors.red,
                        borderRadius: '8px',
                        color: 'white',
                        padding: '2px 4px',
                        fontSize: '10px',
                        fontWeight: 'bold',
                        marginRight: '4px',
                        display: 'inline-block',
                    }}
                >
                    Scheduled ride
                </span>
            );
        }

        return data.map(order => ({
            ...order,
            tags: <div>{tags}</div>,
            user_name: [
                order?.user?.first_name,
                ' ',
                `${order?.user?.last_name.charAt(0)}.`
            ],
            cancel: (
                <>
                    <Button
                        loading={waitingForResponse && cancelingOrderId === order.order_id}
                        spinnerColor={colors.red}
                        cancel
                        action={(e) => {
                            e.stopPropagation();
                            setSelectedTaxiOrder(order);
                            setModal('cancelOrder');
                        }}
                    />
                </>

            )
        }));
    };

    const handleSetModal = (data) => {
        setModal(data);
    };

    const handleSetTaxiOrder = (data) => {
        setSelectedTaxiOrder(data);
    };

    const actionMap = {
        taxi_order_view: {
            setModal: handleSetModal,
            setTaxiOrder: handleSetTaxiOrder,
        },
    };

    return (
        <div>
            {renderMap()}

            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
                <h4 style={{margin: 0}}>Pending Taxi Orders</h4>
                <Button
                    action={() => setModal('createOrder')}
                    style={{
                        backgroundColor: colors.yellow
                    }}
                    styleText={{color: colors.white}}
                    create_order
                    text={'Create New Order'}
                >
                </Button>
            </div>

            <DynamicTable
                data={renderPendingOrders(pendingOrders)}
                columnsConfig={pendingColumnsConfig}
                rowsPerPage={8}
                extendable={modal !== 'cancelOrder'}
            />

            <h4>Active Taxi Orders</h4>
            <DynamicTable
                data={activeOrders}
                columnsConfig={activeColumnsConfig}
                rowsPerPage={8}
                extendable={true}
                actionMap={actionMap.taxi_order_view}
                additionalData={additionalData}
            />

            <h4>Completed Taxi Orders</h4>
            <DynamicTable
                data={completedOrders}
                columnsConfig={completedColumnsConfig}
                rowsPerPage={8}
                extendable={true}
            />

            <h4>Canceled or Rejected Taxi Orders</h4>
            <DynamicTable
                data={canceledOrders}
                columnsConfig={canceledRejectedColumnsConfig}
                rowsPerPage={8}
                extendable={true}
            />

            {renderModals()}
        </div>
    );
};

export default TaxiOrdersOverwatch;
