import React, {useState} from 'react';
import '../css/BasicInfo.css';
import {getColorByValue} from "../../lib/dataParsingHelpers";
import Map from "./Map";
import ContactForm from "./ContactForm";
import DriverApi from "../../apis/driverApi";
import CustomModal from "./CustomModal";

const EditDriver = ({ driverData }) => {

    const [modal, setModal] = useState(null)
    if (!driverData) {
        return <p>Loading...</p>;
    }

    const reformatData = (data) => {
        if (!data) {
            return data;
        }

        const reformattedDocuments = data.documents?.reduce((acc, doc) => {
            if (doc?.document_type) {
                acc[doc.document_type.toLowerCase().replace(' ', '_')] = doc;
            }
            return acc;
        }, {});

        const reformattedVehicles = data.vehicles?.[0]?.documents?.reduce((acc, doc) => {
            if (doc?.document_type) {
                acc[doc.document_type.toLowerCase().replace(' ', '_')] = doc;
            }
            return acc;
        }, {});

        const reformattedUserDocuments = data.user?.documents?.reduce((acc, doc) => {
            if (doc?.document_type) {
                acc[doc.document_type.toLowerCase().replace(' ', '_')] = doc;
            }
            return acc;
        }, {});


        return {
            ...data,
            documents: reformattedDocuments,
            vehicles: data.vehicles?.length > 0 ? [
                {
                    ...data.vehicles[0],
                    documents: reformattedVehicles || {}
                }
            ] : [],
            user: {
                ...data.user,
                documents: reformattedUserDocuments || {}
            }
        };
    };

    const onSave = async (data) => {
        try {
            const response = await DriverApi.editDriver(data)
            if (response && !response.error) {
                setModal('success')
            }
        } catch (e) {
            setModal('error')
            console.log('ERROR editing driver', e)
        }
    }

    return (
        <div className="order-extended-content" style={{display: 'flex', justifyContent: 'space-between'}}>


                <div className="driver-info-container">
                    <div className="driver-info-grid">
                            <ContactForm initialData={reformatData(driverData)} onSave={onSave} />

                    </div>
                </div>

            <CustomModal
                visible={modal === 'success'}
                title="Driver Edit Successful"
                description="The driver's information has been successfully updated."
                button1Text="OK"
                button1CallAction={() => setModal(null)}
                onClose={() => setModal(null)}
                yellow
            />

            <CustomModal
                visible={modal === 'error'}
                title="Error Editing Driver"
                description="There was an error updating the driver's information. Please try again."
                button1Text="OK"
                button1CallAction={() => setModal(null)}
                onClose={() => setModal(null)}
                yellow
            />


        </div>
    );
};

export default EditDriver;
