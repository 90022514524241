import React, {useEffect, useState} from "react";
import {APP_SERVICES, DELIVERY_ORDER_STATUS, SORTING_TYPE} from "../../../constants/enums";
import deliveryApi from "../../../apis/deliveryApi";
import deliveryDriverApi from "../../../apis/deliveryDriverApi";
import userApi, {updateScheduledUser} from "../../../apis/userApi";
import businessApi from "../../../apis/businessApi";
import {getColorByValue} from "../../../lib/dataParsingHelpers";
import DynamicTable from "../DynamicTable";
import {getLocalisedTexts} from "../../../l10n/localisations/languages";
import GeneralPicker from "../GeneralPicker";
import './../../css/SortingSwitch.css';

const DailyMealsOverwatch = () => {
    const l10nText = getLocalisedTexts('DRIVER_LIST');
    const [usersFormOpen, setUsersFormOpen] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [allUsersList, setAllUsersList] = useState([]);
    const [nonSubscribedUsers, setNonSubscribedUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingEditUser, setLoadingEditUser] = useState(false);
    const [userIndex, setUserIndex] = useState(-1);
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);
    const [addUserPopup, setAddUserPopup] = useState(false);
    const [submitPopup, setSubmitPopup] = useState(false);
    const [driversList, setDriversList] = useState([]);
    const [driversListResponsibleForDailyMeals, setDriversListResponsibleForDailyMeals] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [businessesList, setBusinessesList] = useState([]);
    const [businessesOfferingDailyMeals, setBusinessesOfferingDailyMeals] = useState([]);
    const [sortingType, setSortingType] = useState(businessesOfferingDailyMeals[0] && businessesOfferingDailyMeals[0]?.daily_users_sorting_type === SORTING_TYPE.MANUAL ? SORTING_TYPE.MANUAL : SORTING_TYPE.AUTOMATIC)

    const [selectedBusiness, setSelectedBusiness] = useState(null);

    const [waitingForResponse, setWaitingForResponse] = useState(false);

    const [deliveryOrdersList, setDeliveryOrdersList] = useState([])
    const [filteredDeliveryOrders, setFilteredDeliveryOrders] = useState([]);

    const [floorNumber, setFloorNumber] = useState("")
    const [doorNumber, setDoorNumber] = useState("")
    const [customerNote, setCustomerNote] = useState('')

    const service = APP_SERVICES.DELIVERY; // or 'delivery'
    const [onlineDrivers, setOnlineDrivers] = useState(0)
    const [registeredDrivers, setRegisteredDrivers] = useState(0);
    const [completedOrdersToday, setCompletedOrdersToday] = useState(0)
    const [totalDailyEarnings, setTotalDailyEarnings] = useState(0)

    useEffect(() => {
        const fetchDeliveryOrders = async () => {
            try {
                let orders = await deliveryApi.getDeliveryOrders()
                if (orders && !orders.error) {
                    orders = orders.filter(order => order?.is_daily_meal);
                    const ordersWithDriverData = await Promise.all(orders.map(async (order) => {
                        if (order.delivery_driver?.user_id) {
                            const driverData = await deliveryDriverApi.getDeliveryDriverByUserId(order.delivery_driver.user_id);
                            if (driverData) {
                                return {
                                    ...order,
                                    type: APP_SERVICES.DELIVERY,
                                    delivery_driver: {
                                        ...order.delivery_driver,
                                        user: driverData.user,
                                        vehicles: driverData.vehicles,
                                    },
                                };
                            }
                        }
                        return order;
                    }));

                    setDeliveryOrdersList(ordersWithDriverData)
                    setFilteredDeliveryOrders(ordersWithDriverData)

                    // Get today's date
                    const today = new Date();
                    const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                    const endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);

                    // Filter completed orders completed today
                    const completedTodayOrders = orders.filter(order => {
                        const completedTimeline = order.timeline.find(event => event.status === DELIVERY_ORDER_STATUS.COMPLETED);
                        if (completedTimeline) {
                            const completedDate = new Date(completedTimeline.location.timestamp);
                            return completedDate >= startOfDay && completedDate < endOfDay;
                        }
                        return false;
                    });

                    // Calculate total price for completed orders today
                    const totalPrice = completedTodayOrders.reduce((acc, order) => {
                        const deliveryCost = parseFloat(order.details?.delivery_cost || 0);
                        const deliveryEarnings = parseFloat(order.details?.delivery_earnings || 0);
                        console.log('deliveryCost', deliveryCost, 'deliveryEarnings', deliveryEarnings)
                        return acc + deliveryCost + deliveryEarnings;
                    }, 0);

                    // Set the total price and the number of completed orders
                    setTotalDailyEarnings(totalPrice);
                    setCompletedOrdersToday(completedTodayOrders.length);

                } else {
                    // Handle the case where orders are null or there's an error
                    setDeliveryOrdersList([]);
                    setTotalDailyEarnings(0);
                    setCompletedOrdersToday(0);
                }
            } catch (e) {
                // Handle errors in the fetch process
                console.error('Error fetching scheduled orders:', e);
                setDeliveryOrdersList([]);
            }
        };

        fetchDeliveryOrders()

        const intervalId = setInterval(() => {
            fetchDeliveryOrders();
        }, 10000);

        return () => clearInterval(intervalId);

    }, []);

    useEffect(() => {
        listUsers()

        const intervalId = setInterval(() => {
            listUsers()
        }, 60000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
              fetchScheduledUsers()

        const intervalId = setInterval(() => {
            fetchScheduledUsers();
        }, 10000);

        return () => clearInterval(intervalId);
    }, [])

    const fetchScheduledUsers = async () => {
        try {
            const response = await userApi.getScheduledUsers()
            if (response && !response.error) {
                const transformedUsers = response.map(user => {
                    const dateOfBirth = new Date(user.date_of_birth);
                    return {
                        firstName: user.first_name,
                        lastName: user.last_name,
                        dayOfBirth: dateOfBirth.getUTCDate().toString(),
                        monthOfBirth: (dateOfBirth.getUTCMonth() + 1).toString(),
                        yearOfBirth: dateOfBirth.getUTCFullYear().toString(),
                        email: user.email,
                        password: '********',
                        phone: user.telephone_number,
                        fullPhoneNumber: user.telephone,
                        countryCode: user.telephone_code,
                        user_id: user.user_id,
                        personal_address: user.addresses[0]?.address || '',
                        note: user.details?.note ? user.details.note : '',
                        floor_number: user.details?.floor_number ? user.details.floor_number : '',
                        door_number: user.details?.door_number ? user.details.door_number : '',
                        daily_meal_preferences: user?.daily_meal_preferences,
                        daily_meal_day_preferences: user?.daily_meal_day_preferences || []
                    };
                });
                setUsersList(transformedUsers);
            }
        } catch (e) {
            console.log(e)
        }
    }

    const listUsers = async () => {
        try {
            const response = await userApi.getPersonalUsers();
            if (response && !response.error) {
                const subscribedUsers = response.filter(user => user.subscribed_to_daily_meals);
                const nonSubscribedUsers = response.filter(user => !user.subscribed_to_daily_meals);

                setAllUsersList(response);
                setNonSubscribedUsers(nonSubscribedUsers)
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const fetchMerchantBusinesses = async () => {
            try {
                const response = await businessApi.loadMerchantBusinesses();
                if (response && !response.error) {
                    const filteredBusinesses = response.filter((business) => business.offers_daily_meals)
                    setBusinessesOfferingDailyMeals(filteredBusinesses)
                    setBusinessesList(response)
                }
            } catch (e) {
                console.log(e)
            }
        }

        fetchMerchantBusinesses()

        const intervalId = setInterval(() => {
            fetchMerchantBusinesses();
        }, 10000);

        return () => clearInterval(intervalId);
    }, [selectedBusiness]);

    useEffect(() => {
        const fetchDeliveryDrivers = async () => {
            try {
                const response = await deliveryDriverApi.listDeliveryDrivers()
                if (response && !response.error) {
                    const filteredDrivers = response.filter((driver) => driver.delivers_daily_meals)
                    setDriversListResponsibleForDailyMeals(filteredDrivers)
                    setRegisteredDrivers(filteredDrivers.length)
                    const onlineDeliveryDrivers = filteredDrivers.filter(driver => driver.online)
                    setOnlineDrivers(onlineDeliveryDrivers.length)
                    setDriversList(response)
                }
            } catch (e) {
                console.log(e)
            }
        }

        fetchDeliveryDrivers()

        const intervalId = setInterval(() => {
            fetchDeliveryDrivers()
        }, 1000);

        return () => clearInterval(intervalId);
    }, [selectedDriver]);

    const columnsConfig = {
        order_id: 'Order ID',
        driver_name: 'Driver',
        vehicle: 'Vehicle',
        vehicle_color: 'Vehicle Color',
        status: 'Status',
        created_at: 'Created',
        pickup_location: 'Pickup Location',
    };

    const parseOrderData = (orders) =>
        orders.map((order) => ({
            order_id: order.order_id.slice(0, 6),
            driver_name: `${order?.delivery_driver?.user?.first_name ?? 'N/A'} ${order?.delivery_driver?.user?.last_name ?? ''}`,
            vehicle: `${order?.delivery_driver?.vehicles[0]?.make ?? 'N/A'} ${order?.delivery_driver?.vehicles[0]?.model ?? 'N/A'}`,
            vehicle_color: getColorByValue(order?.delivery_driver?.vehicles[0]?.color),
            status: order.status,
            created_at: new Date(order.created_at).toLocaleString('sl-SI', {timeZone: 'Europe/Ljubljana'}),
            pickup_location: order?.pickup_location?.address ?? 'N/A',
        }));

    const driverColumnsConfig = {
        online: l10nText?.activity || 'Online',
        driver: l10nText?.driver || 'Driver',
        vehicle: l10nText?.vehicle_make_model || 'Vehicle Make/Model',
        vehicle_color: l10nText?.vehicle_color || 'Vehicle Color',
        license_plate: l10nText?.vehicle_license_plate || 'License plate',
        status: l10nText?.status || 'Status',
    };

    const parseDriverData = driversListResponsibleForDailyMeals.map(driver => ({
        online: (
            <span className={`status-dot ${driver?.online ? 'online' : 'offline'}`}/>
        ),
        driver: `${driver?.user?.first_name} ${driver?.user?.last_name}`,
        vehicle: `${driver?.vehicles[0]?.make ?? ''} ${driver?.vehicles[0]?.model ?? ''}`,
        vehicle_color: getColorByValue(driver?.vehicles[0]?.color),
        license_plate: driver?.vehicles[0]?.license_plate,
        status: !driver?.online ? "Offline" : driver?.on_order ? "On delivery ride" : "Waiting",
        delivery_driver_id: driver.delivery_driver_id
    }));


    const restaurantColumnsConfig = {
        name: 'Name',
        phone: 'Telephone',
        address: 'Address'
    };

    const parseRestaurantData = businessesOfferingDailyMeals.map(provider => ({
        name: provider?.name,
        phone: provider?.telephone,
        address: provider?.address?.address,
        business_id: provider.business_id
    }));

    const usersColumnsConfig = {
        name: 'Contact Name',
        phone: 'Telephone',
        address: 'Address'
    };

    const parseUserData = usersList.map(user => ({
        name: `${user?.firstName} ${user?.lastName}`,
        phone: user?.phone,
        address: user?.personal_address,
        daily_meal_preferences: user?.daily_meal_preferences || {"normal":{"amount":0},"substitution":{"amount":0}},
        daily_meal_day_preferences: user?.daily_meal_day_preferences,
        user_id: user.user_id
    }));

    const updateDriverResponsibleForDailyMeals = async (driver, dailyMeals) => {
        try {
            const response = await deliveryDriverApi.updateDeliveryDriver(driver.delivery_driver_id, {
                delivers_daily_meals: dailyMeals
            })
            if (response && !response.error) {
                setSelectedDriver(driver)
            }
        } catch (er) {
            console.log(er)
        }
    }

    const updateBusinessResponsibleForDailyMeals = async (business, offersDailyMeals) => {
        try {
            const response = await businessApi.updateBusiness({
                business_id: business.business_id,
                offers_daily_meals: offersDailyMeals
            })
            if (response && !response.error) {
                setSelectedBusiness(business)
            }
        } catch (er) {
            console.log(er)
        }
    }

    const setScheduledUser = async (user) => {
        try {
            const response = await userApi.updateUserByUserId(user.user_id, {
                subscribed_to_daily_meals: true
            })
            if (response && !response.error) {
                const dateOfBirth = new Date(user.date_of_birth);
                const newUser = {
                    firstName: user.first_name,
                    lastName: user.last_name,
                    dayOfBirth: dateOfBirth.getUTCDate().toString(),
                    monthOfBirth: (dateOfBirth.getUTCMonth() + 1).toString(),
                    yearOfBirth: dateOfBirth.getUTCFullYear().toString(),
                    email: user.email,
                    password: '********',
                    phone: user.telephone_number,
                    fullPhoneNumber: user.telephone,
                    countryCode: user.telephone_code,
                    user_id: user.user_id,
                    personal_address: user?.addresses[0]?.address || '',
                    note: user.details?.note ? user.details.note : '',
                    floor_number: user.details?.floor_number ? user.details.floor_number : '',
                    door_number: user.details?.door_number ? user.details.door_number : '',
                    daily_meal_preferences: user?.daily_meal_preferences,
                    daily_meal_day_preferences: user?.daily_meal_day_preferences
                }
                const updatedList = [...usersList, newUser]
                setUsersList(updatedList);
                const userIds = updatedList.map(user => user.user_id);
                await listUsers()
                await businessApi.manualSortScheduledUsers(userIds);
            } else {
                console.error("Error response from API:", response);
            }
        } catch (e) {
            console.error(e)
        }
    }

    const handleSortingType = async (type) => {
        try {
            const response = await businessApi.addScheduledUserSortingType(type)
            if (response && !response.error) {
                setSortingType(type)
            }
        } catch (e) {
            console.error(e)
        }
    }

    const handleDailyMealsRemoveItem = (data) => {
        if (data?.user_id) {
            updateScheduledUser(data?.user_id, {
                subscribed_to_daily_meals: false
            }, null).then(() => {
                setUsersList((prevUsersList) => prevUsersList.filter(user => user.user_id !== data.user_id));
            }).catch(err => console.error(err));
            return;
        }
        if (data?.business_id) {
            updateBusinessResponsibleForDailyMeals(data, false)
            return
        }
        if (data?.delivery_driver_id) {
            updateDriverResponsibleForDailyMeals(data, false)
        }
    }

    const handleDailyMealPreferences = async (data) => {
        try {
            const response = await userApi.updateScheduledUser(
                data?.user_id,
                { daily_meal_preferences: data?.daily_meal_preferences}, null)
            if (response && !response.error) {
                console.log('Scheduled user updated successfully!')
            }
        } catch (e) {
            console.error('handleDailyMealPreferences', e)
        }
    }

    const handleDailyMealDayPreferences = async (data) => {
        try {
            const response = await userApi.updateScheduledUser(
                data?.user_id,
                { daily_meal_day_preferences: data?.daily_meal_day_preferences}, null)
            if (response && !response.error) {
                console.log('Scheduled user pref. days updated successfully!')
            }
        } catch (e) {
            console.error('handleDailyMealDayPreferences', e)
        }
    }

    const handleUserAddress = async (user_id, address) => {
        console.log('USER ADDRESS', address)
        if (address?.address === '' || address?.coordinates === null) {
            return
        }
        try {
            await userApi.updateScheduledUser(
                user_id,
                null,
                [{
                    address: address?.address,
                    latitude: address?.coordinates?.latitude?.toString(),
                    longitude: address?.coordinates?.longitude?.toString()
                }]
            );
        } catch (e){
            console.error('User address cannot be updated', e)
        }
    }

    const actionMap = {
        daily_meals_view: {
            handleDailyMealsRemoveItem: handleDailyMealsRemoveItem,
            handleDailyMealPreferences: handleDailyMealPreferences,
            handleDailyMealDayPreferences: handleDailyMealDayPreferences,
            setAddressObject: handleUserAddress
        },
    };

    return (
        <div>
            <h3>Restaurant Providers Responsible for Daily Meals</h3>
            <GeneralPicker
                items={businessesList}
                onSelect={(selected) => updateBusinessResponsibleForDailyMeals(selected, true)}
                displayProperty="name"
                valueProperty="business_id"
                label="Select Business Responsible for Daily Meals"
            />
            <DynamicTable
                data={parseRestaurantData}
                columnsConfig={restaurantColumnsConfig}
                rowsPerPage={8}
                extendable={true}
                viewType={'daily_meals_view'}
                actionMap={actionMap.daily_meals_view}
            />


            <h3>Delivery Drivers Responsible for Daily Meals</h3>
            <GeneralPicker
                items={driversList}
                onSelect={(driver) => updateDriverResponsibleForDailyMeals(driver, true)}
                displayProperty="user.first_name user.last_name"
                valueProperty="delivery_driver_id"
                label="Select Delivery Driver"
            />
            <DynamicTable
                data={parseDriverData}
                columnsConfig={driverColumnsConfig}
                rowsPerPage={8}
                extendable={true}
                viewType={'daily_meals_view'}
                actionMap={actionMap.daily_meals_view}
            />

            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px', justifyContent: 'space-between'}}>
                <h3 style={{margin: 0}}>Scheduled Users</h3>
                <div className="sorting-switch">
                    <button
                        className={`sorting-button ${sortingType === 'AUTOMATIC' ? 'active' : ''}`}
                        onClick={() => handleSortingType('AUTOMATIC')}
                    >
                        Automatic
                    </button>
                    <button
                        className={`sorting-button ${sortingType === 'MANUAL' ? 'active' : ''}`}
                        onClick={() => handleSortingType('MANUAL')}
                    >
                        Manual
                    </button>
                </div>
            </div>
                <GeneralPicker
                    items={nonSubscribedUsers}
                    onSelect={(user) => setScheduledUser(user, true)}
                    displayProperty="first_name last_name"
                    valueProperty="user_id"
                    label="Select Scheduled User"
                />

            <DynamicTable
                data={parseUserData}
                columnsConfig={usersColumnsConfig}
                rowsPerPage={8}
                extendable={true}
                viewType="daily_meals_view"
                actionMap={actionMap.daily_meals_view}
            />

            <h3>Active Daily Meal Orders</h3>
            <DynamicTable
                data={parseOrderData(
                    deliveryOrdersList.filter(
                        order =>
                            order.status !== DELIVERY_ORDER_STATUS.PENDING &&
                            order.status !== DELIVERY_ORDER_STATUS.CANCELED &&
                            order.status !== DELIVERY_ORDER_STATUS.REJECTED &&
                            order.status !== DELIVERY_ORDER_STATUS.COMPLETED &&
                            order.status !== DELIVERY_ORDER_STATUS.ARRIVED &&
                            order.status !== DELIVERY_ORDER_STATUS.DELIVERED
                    )
                )}
                columnsConfig={columnsConfig}
                rowsPerPage={5}
            />

            <h3>Completed Daily Meal Orders</h3>
            <DynamicTable
                data={parseOrderData(
                    deliveryOrdersList.filter(order => order.status === DELIVERY_ORDER_STATUS.COMPLETED)
                )}
                columnsConfig={columnsConfig}
                rowsPerPage={5}
            />
        </div>
    )
}

export default DailyMealsOverwatch