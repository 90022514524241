import React, {useRef, useState} from 'react';
import Button from "./Button";
import colors from "../../constants/colors";
import Input from "./Input";
import { AxiosHeaders as Geocoder } from "axios";
import CustomModal from "./CustomModal";
import { getLocalisedTexts } from "../../l10n/localisations/languages";
import swapIcon from '../../assets/icons/swap.png';
import {getRepeatDays, getRepeatWeeks, getTaxiVehicleOptions} from "../../constants/keyValueOptions";
import {ORDER_TYPE, PAYMENT_STATUS, PAYMENT_TYPE, VEHICLE_CATEGORY, VEHICLE_CLASS} from "../../constants/enums";
import Numbering from "./Numbering";
import RideRequirements from "./RideRequirements";
import RadioButtons from "./RadioButtons";
import DatePickerComponent from "./DatePicker";
import './../css/BookingTaxi.css'
import TimePickerComponent from "./TimePickerComponent";
import {createTaxiOrderHandle} from "../../lib/orderHelpers";
import {useSelector} from "react-redux";
import {useSocket} from "../../socket/SocketProvider";
import {convertToIsoTimestamp} from "../../lib/dateUtils";
import AddressInput from "./AddressInput";

const BookingTaxi = ({ onClose, transfer = false, userPhoneNumber }) => {
    const l10nText = getLocalisedTexts('TAXI_SELECTION_MAP');
    const l10nTextGeneral = getLocalisedTexts('GENERAL');
    const l10nTaxi = getLocalisedTexts('BOOK_TAXI');
    const user = useSelector(state => state.user);
    let { joinRoom } = useSocket();
    const [waitingForResponse, setWaitingForResponse] = useState(false)

    console.log(userPhoneNumber, 'user phone number')

    const [originAddress, setOriginAddress] = useState('');
    const [originAddressObject, setOriginAddressObject] = useState({ address: '', coordinates: null });
    const [destinationAddress, setDestinationAddress] = useState('');
    const [destinationAddressObject, setDestinationAddressObject] = useState({ address: '', coordinates: null });
    const [stopoverAddresses, setStopoverAddresses] = useState([]);
    const [stopoverAddressesPredicted, setStopoverAddressesPredicted] = useState([]);
    const [shouldAutocomplete, setShouldAutocomplete] = useState(true);
    const [modal, setModal] = useState(null);
    const [quantityAdults, setQuantityAdults] = useState(1);
    const [quantityChildrenUnder140cm, setQuantityChildrenUnder140cm] = useState(0);
    const [quantityChildrenAbove140cm, setQuantityChildrenAbove140cm] = useState(0);
    const [requirements, setRequirements] = useState({
        traveling_with_pet: false,
        luggage: false,
        air_conditioning: false,
        child_seats: false,
        wheelchair_accessibility: false,
        quiet_ride: false,
    });

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [vehicleCategories, setVehicleCategories] = useState(['STANDARD', 'PREMIUM', 'ANY']);
    const vehicles = getTaxiVehicleOptions()

    let repeatDays = getRepeatDays();
    const [_scheduleRide, _setScheduleRide] = useState(null);
    const [_departureDate, _setDepartureDate] = useState(null)
    const [departureTimeDateISO, setDepartureTimeDateISO] = useState(null);
    const [_repeatRide, _setRepeatRide] = useState([repeatDays[0]])
    const [_repeatDuration, _setRepeatDuration] = useState(null);

    const handleRemoveStopover = (index) => {
        const updatedStopovers = [...stopoverAddresses];
        const updatedStopoverPredicted = [...stopoverAddressesPredicted]
        updatedStopoverPredicted.splice(index, 1)
        updatedStopovers.splice(index, 1);
        setStopoverAddresses(updatedStopovers);
        setStopoverAddressesPredicted(updatedStopoverPredicted)
    };

    const handleSwap = () => {
        setShouldAutocomplete(false);
        const tempOrigin = originAddress;
        const tempDestination = destinationAddress;
        const tempOriginObject = originAddressObject;
        const tempDestinationObject = destinationAddressObject;

        setOriginAddress(tempDestination);
        setDestinationAddress(tempOrigin);
        setOriginAddressObject(tempDestinationObject);
        setDestinationAddressObject(tempOriginObject);

        setTimeout(() => {
            setShouldAutocomplete(true);
        }, 0);
    };

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        if (category === VEHICLE_CATEGORY.ANY) {
            setSelectedVehicle(VEHICLE_CLASS.ANY)
        }
    };

    const handleVehicleChange = (vehicle) => {
        setSelectedVehicle(vehicle);
    };

    const renderRequirementsView = () => {
        return (
            <div>
                <Numbering title={l10nTaxi?.numAdults} value={quantityAdults} onUpdate={setQuantityAdults}
                           yellow={!transfer} green={transfer}/>
                <Numbering title={l10nTaxi?.numChildrenBig} value={quantityChildrenAbove140cm}
                           onUpdate={setQuantityChildrenAbove140cm}
                           yellow={!transfer} green={transfer}/>
                <Numbering title={l10nTaxi?.numChildrenSmall} value={quantityChildrenUnder140cm}
                           onUpdate={setQuantityChildrenUnder140cm}
                           yellow={!transfer} green={transfer}/>
                <RideRequirements selectedExtras={requirements} setSelectedExtras={setRequirements} l10nText={l10nTaxi} transfer={transfer}/>

                <h4 >{l10nTaxi?.scheduleRideLabel}</h4>
                <RadioButtons
                    value={_scheduleRide}
                    items={[
                        {name: l10nTaxi?.scheduleRide, value: "schedule_ride"}
                    ]}
                    yellow={!transfer} green={transfer}
                    onSelectionChange={(selectedItems) => {
                        _setScheduleRide(selectedItems);
                    }}
                />

                {_scheduleRide && _scheduleRide.some(requirement => requirement.value === "schedule_ride") && (
                    <>
                        <div style={{marginBottom: 8}}>
                            <DatePickerComponent
                                yellow={!transfer} green={transfer}
                                        date={_departureDate}
                                        setDate={_setDepartureDate}
                                        title={l10nTaxi?.departureDate}
                                        placeholder={l10nTaxi?.departureDatePlaceholder}/>

                            <TimePickerComponent
                                        time={departureTimeDateISO}
                                        setTime={setDepartureTimeDateISO}
                                        title={l10nTaxi?.departureTime}
                                        placeholder={l10nTaxi?.departureTimePlaceholder}/>

                            <div>
                                <h4>{l10nTaxi?.repeatRide}</h4>
                                <RadioButtons
                                    items={getRepeatDays()}
                                    value={_repeatRide}
                                    yellow={!transfer} green={transfer}
                                    onSelectionChange={(selectedItems) => {
                                        _setRepeatRide(selectedItems);
                                    }}
                                />
                            </div>
                            {!_repeatRide?.empty && _repeatRide?.some(ride => ride.value !== repeatDays[0].value) && (
                                <>
                                    <h4>{l10nTaxi?.repeatDuration}</h4>
                                    <RadioButtons
                                        singleToggle
                                        yellow={!transfer} green={transfer}
                                        value={_repeatDuration}
                                        items={getRepeatWeeks()}
                                        onSelectionChange={(selectedDuration) => {
                                            _setRepeatDuration(selectedDuration);
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </>
                )}

            </div>
        )
    }

    const filterInvalidStopovers = (stopovers) => {
        return stopovers.filter(stopover =>
            stopover.address !== '' && stopover.coordinates !== null
        );
    };

    const validRoute = (route) => {
        return route.every(location =>
            location.address !== '' && location.coordinates !== null
        );
    };

    const handleSubmit = async () => {
        console.log('CREATE TAXI ORDER');
        setWaitingForResponse(true);

        const stopovers = filterInvalidStopovers(stopoverAddressesPredicted);
        const isDestinationValid = destinationAddressObject?.address && destinationAddressObject?.coordinates;
        const destination = isDestinationValid ? destinationAddressObject : null;
        const route = [originAddressObject, ...stopovers, ...(isDestinationValid ? [destinationAddressObject] : [])];

        const isScheduled = _scheduleRide.length !== 0 && _scheduleRide[0]?.value === 'schedule_ride';
        if (isScheduled && (!_departureDate || !departureTimeDateISO)) {
            setWaitingForResponse(false);
            return;
        }

        if (!validRoute(route)) {
            setWaitingForResponse(false);
            return;
        }

        const taxiOrder = {
            timeline: [],
            type: !transfer ? ORDER_TYPE.TAXI : ORDER_TYPE.TRANSFER_PRIVATE,
            status: null,
            date: null,
            pickup_location: originAddressObject,
            delivery_location: destination,
            route: route,
            telephone: userPhoneNumber,
            preferences: {
                vehicle_category: VEHICLE_CATEGORY.STANDARD,
                vehicle_class: VEHICLE_CLASS.SEDAN,
                adults: quantityAdults,
                children_under_140: quantityChildrenUnder140cm,
                children_above_140: quantityChildrenAbove140cm,
                ride_requirements: requirements,
                scheduled_ride: _scheduleRide,
                departure_date: _departureDate,
                departure_time: _departureDate && departureTimeDateISO ? convertToIsoTimestamp(_departureDate, departureTimeDateISO) : null,
                repeat_ride: _repeatRide,
                repeat_duration: _repeatDuration
            },
            payment: {
                status: PAYMENT_STATUS.UNPAID,
                type: PAYMENT_TYPE.CREDIT_CARD,
                credit_card: {
                    issuer: null,
                    number: null
                },
                date: new Date().toLocaleString('sl-SI', { timeZone: 'Europe/Ljubljana' }),
                price: 0,
                distance: 0,
                duration: 0,
            },
            estimates: {
                price: 0,
                distance: 0,
                duration: 0,
                pickup_time_in_seconds: 0,
                pickup_time: 0
            },
        };

        try {
            await createTaxiOrderHandle(user, taxiOrder, joinRoom, !transfer);
        } catch (error) {
            console.error("Error creating taxi order:", error);
        } finally {
            setWaitingForResponse(false);
            onClose();
            handleClearInputs();
        }
    };


    const handleClearInputs = () => {
        setOriginAddress('');
        setDestinationAddress('');
        setStopoverAddresses([]);
        setStopoverAddressesPredicted([])
        setOriginAddressObject({ address: '', coordinates: null })
        setDestinationAddressObject({ address: '', coordinates: null })
        setQuantityAdults(1);
        setQuantityChildrenUnder140cm(0);
        setQuantityChildrenAbove140cm(0);
        setDepartureTimeDateISO(null);
        setShouldAutocomplete(true);
        setModal(null);
        _setDepartureDate(null)
        _setScheduleRide([])
        _setRepeatDuration(null)
        _setRepeatRide([])
    }

    return (
        <div style={{ padding: '20px', maxWidth: '500px', margiFn: 'auto' }}>
            {/*<div className="scrollable-container">*/}
            {/* Pickup Location Input */}
            <div>
                <h4>Pickup Location:</h4>
                <AddressInput
                    value={originAddress}
                    shouldAutocomplete={shouldAutocomplete}
                    placeholder="Enter pickup location"
                    setAddressData={setOriginAddress}
                    setAddressObject={setOriginAddressObject}
                />
            </div>

                {stopoverAddresses.map((stop, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                        <div style={{ flex: 1 }}>
                            <AddressInput
                                value={stop.address}
                                shouldAutocomplete={shouldAutocomplete}
                                placeholder="Enter stop location"
                                setAddressData={(address) => {
                                    const updatedStopovers = [...stopoverAddresses];
                                    updatedStopovers[index].address = address
                                    setStopoverAddresses(updatedStopovers);
                                }}
                                setAddressObject={(stopoverData) => {
                                    const updatedStopovers = [...stopoverAddressesPredicted];
                                    updatedStopovers[index] = stopoverData;
                                    setStopoverAddressesPredicted(updatedStopovers);
                                }}
                            />
                        </div>
                        <Button
                            text="X"
                            remove
                            action={() => handleRemoveStopover(index)}
                        />
                    </div>
                ))}


                {/* Swap and Add Stopover Button */}
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <Button
                    text={'+  Add a stop'}
                    style={{ backgroundColor: !transfer ? colors.yellow : colors.green, flex: 1, height: '34px', borderRadius: 10 }}
                    action={() => {
                        setStopoverAddresses([...stopoverAddresses, { address: '', coordinates: null }]);
                    }}
                />
                <Button
                    text={<img src={swapIcon} alt="Swap" style={{ width: '20px', height: '20px' }} />}
                    swap_locations
                    action={handleSwap}
                />
            </div>

            {/* Drop-off Location Input */}
            <div>
                <h4>Drop-off Location:</h4>
                <AddressInput
                    value={destinationAddress}
                    shouldAutocomplete={shouldAutocomplete}
                    placeholder="Enter drop-off location"
                    setAddressData={setDestinationAddress}
                    setAddressObject={setDestinationAddressObject}
                />
            </div>


            {/* Vehicle Category Selection */}
            <div style={{ marginBottom: '20px' }}>
                <h4>Vehicle Category:</h4>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 3, justifyContent: 'space-between' }}>
                    {vehicleCategories.map(category => (
                        <Button
                            key={category}
                            text={category}
                            style={{
                                backgroundColor: selectedCategory === category && !transfer ? colors.yellow : selectedCategory === category && transfer ? colors.green : 'white',
                            }}
                            vehicle_selection
                            styleText={{ color: colors.black, fontSize: 14 }}
                            action={() => handleCategoryChange(category)}
                        />
                    ))}
                </div>
            </div>

            {/* Vehicle Selection */}
            {selectedCategory && selectedCategory !== VEHICLE_CATEGORY.ANY && (
                <div style={{ marginBottom: '20px' }}>
                    <h4>Select Vehicle:</h4>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {vehicles[selectedCategory].map(vehicle => (
                            <Button
                                key={vehicle.name}
                                text={vehicle.name}
                                style={{
                                    backgroundColor: selectedVehicle === vehicle.name && !transfer ? colors.yellow : selectedVehicle === vehicle.name && transfer ? colors.green : 'white',
                                }}
                                vehicle_selection
                                styleText={{ color: colors.black, fontSize: 14 }}
                                action={() => handleVehicleChange(vehicle.name)}
                            />
                        ))}
                    </div>
                </div>
            )}

            {/*Requirements*/}
            {renderRequirementsView()}
            {/*</div>*/}

            <Button
                text={'Submit'}
                style={{ backgroundColor: transfer ? colors.green : colors.yellow, width: '100%', height:38}}
                action={() => {
                    handleSubmit()
                    console.log('SUBMIT');
                }}
                loading={waitingForResponse}
                spinnerColor={colors.white}
            />

            <CustomModal
                visible={modal === 'wrong_address'}
                title={l10nText?.addressNotRecognized}
                description={l10nText?.provideValid}
                button1Text={l10nTextGeneral?.ok}
                button1CallAction={() => setModal(null)}
                onClose={() => setModal(null)}
            />
        </div>
    );
};

export default BookingTaxi;
