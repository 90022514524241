import React from 'react';
import '../css/BasicInfo.css';
import {formatDate, formatDateTime} from "../../lib/dateUtils";

const UserBasicInfo = ({ userData }) => {
    if (!userData) {
        return <p>Loading...</p>;
    }

    console.log("User: ", userData)

    const renderAddresses = (addresses) => {
        return addresses?.map((address, index) => (
            <div key={index}>
                <p>
                    <strong>Address {index + 1}:</strong> {address.address || 'N/A'}
                </p>
                <p>
                    Latitude: <strong>{address.latitude}</strong>
                </p>
                <p>
                    Longitude: <strong>{address.longitude}</strong>
                </p>
            </div>
        ));
    };

    const renderPreferences = (preferences, label) => {
        if (!preferences || typeof preferences !== 'object') {
            return <p>No {label} preferences available.</p>;
        }

        return Object.entries(preferences).map(([preference, value], index) => (
            <div key={index}>
                <p>
                    {preference.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}:
                    {value ? <strong> Yes</strong> : ' No'}
                </p>
            </div>
        ));
    };

    return (
        <div className="driver-info-container">
            <div className="driver-info-grid">
                <div className="driver-info-column">
                    <h4>User Information</h4>

                    <div className={'custom-row'} style={{justifyContent: "start", alignItems: 'start'}}>
                        <p>Name: <strong>{userData.first_name} {userData.last_name}</strong></p>
                        {userData.profile_picture && (
                            <img
                                src={userData.profile_picture}
                                alt={`${userData.first_name} ${userData.last_name}`}
                                style={{
                                    width: '70px',
                                    height: '70px',
                                    borderRadius: '50%',
                                    marginBottom: '10px',
                                    objectFit: 'cover',
                                    marginLeft: '24px'
                                }}
                            />
                        )}
                    </div>
                    <div className="driver-status">
                        <p>Phone verified:</p>
                        <div style={{marginLeft: "10px"}}
                             className={`status-dot ${userData.phone_verified ? 'online' : 'offline'}`}/>
                        <p><strong>{userData.phone_verified ? 'Phone Verified' : 'Not Verified'}</strong></p>
                    </div>
                    <p>Phone: <strong>{userData.telephone}</strong></p>
                    <p>Email: <strong>{userData.email}</strong></p>

                    <p style={{marginTop: "24px"}}>Date of birth: <strong>{formatDate(userData.date_of_birth)}</strong>
                    </p>
                    <p>Created at: <strong>{formatDateTime(userData.created_at)}</strong></p>

                    <h4>Addresses</h4>
                    <div>{renderAddresses(userData.addresses)}</div>
                    <h4>Delivery details</h4>
                    <p>Door Number: <strong>{userData?.details?.door_number || 'N/A'}</strong></p>
                    <p>Floor Number: <strong>{userData?.details?.floor_number || 'N/A'}</strong></p>

                </div>

                <div className="driver-info-column">
                    <h4>Taxi Preferences</h4>
                    <div>{renderPreferences(userData.taxi_preferences, 'taxi')}</div>

                    <h4>Radio Preferences</h4>
                    <div>{renderPreferences(userData.radio_preferences, 'radio')}</div>

                    <h4>Transfer Preferences</h4>
                    <div>{renderPreferences(userData.transfer_preferences, 'transfer')}</div>
                </div>
            </div>
        </div>
    );
};

export default UserBasicInfo;