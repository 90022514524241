import React, { useState } from 'react';
import ImageModal from './ImageModal';
import { LOST_ITEM_STATUS } from "../../constants/enums";
import {updateLostItem} from "../../apis/lostItemApi";
import colors from "../../constants/colors";

export const LostAndFoundExtendedContent = ({ item, onStatusUpdate }) => {
    console.log("LOST AND FOUND ITEM EXTENDED VIEW: ", item);

    const [modalImage, setModalImage] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);

    const handleImageClick = (imageUrl) => {
        setModalImage(imageUrl);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalImage(null);
        setModalOpen(false);
    };

    const handleResolveItem = async () => {
        try {
            await updateLostItem(item.lost_item_id, { status: LOST_ITEM_STATUS.RESOLVED });
            if (onStatusUpdate) onStatusUpdate(item.lost_item_id);
        } catch (error) {
            console.error('Error updating lost item:', error);
            alert('Failed to update item status');
        }
    };

    const renderImages = (documents) => {
        return documents.flatMap((doc, docIndex) =>
            doc.files.map((file, fileIndex) => (
                <img
                    key={`${docIndex}-${fileIndex}`}
                    src={file.url}
                    alt={`Image ${fileIndex + 1}`}
                    onClick={() => handleImageClick(file.url)}
                    style={{ width: '120px', height: 'auto', marginRight: '8px', marginBottom: '8px', cursor: 'pointer' }}
                />
            ))
        );
    };

    return (
        <div className="lost-and-found-extended-content" style={{ display: 'flex', padding: '10px', gap: '10px', position: 'relative', width: '100%', alignItems: 'flex-start' }}>

            {/* Left Column - Images */}
            {item?.documents.length !== 0 && (
                <div>
                    <strong>Images:</strong>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {renderImages(item?.documents)}
                    </div>
                </div>
            )}

            {/* Right Column - Details */}
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {/* Description */}
                <p><strong>Description:</strong> {item.description}</p>

                {/* Reported By */}
                <div>
                    <strong>Reported By:</strong>
                    <p style={{ margin: '2px 0' }}>User ID: {item?.user?.user_id?.slice(0, 10)}...</p>
                    <p style={{ margin: '2px 0' }}>Name: {item?.user?.first_name} {item?.user?.last_name}</p>
                    <p style={{ margin: '2px 0' }}>Telephone: {item?.user?.telephone}</p>
                    <p style={{ margin: '2px 0' }}>Role: {item?.user?.user_role}</p>
                </div>

                {/* Creation and Update Time */}
                <div><strong>Reported At:</strong> {item.created_at}</div>

                {item.status === LOST_ITEM_STATUS.RESOLVED && (
                    <div><strong>Updated At:</strong> {item?.updated_at}</div>
                )}

                {/* Found Status */}
                <div>
                    <strong>Found Status:</strong> <span>{item?.status}</span>
                </div>
            </div>

            { item?.status === LOST_ITEM_STATUS.REPORTED && (
                <button
                    onClick={handleResolveItem}
                    style={{
                        position: "absolute",
                        top: '0px',
                        right: '0px',
                        padding: '5px 10px',
                        backgroundColor: colors.green,
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                >
                    Resolve Item
                </button>
            )}
            {/* Image Modal */}
            <ImageModal
                isOpen={isModalOpen}
                imageSrc={modalImage}
                onClose={handleCloseModal}
            />
        </div>
    );
};

export default LostAndFoundExtendedContent;
