import React, { useState } from 'react';
import '../css/DynamicTable.css';
import {
    renderCellContent,
    renderTaxiOrderExtendedContent,
    renderDeliveryOrderExtendedContent
} from "../../lib/tableHelpers";
import LostAndFoundExtendedContent from "./LostAndFoundExtended";
import DailyMealsExtendedContent from "./DailyMealsExtendedContent";
import {isISODate, isNumber} from "../../lib/dateUtils";

const DynamicTable = ({
                          data = [],
                          columnsConfig = {},
                          rowsPerPage = 10,
                          extendable = false,
                          viewType = 'taxi_order_view',
                          actionMap = {},
                          additionalData = {}
                      }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [expandedRows, setExpandedRows] = useState({});

    const totalPages = Math.ceil(data.length / rowsPerPage);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const sortedData = React.useMemo(() => {
        if (sortConfig.key) {
            return [...data].sort((a, b) => {
                const aValue = a[sortConfig.key];
                const bValue = b[sortConfig.key];

                // If both values are dates, parse and compare them
                if (isISODate(aValue) && isISODate(bValue)) {
                    const dateA = new Date(aValue);
                    const dateB = new Date(bValue);

                    // Compare based on sortConfig direction (asc or desc)
                    return sortConfig.direction === 'asc'
                        ? dateA - dateB
                        : dateB - dateA;
                }

                // If values are numbers, sort them as numbers
                if (isNumber(aValue) && isNumber(bValue)) {
                    return sortConfig.direction === 'asc'
                        ? aValue - bValue
                        : bValue - aValue;
                }

                // For strings, sort lexicographically
                if (aValue < bValue) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return data;
    }, [data, sortConfig]);

    const toggleRowExpansion = (rowIndex) => {
        setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [rowIndex]: !prevExpandedRows[rowIndex],
        }));
    };

    const startRow = (currentPage - 1) * rowsPerPage;
    const endRow = startRow + rowsPerPage;
    const displayedData = sortedData.slice(startRow, endRow);

    const renderHeader = () => (
        <thead>
        <tr>
            {Object.keys(columnsConfig).map((key) => (
                <th key={key} onClick={() => handleSort(key)} style={{ cursor: 'pointer' }}>
                    {sortConfig.key === key && (
                        <span className="sort-arrow">
                                {sortConfig.direction === 'asc' ? '▲' : '▼'}
                            </span>
                    )}
                    {columnsConfig[key]}
                </th>
            ))}
        </tr>
        </thead>
    );

    const renderExtendedContent = (rowIndex, rowData) => {
        if (!extendable || !expandedRows[rowIndex]) return null;

        let extendedContent;
        switch (viewType) {
            case 'lost_items_view':
                extendedContent = <LostAndFoundExtendedContent item={rowData} onStatusUpdate={actionMap?.onStatusUpdate} />;
                break;
            case 'delivery_order_view':
                extendedContent = renderDeliveryOrderExtendedContent(rowData);
                break;
            case 'daily_meals_view':
                extendedContent =
                    <DailyMealsExtendedContent
                        rowData={rowData}
                        handlePreferences={actionMap?.handleDailyMealPreferences}
                        handleDayPreferences={actionMap?.handleDailyMealDayPreferences}
                        removeItem={actionMap?.handleDailyMealsRemoveItem}
                        setAddressObject={actionMap?.setAddressObject}
                    />;
                break;
            case 'taxi_order_view':
            default:
                extendedContent = renderTaxiOrderExtendedContent(rowData, actionMap?.setModal, actionMap?.setTaxiOrder, additionalData);
                break;
        }

        return (
            <tr key={`${rowIndex}-extended`} className="extended-row">
                <td colSpan={Object.keys(columnsConfig).length}>
                    <div className="extended-content">
                        {extendedContent}
                    </div>
                </td>
            </tr>
        );
    };

    const renderBody = () => {
        if (displayedData.length === 0) {
            return (
                <tbody>
                <tr>
                    <td colSpan={Object.keys(columnsConfig).length} className="empty-data">
                        List is currently empty.
                    </td>
                </tr>
                </tbody>
            );
        }

        return (
            <tbody>
            {displayedData.map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                    <tr onClick={() => toggleRowExpansion(rowIndex)} className={extendable ? 'extendable-row' : ''}>
                        {Object.keys(columnsConfig).map((key) => (
                            <td key={key}>
                                {renderCellContent(key, row[key])}
                            </td>
                        ))}
                    </tr>
                    {renderExtendedContent(rowIndex, row)}
                </React.Fragment>
            ))}
            </tbody>
        );
    };

    const renderPagination = () => {
    if (totalPages <= 1) return null;

    const pages = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, startPage + 4);

    // Adjust startPage if there are less than 5 pages to display
    const adjustedStartPage = Math.max(1, endPage - 4);

    for (let i = adjustedStartPage; i <= endPage; i++) {
        pages.push(i);
    }

    // Always show the last page number
    if (endPage < totalPages) {
        pages.push('|');
        pages.push(totalPages);
    }

    return (
        <div className="pagination">
            <button
                className="pagination-button"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                Prev
            </button>
            {pages.map((page, index) => (
                typeof page === 'number' ? (
                    <button
                        key={index}
                        className={`pagination-number ${currentPage === page ? 'active' : ''}`}
                        onClick={() => handlePageChange(page)}
                        disabled={page === '|'}
                    >
                        {page}
                    </button>
                ) : (
                    <span key={index} className="pagination-delimiter">{page}</span>
                )
            ))}
            <button
                className="pagination-button"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                Next
            </button>
        </div>
    );
};

    return (
        <div className="table-container">
            <table className="dynamic-table">
                {renderHeader()}
                {renderBody()}
            </table>
            {renderPagination()}
        </div>
    );
};

export default DynamicTable;
