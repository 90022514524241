import React from 'react';
import '../css/BasicInfo.css';
import {getColorByValue} from "../../lib/dataParsingHelpers";
import Map from "./Map";

const DriverBasicInfo = ({ driverData }) => {
    if (!driverData) {
        return <p>Loading...</p>;
    }

    const renderWorkingHours = (workingHours) => {
        return Object.entries(workingHours).map(([day, hours], index) => (
            <div className="custom-row" key={index} style={{ justifyContent: "flex-start", height: "24px" }}>
                <p style={{ paddingRight: "12px" }}>
                    {day.charAt(0).toUpperCase() + day.slice(1)}:
                    <strong> {hours?.length > 0 ? hours?.map(range => `${range[0]} - ${range[1]}`).join(', ') : 'Not Available'}</strong>
                </p>
            </div>
        ));
    };

    const renderRideRequirements = (requirements) => {
        return Object.entries(requirements).map(([requirement, value], index) => (
            <div key={index}>
                <p>
                    {requirement.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}:
                    <strong> {value ? 'Yes' : 'No'}</strong>
                </p>
            </div>
        ));
    };

    const renderVehicles = (vehicles) => {
        return vehicles.map((vehicle, index) => {
            const colorValue = getColorByValue(vehicle.color);
            return (
                <div key={index} className="vehicle-info" style={{ fontSize: 14 }}>
                    <p><strong>Vehicle {index + 1}:</strong></p>
                    <p>Make: <strong>{vehicle.make}</strong></p>
                    <p>Model: <strong>{vehicle.model}</strong></p>
                    <p>License Plate: <strong>{vehicle.license_plate}</strong></p>
                    <p>Class: <strong>{vehicle.class}</strong></p>
                    <p>Category: <strong>{vehicle.category}</strong></p>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '-6px'}}>
                        <p style={{ marginRight: '6px' }}>Color:</p>
                        <div
                            style={{
                                width: '12px',
                                height: '12px',
                                borderRadius: '10%',
                                backgroundColor: colorValue,
                                marginRight: '6px',
                                marginBottom: '0px',
                                border: "1px solid black"
                            }}
                        />
                        <strong>{vehicle.color}</strong>
                    </div>
                </div>
            );
        });
    };


    const renderLocationSection = (driverData) => {
        return <>
            {driverData.location && driverData.location.coordinates && (
                <>
                    <h4>Current Location</h4>
                    <p>Address: <strong>{driverData.location.address || 'N/A'}</strong></p>
                    <p>Latitude: <strong>{driverData.location.coordinates.latitude}</strong></p>
                    <p>Longitude: <strong>{driverData.location.coordinates.longitude}</strong></p>
                </>
            )}
        </>
    }

    return (
        <div className="order-extended-content" style={{display: 'flex', justifyContent: 'space-between'}}>
            {/* Left Column */}
            <div style={{paddingRight: '20px'}}>

                <div className="driver-info-container">
                <div className="driver-info-grid">
                    <div className="driver-info-column">
                        <h4>Driver Information</h4>
                        <div className="driver-status">
                            <p>Status:</p>
                            <div style={{marginLeft: "10px"}}
                                 className={`status-dot ${driverData.online ? 'online' : 'offline'}`}/>
                            <p><strong>{driverData.online ? 'ONLINE' : 'OFFLINE'}</strong></p>
                        </div>
                        <p>Name: <strong>{driverData.user.first_name} {driverData.user.last_name}</strong></p>
                        <p>Email: <strong>{driverData.user.email}</strong></p>
                        <p>Phone: <strong>{driverData.user.telephone}</strong></p>
                        <p>On Order: {driverData.on_order ? <strong className="status-on-order">Yes</strong> :
                            <strong className="status-available">No</strong>}</p>


                        {renderLocationSection(driverData)}

                        <h4>Working Hours</h4>
                        <div>{renderWorkingHours(driverData.working_hours)}</div>

                    </div>

                    <div className="driver-info-column">
                        <h4>Vehicle Information</h4>
                        <div>{renderVehicles(driverData.vehicles)}</div>

                        <h4>Ride Requirements</h4>
                        <div>{renderRideRequirements(driverData.ride_requirements)}</div>
                    </div>
                </div>
                </div>
            </div>

            {/* Right Column - Map Component */}
            <div style={{flex: 1, paddingLeft: '24px', borderLeft: '1px solid #ccc'}} className="driver-info-container">
                <div style={{marginTop: '20px', height: '400px', width: '550px'}}>
                    <Map
                         drivers={[driverData]}
                         display_24h_location_history={true}
                    />
                </div>
            </div>
        </div>
    );
};

export default DriverBasicInfo;
