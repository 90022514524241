import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageWrapper from '../components/js/PageWrapper';
import Header from '../components/js/Header';
import PageTabs from '../components/js/PageTabs';
import deliveryDriverApi from '../apis/deliveryDriverApi';
import Documents from '../components/js/Documents';
import Reviews from '../components/js/Reviews';
import UserApi from '../apis/userApi';
import DeliveryDriverBasicInfo from "../components/js/DeliveryDriverBasicInfo";
import DeliveryDriverCompletedOrders from "../components/js/DeliveryDriverCompletedOrders";

const DeliveryDriver = () => {
    const { delivery_driver_id } = useParams();

    const tabs = ['BASIC_INFO', 'COMPLETED_ORDERS', 'REVIEWS', 'DOCUMENTS'];

    const [driverData, setDriverData] = useState(null);
    const [currentTab, setCurrentTab] = useState('BASIC_INFO');
    const [reviews, setReviews] = useState([]);
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        const fetchDriverData = async () => {
            try {
                const response = await deliveryDriverApi.getDeliveryDriverById(delivery_driver_id);
                let documentsData = [];
                if (response && !response.error) {
                    setDriverData(response);
                    console.log("deliveryDriverData", response);

                    documentsData = [...documentsData, ...response.documents, ...response.user.documents];
                    if (response.vehicles.length > 0) {
                        documentsData = [...documentsData, ...response.vehicles[0].documents];
                    }
                    if (response.user.business) {
                        documentsData = [...documentsData, ...response.user.business.documents];
                    }

                    setDocuments(documentsData);
                    const resp = await UserApi.getReviewsByUserId(response.user.user_id);
                    console.log("user_id", response.user.user_id);
                    console.log(resp, 'reviews');
                    setReviews(resp);
                }
            } catch (error) {
                console.error('Error fetching delivery driver data:', error);
            }
        };

        fetchDriverData();
    }, [delivery_driver_id]);

    const handleTabChange = (selectedTab) => {
        setCurrentTab(selectedTab);
    };

    return (
        <PageWrapper>
            <div className="body">
                <Header title={`Delivery Driver: ${driverData?.user?.first_name} ${driverData?.user?.last_name}`} />
                <PageTabs
                    tabs={tabs}
                    initialTab={currentTab}
                    onTabChange={handleTabChange}
                    pageContext="DRIVER"
                />

                <div>
                    {currentTab === 'BASIC_INFO' && <DeliveryDriverBasicInfo driverData={driverData} />}
                    {currentTab === 'COMPLETED_ORDERS' && <DeliveryDriverCompletedOrders deliveryDriverId={delivery_driver_id} />}
                    {currentTab === 'REVIEWS'  && <Reviews reviews={reviews} /> }
                    {currentTab === 'DOCUMENTS' && <Documents documents={documents} /> }
                </div>
            </div>
        </PageWrapper>
    );
};

export default DeliveryDriver;