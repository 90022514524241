import React from "react";
import { TailSpin } from 'react-loader-spinner';
import '../css/Button.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

function Button(props) {

    let className = '';

    if (!props.cancel) {
        className = ' button-container button-default button-primary';
    }
    if (props.secondary) {
        className += ' button-secondary';
    }
    if (props.add) {
        className += ' button-add';
    }
    if (props.delete) {
        className += ' button-delete';
    }
    if (props.login) {
        className += ' button-login';
    }
    if (props.confirm) {
        className += ' button-confirm';
    }
    if (props.reject) {
        className += ' button-reject';
    }
    if (props.upload) {
        className += ' button-upload';
    }
    if (props.selection) {
        className += ' button-selection';
    }
    if (props.selected) {
        className += ' button-selected';
    }
    if (props.disabled) {
        className += ' button-disabled';
    }
    if (props.info) {
        className += ' button-info';
    }
    if (props.cancel && !props.loading) {
        className += ' cancel-button'
    }
    if (props?.remove) {
        className += ' remove-button'
    }
    if (props?.swap_locations) {
        className += ' swap-button'
    }
    if (props?.vehicle_selection) {
        className += ' vehicle_selection'
    }
    if (props?.create_order) {
        className += ' create_order'
    }
    if (props?.toggle_layers) {
        className += ' button-toggle_layers'
    }

    return (
        <div
            className={props.className + ' ' + className}
            style={props.style}
            onClick={(e) => {
                if (!props.disabled && !props.loading) {
                    props.action(e);
                }
            }}
        >
            <div className={'button'}>
                {props.loading ? (
                    <TailSpin
                        type="TailSpin"
                        strokeWidth={5}
                        color={props.spinnerColor || '#FFFFFF'}
                        height={props?.cancel ? 22 : 30}
                        width={props?.cancel ? 22 : 30}
                        style={{ paddingTop: 8, paddingBottom: 8 }}
                    />
                ) :
                    props?.cancel ?  <FontAwesomeIcon style={{width: 16, height: 16}} icon={faTrash}/> :
                    <p style={props.styleText}>{props.text}</p>
                }
            </div>
        </div>
    );
}

export default Button;
