import React, {useEffect, useState} from 'react';
import {getLocalisedTexts} from "../../../l10n/localisations/languages";
import DynamicTable from "../DynamicTable";
import {renderDriversEarningsListDataSquares} from "../DataSquare";
import driverApi from "../../../apis/driverApi";
import {DELIVERY_ORDER_STATUS} from "../../../constants/enums";
import deliveryApi from "../../../apis/deliveryApi";
import deliveryDriverApi from "../../../apis/deliveryDriverApi";

const DeliveryDriversEarningsList = () => {
    const l10nText = getLocalisedTexts('DRIVER_EARNINGS');
    const l10nDelivery = getLocalisedTexts().PAGE_TABS['DASHBOARD'] || {};

    const [todaysEarnings, setTodaysEarnings] = useState(0);
    const [weeklyEarnings, setWeeklyEarnings] = useState(0);
    const [monthlyEarnings, setMonthlyEarnings] = useState(0);
    const [totalEarnings, setTotalEarnings] = useState(0);
    const [drivers, setDrivers] = useState([])
    const [deliveryOrders, setDeliveryOrders] = useState([])
    const [driversEarnings, setDriversEarnings] = useState([])

    const columnsConfig = {
        is_online: l10nText.activity || 'Activity',
        license_number: l10nText.license_number || 'License number',
        driver: l10nText.delivery_driver || 'Delivery Driver',
        number_of_rides: l10nText.number_of_rides || 'Number of Rides',
        distance_time_travelled: l10nText.distance_time_travelled || 'Distance and Time Travelled',
        hourly_earnings: l10nText.hourly_earnings || 'Hourly Earnings',
        total_earnings: l10nText.total_earnings || 'Total Earnings',
        earnings_fee: l10nText.earnings_fee || 'Earnings Fee',
    };

    useEffect(() => {
        const fetchDeliveryOrders = async () => {
            try {
                const orders = await deliveryApi.getDeliveryOrders();
                if (orders && !orders.error) {
                    setDeliveryOrders(orders)
                    const completedOrders = orders.filter(order => order.status === DELIVERY_ORDER_STATUS.COMPLETED)

                    let todaysEarnings = 0;
                    let weeklyEarnings = 0;
                    let monthlyEarnings = 0;
                    let totalEarnings = 0;

                    const now = new Date();
                    const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
                    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);

                    completedOrders.forEach(order => {
                        const timeline = order.timeline.find(item => item.status === DELIVERY_ORDER_STATUS.COMPLETED);
                        const timestamp = timeline?.location?.timestamp;

                        if (timestamp) {
                            const orderDate = new Date(timestamp);
                            const daysDiff = Math.floor((now - orderDate) / (1000 * 60 * 60 * 24));
                            const weeksDiff = Math.floor(daysDiff / 7);
                            const monthsDiff = Math.floor(daysDiff / 30);

                            const deliveryCost = parseFloat(order.details?.delivery_cost || 0);
                            const deliveryEarnings = parseFloat(order.details?.delivery_earnings || 0);

                            const orderAmount = (deliveryCost + deliveryEarnings) || 0;

                            totalEarnings += orderAmount;

                            if (orderDate >= startOfDay && orderDate <= endOfDay) {
                                todaysEarnings += orderAmount;
                            }

                            if (weeksDiff === 0) {
                                weeklyEarnings += orderAmount;
                            }

                            if (monthsDiff === 0) {
                                monthlyEarnings += orderAmount;
                            }
                        }
                    });

                    setTodaysEarnings(todaysEarnings);
                    setWeeklyEarnings(weeklyEarnings);
                    setMonthlyEarnings(monthlyEarnings);
                    setTotalEarnings(totalEarnings);
                }
            } catch (error) {
                console.error('Error fetching delivery orders:', error);
            }
        };

        const fetchRegisteredDrivers = async () => {
            try {
                const drivers = await deliveryDriverApi.listDeliveryDrivers();
                if (drivers && !drivers.error) {
                    setDrivers(drivers);
                }
            } catch (e) {
                console.error(e);
            }
        };

        fetchDeliveryOrders()
        fetchRegisteredDrivers()
        const intervalId = setInterval(() => {
            fetchDeliveryOrders()
            fetchRegisteredDrivers()
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const calculateEarnings = async () => {
            const earnings = await Promise.all(drivers?.map(async (driver) => {
                try {
                    const driverOrders = await deliveryApi.getCompletedDeliveryOrdersByDeliveryDriverId(driver.delivery_driver_id);

                    if (driverOrders && driverOrders.length) {
                        const totalEarnings = driverOrders.reduce((sum, order) => {
                            const deliveryCost = parseFloat(order.details?.delivery_cost || 0);
                            const deliveryEarnings = parseFloat(order.details?.delivery_earnings || 0);

                            return sum + ((deliveryCost + deliveryEarnings) || 0)
                        }, 0);

                        const numberOfRides = driverOrders.length;

                        // Calculate the total duration in minutes from "DELIVERY_ACCEPTED" to "DELIVERY_COMPLETED"
                        const totalDurationInMinutes = driverOrders.reduce((sum, order) => {
                            const timeline = order.timeline; // Assuming the timeline is part of the order object
                            const acceptedTimestamp = timeline.find(entry => entry.status === DELIVERY_ORDER_STATUS.ACCEPTED)?.location?.timestamp;
                            const completedTimestamp = timeline.find(entry => entry.status === DELIVERY_ORDER_STATUS.COMPLETED)?.location?.timestamp;

                            if (acceptedTimestamp && completedTimestamp) {
                                const start = new Date(acceptedTimestamp);
                                const end = new Date(completedTimestamp);
                                const durationInMinutes = (end - start) / (1000 * 60);
                                return sum + durationInMinutes;
                            }

                            return sum;
                        }, 0);

                        // Convert total duration from minutes to hours
                        const totalDurationInHours = totalDurationInMinutes / 60;

                        const hourlyEarnings = totalDurationInHours > 0 ? (totalEarnings / totalDurationInHours).toFixed(2) : '0.00';

                        const distance = driverOrders.reduce((totalDistance, order) => {
                            const distanceString = order?.details?.distance || '0 km';
                            const numericDistance = parseFloat(distanceString);

                            return totalDistance + numericDistance;
                        }, 0);


                        // Calculate hours and minutes from total duration in minutes
                        const hours = Math.floor(totalDurationInMinutes / 60);
                        const minutes = Math.floor(totalDurationInMinutes % 60);

                        const distanceTimeTravelled = formatDistanceTimeTravelled(distance, hours, minutes);

                        return {
                            is_online: driver?.online,
                            license_number: driver?.vehicles[0]?.license_plate,
                            driver: `${driver?.user?.first_name} ${driver?.user?.last_name}`,
                            total_earnings: `€${totalEarnings.toFixed(2)}`,
                            number_of_rides: numberOfRides,
                            hourly_earnings: `${hourlyEarnings} €/h`,
                            distance_time_travelled: `${distanceTimeTravelled}`,
                            earnings_fee: `€${(totalEarnings * 0.10).toFixed(2)}`,
                        };
                    } else {
                        return {
                            is_online: driver?.online,
                            license_number: driver?.vehicles[0]?.license_plate,
                            driver: `${driver?.user?.first_name} ${driver?.user?.last_name}`,
                            total_earnings: '€0.00',
                            number_of_rides: 0,
                            hourly_earnings: '0.00 €/h',
                            distance_time_travelled: '0km',
                            earnings_fee: '€0.00',
                        };
                    }
                } catch (error) {
                    console.error('Error calculating earnings for driver:', driver.delivery_driver_id, error);
                    return {};
                }
            }));

            setDriversEarnings(earnings);
        };

        if (drivers.length > 0 && deliveryOrders.length > 0) {
            calculateEarnings();
        }
    }, [drivers, deliveryOrders]);


    const formatDistanceTimeTravelled = (distance, hours, minutes) => {
        let distanceStr = distance > 0 ? `${distance.toFixed(2)}km` : '';
        let timeStr = '';

        if (hours > 0) {
            timeStr += `${hours}h`;
        }
        if (minutes > 0) {
            timeStr += `${minutes}min`;
        }

        return distanceStr && timeStr ? `${distanceStr}/${timeStr}` : distanceStr || timeStr;
    };

    return (
        <div>
            <div className="list-header">
                <h3>{l10nDelivery.DELIVERY_DRIVERS_EARNINGS || 'Delivery Drivers Earnings'}</h3>
                {renderDriversEarningsListDataSquares(l10nText, {}, todaysEarnings, weeklyEarnings, monthlyEarnings, totalEarnings)}
            </div>
            <DynamicTable
                data={driversEarnings}
                columnsConfig={columnsConfig}
                rowsPerPage={5}
            />
        </div>
    );
};

export default DeliveryDriversEarningsList;
