import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {getLocalisedTexts} from "../../l10n/localisations/languages";
import businessApi from "../../apis/businessApi";
import DynamicTable from "./DynamicTable";
import {renderBusinessesDataSquares} from "./DataSquare";
import Colors from "../../constants/colors"; // Import the new render function

const MerchantBusinessesList = () => {
    const l10nText = getLocalisedTexts('BUSINESS_LIST');
    const [businessesList, setBusinessesList] = useState([]);
    const [registeredBusinessUsers, setRegisteredBusinessUsers] = useState(0);
    const [popularRestaurants, setPopularRestaurants] = useState(0);
    const [newRestaurants, setNewRestaurants] = useState(0);
    const [businessesOfferingDailyMeals, setBusinessesOfferingDailyMeals] = useState(0);
    const [businessesWithSeatingReservation, setBusinessesWithSeatingReservation] = useState(0);

    const fetchBusinesses = async () => {
        try {
            const businesses = await businessApi.loadMerchantBusinesses();
            if (businesses && !businesses.error) {
                console.log(businesses);
                setBusinessesList(businesses);
                setRegisteredBusinessUsers(businesses.reduce((total, business) => total + (business.business_users ? business.business_users.length : 0), 0)); // Count total business users
                setPopularRestaurants(businesses.filter(business => business.popular).length); // Count popular restaurants
                setNewRestaurants(businesses.filter(business => business.new).length); // Count new restaurants
                setBusinessesOfferingDailyMeals(businesses.filter(business => business.offers_daily_meals).length); // Count businesses offering daily meals
                setBusinessesWithSeatingReservation(businesses.filter(business => business.seats > 0).length); // Count businesses with seating reservation
            } else {
                setBusinessesList([]);
                setRegisteredBusinessUsers(0);
                setPopularRestaurants(0);
                setNewRestaurants(0);
                setBusinessesOfferingDailyMeals(0);
                setBusinessesWithSeatingReservation(0);
            }
        } catch (e) {
            console.error(e);
            setBusinessesList([]);
            setRegisteredBusinessUsers(0);
            setPopularRestaurants(0);
            setNewRestaurants(0);
            setBusinessesOfferingDailyMeals(0);
            setBusinessesWithSeatingReservation(0);
        }
    };

    useEffect(() => {
        fetchBusinesses();

        const intervalId = setInterval(() => {
            fetchBusinesses();
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);

    const columnsConfig = {
        name: l10nText?.name || 'Business Name',
        operating_address: l10nText?.address || 'Address',
        owner: l10nText?.owner || 'Owner/Director',
        daily_meals: l10nText?.offers_daily_meals || 'Offers daily meals',
        is_new: l10nText?.new || 'New',
        is_popular: l10nText?.popular || 'Popular',
        view: 'View',
    };

    const parseBusinessData = businessesList.map(business => {
        // Find the owner or director
        const owner = business?.business_users.find(user => user?.company_role === "DIRECTOR" || user?.company_role === "OWNER");
        const ownerName = owner ? `${owner?.users?.first_name} ${owner?.users?.last_name}` : 'N/A'; // Default to 'N/A' if no owner/director found

        return {
            name: business.name,
            operating_address: business?.address?.address || 'Address not available',
            owner: ownerName,
            daily_meals: (
                <div
                    style={{
                        opacity: business?.offers_daily_meals ? 1 : 0,
                        backgroundColor: business?.offers_daily_meals ? Colors.red : '#dc3545',
                        borderRadius: '8px',
                        textAlign: 'center',
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: "12px",
                        maxWidth: "90px"
                    }}
                >
                    {business?.offers_daily_meals ? 'Yes' : ''}
                </div>
            ),
            is_new: (
                <div
                    style={{
                        opacity: business?.new ? 1 : 0,
                        backgroundColor: business?.new ? '#28a745' : '#dc3545',
                        borderRadius: '8px',
                        textAlign: 'center',
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: "12px",
                        maxWidth: "90px"
                    }}
                >
                    {business?.new ? 'New' : ''}
                </div>
            ),
            is_popular: (
                <div
                    style={{
                        opacity: business?.popular ? 1 : 0,
                        backgroundColor: business?.popular ? '#5f289f' : '#dc3545',
                        borderRadius: '8px',
                        textAlign: 'center',
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: "12px",
                        maxWidth: "90px"
                    }}
                >
                    {business?.popular ? 'Popular' : ''}
                </div>
            ),
            view: <Link to={`/business/${business?.business_id}`}>View</Link>,
            ...business
        };
    });

    return (
        <div>
            <div className="list-header">
                <h3>{'Merchants List'}</h3>
                {renderBusinessesDataSquares(
                    l10nText,
                    registeredBusinessUsers,
                    popularRestaurants,
                    newRestaurants,
                    businessesOfferingDailyMeals,
                    businessesWithSeatingReservation
                )}
            </div>
            <DynamicTable
                data={parseBusinessData}
                columnsConfig={columnsConfig}
                rowsPerPage={12}
                extendable={false}
            />
        </div>
    );
};

export default MerchantBusinessesList;