import React, {useState, useEffect} from 'react';
import {getLocalisedTexts} from "../../../l10n/localisations/languages";
import DynamicTable from "../DynamicTable";
import {renderUsersListDataSquares} from "../DataSquare";
import {Link} from "react-router-dom";
import {getPersonalUsers} from "../../../apis/userApi";
import colors from "../../../constants/colors";
import {PAGES} from "../../../constants/enums";
import {formatDateTime} from "../../../lib/dateUtils";

const UsersList = () => {
    const l10nText = getLocalisedTexts('USERS_LIST');
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);

    const fetchUsers = async () => {
        try {
            const allUsers = await getPersonalUsers();
            if (allUsers && !allUsers.error) {
                setUsers(allUsers);
                setFilteredUsers(allUsers);
            } else {
                setUsers([]);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
            setUsers([]);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const totalUsers = users.length; // Total number of users
    const usersJoinedToday = users.filter(user => {
        const today = new Date();
        const registrationDate = new Date(user.created_at); // Use created_at for registration date
        return registrationDate.getDate() === today.getDate() &&
            registrationDate.getMonth() === today.getMonth() &&
            registrationDate.getFullYear() === today.getFullYear();
    }).length;

    const columnsConfig = {
        tags: '', // Placeholder for tags column without a header
        name: l10nText.name || 'Name',
        email: l10nText.email || 'Email',
        phone: l10nText.phone || 'Phone',
        phone_verified: l10nText.phone_verified || 'Phone Verified', // New column for phone verification
        created_at: l10nText.created_at || 'Created at',
        preview: l10nText.preview || 'Preview',
    };

    const usersJoinedLast7Days = users.filter(user => {
        const registrationDate = new Date(user.created_at);
        const today = new Date();
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 7);
        return registrationDate >= sevenDaysAgo;
    }).length;

    const usersJoinedLastWeek = users.filter(user => {
        const registrationDate = new Date(user.created_at);
        const today = new Date();
        const lastWeekStart = new Date(today);
        lastWeekStart.setDate(today.getDate() - 7);
        const lastWeekEnd = new Date(today);
        lastWeekEnd.setDate(today.getDate() - 1);
        return registrationDate >= lastWeekStart && registrationDate <= lastWeekEnd;
    }).length;

    const usersJoinedLastMonth = users.filter(user => {
        const registrationDate = new Date(user.created_at);
        const today = new Date();
        const lastMonthStart = new Date(today);
        lastMonthStart.setMonth(today.getMonth() - 1);
        lastMonthStart.setDate(1);
        const lastMonthEnd = new Date(today);
        lastMonthEnd.setMonth(today.getMonth());
        lastMonthEnd.setDate(0); // Last day of the previous month
        return registrationDate >= lastMonthStart && registrationDate <= lastMonthEnd;
    }).length;

    const usersJoinedThisYear = users.filter(user => {
        const registrationDate = new Date(user.created_at);
        const today = new Date();
        return registrationDate.getFullYear() === today.getFullYear();
    }).length;

    const parseUserData = filteredUsers.map(user => {
        const tags = [];
        if (user.subscribed_to_daily_meals) {
            tags.push(
                <span
                    key="daily-meals-tag"
                    style={{
                        backgroundColor: colors.red,
                        borderRadius: '8px',
                        color: 'white',
                        padding: '2px 4px',
                        fontSize: '10px',
                        fontWeight: 'bold',
                        marginRight: '4px',
                        display: 'inline-block',
                    }}
                >
                    Daily meals
                </span>
            );
        }

        return {
            tags: <div>{tags}</div>, // Display tags in the first column
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
            phone: (
                <a
                    href={`tel:${user.telephone}`}
                    style={{
                        textDecoration: 'underline',
                    }}
                >
                    {user.telephone}
                </a>
            ),
            phone_verified: user.phone_verified ? <strong>Yes</strong> : 'No',
            created_at: formatDateTime(user.created_at),
            preview: <Link to={`${PAGES.USER}/${user.user_id}`}>View</Link>,
        };
    });

    return (
        <div>
            <div className="list-header">
                <h3>{l10nText.title || 'Users List'}</h3>
                {renderUsersListDataSquares(l10nText, totalUsers, usersJoinedToday, usersJoinedLast7Days, usersJoinedLastWeek, usersJoinedLastMonth, usersJoinedThisYear)}
            </div>
            <DynamicTable
                data={parseUserData}
                columnsConfig={columnsConfig}
                rowsPerPage={12}
            />
        </div>
    );
};

export default UsersList;