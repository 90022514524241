import React, { useEffect, useState } from 'react';
import '../css/RadioButtons.css';
import '../css/Input.css';

const RadioButtons = ({
                          title,
                          items,
                          icons,
                          green,
                          yellow,
                          red,
                          singleToggle,
                          row,
                          onSelectionChange,
                          value,
                          disabled,
                          style
                      }) => {
    const [checkedItems, setCheckedItems] = useState(value ? value : []);

    useEffect(() => {
        if (onSelectionChange) {
            onSelectionChange(checkedItems);
        }
    }, []);

    const getColor = () => {
        if (green) return 'green';
        if (yellow) return 'yellow';
        if (red) return 'red';
        return 'black';
    };

    const handleToggle = (item) => {
        if (singleToggle) {
            setCheckedItems(checkedItems.some(checkedItem => checkedItem.value === item.value) ? [] : [item]);
            onSelectionChange(checkedItems.some(checkedItem => checkedItem.value === item.value) ? [] : [item])
        } else {
            const isChecked = checkedItems.some(checkedItem => checkedItem.value === item.value);
            if (isChecked) {
                setCheckedItems(checkedItems.filter(checkedItem => checkedItem.value !== item.value));
                onSelectionChange(checkedItems.filter(checkedItem => checkedItem.value !== item.value))
            } else {
                setCheckedItems([...checkedItems, item]);
                onSelectionChange([...checkedItems, item])
            }
        }
    };

    return (
        <div style={style} className={`radio-buttons-container ${row ? 'row' : ''}`}>
            {title && <p className={'p-label'}>{title}</p>}
            <div className={`radio-buttons ${row ? 'row' : ''}`}>
                {items.map((item, index) => (
                    <label key={index} className="radio-button-item">
                        <input
                            type="checkbox" // Changed from radio to checkbox
                            value={item.value}
                            checked={checkedItems.some(checkedItem => checkedItem.value === item.value)}
                            onChange={() => handleToggle(item)}
                            style={{ accentColor: getColor() }}
                            disabled={disabled}
                        />
                        {icons && icons[index] && <img src={icons[index]} alt="" className="radio-button-icon" />}
                        <span className="radio-button-text">{item.name}</span>
                    </label>
                ))}
            </div>
        </div>
    );
};

export default RadioButtons;
