import {FILE_TYPE} from "../constants/enums";

export const filterTrueValues = (obj) => {
    if (!obj) return [];

    return Object.entries(obj)
        .filter(([key, value]) => value === true)
        .reduce((acc, [key]) => {
            acc[key] = true;
            return acc;
        }, {});
};

export const stringExists = (value) => value && value !== "";

export const isValidPhoneNumber = (phoneNumber) => {
    const regex = /^\+?[0-9]\d{0,14}$/; // Allows optional '+' and up to 15 digits, including leading zero
    return regex.test(phoneNumber);
};

export const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0;
};

export const filterLanguage = (languageItems, inputString) => {
    if (languageItems && inputString) {
        return languageItems.filter(item => item.value.toLowerCase() === inputString.toLowerCase());
    } else return []
}

export const convertImages = (images) => {
    return images.map(image => {
        return {
            url: image.url,
            file_type: FILE_TYPE.IMAGE,
            mime_type: 'image/jpeg',
            base64: image.base64
        };
    });
};

export const convertTaxiRequirementsToObject = (requirementsList) => {
    const values = requirementsList.map(item => item.value);
    const rideRequirements = {
        traveling_with_pet: false,
        air_conditioning: false,
        child_seats: false,
        quiet_ride: false,
        luggage: false,
        wheelchair_accessibility: false,
    };

    values.forEach(requirement => {
        const formattedRequirement = requirement.toLowerCase().replace(/ /g, '_');
        if (rideRequirements.hasOwnProperty(formattedRequirement)) {
            rideRequirements[formattedRequirement] = true;
        }
    });
    console.log(rideRequirements)
    return rideRequirements;
}

export const convertTransferRequirementsToObject = (requirementsList) => {
    const values = requirementsList.map(item => item.value);
    const rideRequirements = {
        traveling_with_pet: false,
        wi_fi: false,
        outlets: false,
        air_conditioning: false,
        onboard_restrooms: false,
        luggage: false,
        multilingual_staff: false,
    };

    values.forEach(requirement => {
        const formattedRequirement = requirement.toLowerCase().replace(/ /g, '_');
        if (rideRequirements.hasOwnProperty(formattedRequirement)) {
            rideRequirements[formattedRequirement] = true;
        }
    });

    return rideRequirements;
}



export const convertRadioPreferencesToObject = (preferencesList) => {
    const values = preferencesList.map(item => item.value);
    const radioPreferences = {
        drivers_choice: false,
        radio_bob: false,
        rtvslo_radio: false,
        radio_student: false,
        radio_hit: false,
        radio_1: false,
        radio_gorenc: false,
        hit: false,
        rock_radio: false,
        radio_kranj: false,
        aktual: false,
    };

    values.forEach(preference => {
        const formattedPreference = preference.toLowerCase().replace(/ /g, '_');
        if (radioPreferences.hasOwnProperty(formattedPreference)) {
            radioPreferences[formattedPreference] = true;
        }
    });

    return radioPreferences;
}

export const convertAllergyPreferencesToObject = (preferencesList) => {
    const values = preferencesList.map(item => item.value);
    const dietaryPreferences = {
        vegetarian: false,
        vegan: false,
        gluten_free: false,
        nut_free: false,
        diary_free: false,
        organic: false,
    };

    values.forEach(preference => {
        // Ensure the preference key matches the format used in dietaryPreferences keys
        const formattedPreference = preference.toLowerCase().replace(/-/g, '_');
        if (dietaryPreferences.hasOwnProperty(formattedPreference)) {
            dietaryPreferences[formattedPreference] = true;
        }
    });

    return dietaryPreferences;
}

export const convertSpicyPreferencesToObject = (preferencesList) => {
    const values = preferencesList.map(item => item.value);
    const spicyPreferences = {
        not_spicy: false,
        mild: false,
        hot: false,
        very_hot: false,
    };

    values.forEach(preference => {
        const formattedPreference = preference.toLowerCase();
        if (spicyPreferences.hasOwnProperty(formattedPreference)) {
            spicyPreferences[formattedPreference] = true;
        }
    });

    return spicyPreferences;
}

export const convertTransferNotificationPreferencesToObject = (preferencesList) => {
    const values = preferencesList.map(item => item.value);
    const notificationPreferences = {
        departure_reminders: false,
        real_time_updates: false,
        booking_confirmation: false,
        special_offers_and_promotions: false,
        ratings_and_feedback: false,
        news: false,
    };

    values.forEach(preference => {
        // Normalize the preference to match the keys in the notificationPreferences object
        const formattedPreference = preference.toLowerCase().replace(/ /g, '_');
        if (notificationPreferences.hasOwnProperty(formattedPreference)) {
            notificationPreferences[formattedPreference] = true;
        }
    });

    return notificationPreferences;
}

export const convertDeliveryNotificationPreferencesToObject = (preferencesList) => {
    const values = preferencesList.map(item => item.value);
    const notificationPreferences = {
        order_confirmation: false,
        order_updates: false,
        delivery_arrival: false,
        order_tracking: false,
        promotion_discounts: false,
        payment_confirmation: false,
        ratings_feedback: false,
        news_updates: false,
        account_updates: false,
    };

    values.forEach(preference => {
        // Normalize the preference to match the keys in the notificationPreferences object
        const formattedPreference = preference.toLowerCase().replace(/ /g, '_');
        if (notificationPreferences.hasOwnProperty(formattedPreference)) {
            notificationPreferences[formattedPreference] = true;
        }
    });

    return notificationPreferences;
}

export const convertTaxiPushNotificationPreferencesToObject = (preferencesList) => {
    const values = preferencesList.map(item => item.value);
    const notificationPreferences = {
        ride_requests: false,
        ride_acceptance: false,
        driver_arrival: false,
        ride_completion: false,
        promotions_and_discounts: false,
        ratings_and_feedback: false,
        news_and_updates: false,
    };

    values.forEach(preference => {
        const formattedPreference = preference.toLowerCase().replace(/ /g, '_').replace(/[^a-zA-Z0-9_]/g, '');
        if (notificationPreferences.hasOwnProperty(formattedPreference)) {
            notificationPreferences[formattedPreference] = true;
        }
    });

    return notificationPreferences;
}

export const convertNotificationPreferencesToObject = (preferencesList) => {
    const values = preferencesList.map(item => item.value);
    const notificationPreferences = {
        telephone: false,
        whatsapp: false,
        viber: false,
        telegram: false,
        wechat: false,
    };

    values.forEach(preference => {
        const formattedPreference = preference.toLowerCase().replace(/ /g, '_');
        if (notificationPreferences.hasOwnProperty(formattedPreference)) {
            notificationPreferences[formattedPreference] = true;
        }
    });

    return notificationPreferences;
}

export const filterCheckedItems = (items, checkedItems) => {
    if (items && checkedItems) {
        // Items: list of objects, Checked items: object
        return items?.filter(item => checkedItems[item.value]);
    } else return []
}

export const valueExists = (value) => value && value !== "";

export const getOpacity = (state) => {
    return state ? 0.55 : 1;
};

export const validateEmail = (email) => {
    let validFormat = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/;
    return validFormat.test(email);
};

export const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[0-9]{8,9}$/; // Allowing phone numbers with 8 or 9 digits

    return phoneRegex.test(phoneNumber);
};

export const validateUserInput = (params) => {
    for (let i = 0; i < params.length; i++) {
        console.log(params)
        if (params[i] === null || params[i] === '') {
            return false;
        }
    }
    return true;
};

export const formatFreeSlotsLabel = (freeSlots) => {
    switch (Number(freeSlots)) {
        case 1:
            return freeSlots + ' prosto mesto';
        case 2:
            return freeSlots + ' prosti mesti';
        case 3:
        case 4:
            return freeSlots + ' prosta mesta';
        default:
            return freeSlots + ' prostih mest';
    }
}

export const parseMimeTypeFromUrl = (url) => {
    if (!url) {
        return
    }
    try {
        if (url.contains('.jpg') || url.contains('.jpeg')) {
            return '.jpg';
        } else if (url.contains(".png")) {
            return '.png';
        }
    } catch {
        return null;
    }
}

export const parseCaretChars = (text) => {
    console.log(text)
    return text.replace(/č/g, "&#269;").replace(/š/g, "&#353;").replace(/ž/g, "&#382;").replace(/"/g, "&quot;").replace(/\r?\n|\r/g, '<br>');
};

export const findLastIndex = (array, predicate) => {
    let l = array.length;
    while (l--) {
        if (predicate(array[l], l, array))
            return l;
    }
    return -1;
}

export const capitalize = (str) => {
    try {
        if (typeof str !== 'string') throw new TypeError('Input must be a string');
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    } catch (error) {
        console.error('Error in capitalize function:', error);
        return str;
    }
};


/**
 * Filters a list of options based on a preferences object.
 *
 * @param {Array} options - The list of options with name-value pairs.
 * @param {Object} preferencesObject - An object where keys are option values and values are booleans.
 * @returns {Array} - A list of filtered options based on the preferences object.
 */
export const convertObjectToRadioButtonsList = (options, preferencesObject) => {
    console.log(options, preferencesObject);
    const filteredPreferences = options.filter(option => preferencesObject[option.value.toLowerCase()] === true);
    return filteredPreferences.map(option => ({
        name: option.name,
        value: option.value
    }));
};


export const convertLanguagesToObject = (spokenLanguagesList) => {
    const languageObject = {
        de: false,
        en: false,
        es: false,
        fr: false,
        it: false,
        ru: false,
        si: false,
        srb: false
    };

    spokenLanguagesList.forEach(language => {
        const languageCode = language.value.toLowerCase();
        if (languageObject.hasOwnProperty(languageCode)) {
            languageObject[languageCode] = true;
        }
    });

    return languageObject;
}

export const convertFiles = (files) => {
    return files.map(file => {
        let fileType;
        const fileTypeMap = {
            'image/jpeg': FILE_TYPE.IMAGE,
        };
        if (file.mimeType in fileTypeMap) {
            fileType = fileTypeMap[file.mimeType];
        } else {
            fileType = FILE_TYPE.DOCUMENT;
        }
        return {
            url: file.uri,
            file_type: fileType,
            mime_type: file.mimeType,
            base64: file.base64
        };
    });
};
