import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from "react";
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import ImageModal from './ImageModal';
import colors from "../../constants/colors";
import './../css/Input.css'

const UploadFile = (props) => {
    const hiddenFileInput = React.useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");
    const [uploadedFileName, setUploadedFileName] = useState("");

    useEffect(() => {
        if (props?.value) {
            setModalImage(props?.value?.url);
            setUploadedFileName(props?.value?.name || "Image");
        }
    }, [props?.value?.url, props?.value?.name]);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                const fileUrl = URL.createObjectURL(fileUploaded); // Generate the file URL/URI

                setModalImage(base64String);
                setUploadedFileName(fileUploaded.name);

                // Send both base64 and URL/URI in an object back via props
                props?.handleFile && props?.handleFile({
                    base64: base64String,
                    url: fileUrl,
                    name: fileUploaded.name
                });
            };
            reader.readAsDataURL(fileUploaded);
        }

        setTimeout(() => {
            hiddenFileInput.current.value = "";
        }, 250);
    };

    return (
        <>
            {props?.title && <p className={'p-label'}>{props?.title}</p>}
            <div
                className="upload-area"
                style={{
                    ...props?.style,
                    ...(props?.border ? {border: '1px solid #ccc', padding: '10px', cursor: 'pointer'} : {})
                }}
                onClick={handleClick}
            >
                <FontAwesomeIcon icon={faUpload} className="icon icon-upload" style={{margin: '0px', opacity: 0.75}}/>
            </div>
            <input type="file"
                   ref={hiddenFileInput}
                   onChange={handleChange}
                   style={{...props?.style, display: 'none'}}
            />
            {uploadedFileName && (
                <p
                    style={{marginTop: '10px', cursor: 'pointer', color: colors.text, textDecoration: 'underline'}}
                    onClick={() => setIsModalOpen(true)}
                >
                    Uploaded File: {uploadedFileName}
                </p>
            )}

            <ImageModal
                isOpen={isModalOpen}
                imageSrc={modalImage}
                onClose={handleCloseModal}
            />
        </>
    );
};

export default UploadFile;
