import React, { useEffect, useRef } from 'react';
import '../css/CustomModal.css';

const CustomModal = ({
                         visible,
                         onClose,
                         title,
                         description,
                         children,
                         button1Text,
                         button1CallAction,
                         button2Text,
                         button2CallAction,
                         green,
                         red,
                         closeButton = false,
                     }) => {
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target) && !event.target.closest('.modal-close')) {
                onClose();
            }
        };

        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                // Prevent any action on Enter key press
                event.preventDefault();
            }
        };

        if (visible && !closeButton) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [visible, onClose, closeButton]);

    if (!visible) return null;

    const hasOneButton = button1Text && !button2Text;

    return (
        <div className="custom-modal-overlay">
            <div className="custom-modal" ref={modalRef}>
                {closeButton && (
                    <div className="modal-close" onClick={onClose}>
                        &times;
                    </div>
                )}
                {title && <h5 className="modal-title">{title}</h5>}
                {description && <p className="modal-description">{description}</p>}
                {children}
                <div
                    className={`modal-button-group ${hasOneButton ? 'single-button-center' : ''}`}
                >
                    {button1Text && <button
                        className={`modal-button ${green ? 'button-green' : red ? 'button-red' : 'button-yellow'}`}
                        onClick={() => (button1CallAction ? button1CallAction() : onClose())}
                    >
                        {button1Text}
                    </button>}
                    {button2Text && (
                        <button
                            className={`modal-button ${green ? 'button-green' : red ? 'button-red' : 'button-yellow'}`}
                            onClick={() => (button2CallAction ? button2CallAction() : onClose())}
                        >
                            {button2Text}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CustomModal;