import React, { useEffect, useState } from 'react';
import taxiApi from '../../apis/taxiApi';
import DynamicTable from "./DynamicTable";
import {formatDateTime, formatDateTimeShort} from "../../lib/dateUtils";

const UserCompletedOrders = ({ userId }) => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const fetchCompletedOrders = async () => {
            try {
                const response = await taxiApi.getCompletedTaxiOrdersByUserId(userId);
                if (response && !response.error) {
                    console.log(response, "orders");
                    setOrders(response);
                }
            } catch (error) {
                console.error('Error fetching completed orders:', error);
            }
        };

        fetchCompletedOrders();
    }, [userId]);

    const columnsConfig = {
        order_id: 'Order ID',
        driverName: 'Driver',
        status: 'Status',
        price: 'Amount',
        date: 'Date',
    };

    const parseOrderData = orders.map(order => ({
        ...order,
        order_id: order.order_id,
        driverName: `${order?.driver?.user?.first_name} ${order?.driver?.user?.last_name}`,
        status: order.status,
        price: `${order.payment.price} €`,
        date: formatDateTimeShort(order.created_at),
    }));

    return (
        <div>
            <h3>Completed Orders</h3>
            <DynamicTable
                data={parseOrderData}
                columnsConfig={columnsConfig}
                rowsPerPage={12}
                extendable={true}
            />
        </div>
    );
};

export default UserCompletedOrders;