import React from 'react';
import {getTaxiPreferencesOptions, getTransferPreferencesOptions} from '../../constants/keyValueOptions';
import './../css/RideRequirements.css';

const RideRequirements = ({ selectedExtras, setSelectedExtras, preview, l10nText, transfer }) => {
    const taxiPreferencesOptions = getTaxiPreferencesOptions();
    const transferPreferencesOptions = getTransferPreferencesOptions();

    const preferences = transfer ? transferPreferencesOptions : taxiPreferencesOptions

    const handlePress = (value) => {
        if (selectedExtras && selectedExtras[value]) {
            const newExtras = { ...selectedExtras };
            delete newExtras[value];
            setSelectedExtras(newExtras);
        } else {
            setSelectedExtras({ ...selectedExtras, [value]: true });
        }
    };

    selectedExtras = selectedExtras || {};

    if (preview) {
        const hasSelectedExtras = Object.values(selectedExtras)?.some(value => value);

        return (
            <div>
                <h2>{l10nText?.requirements}</h2>
                <div className="requirements-container">
                    {hasSelectedExtras ? (
                        preferences.map((extra, index) => {
                            if (selectedExtras[extra.value]) {
                                return (
                                    <div key={index} className="extras-container-preview">
                                        <img src={extra.image} alt={extra.name} className="extras-icon" />
                                        <span className="card-text">{extra.name}</span>
                                    </div>
                                );
                            }
                            return null;
                        })
                    ) : (
                        <p className="info-text">{l10nText?.noRequirements}</p>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div>
            <h4>{l10nText?.requirements}</h4>
            <div className="scroll-container">
                {preferences.map((extra, index) => (
                    <div key={index}>
                        <button
                            type="button"
                            onClick={() => handlePress(extra.value)}
                            className={`extras-button ${selectedExtras[extra.value] ? 'selected' : ''}`}
                        >
                            <img src={extra.image} alt={extra.name} className="extras-icon" />
                            <span className="card-text">{extra.name}</span>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RideRequirements;
