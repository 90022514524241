import React from "react";

import '../css/Review.css';
import Card from "./Card";
import Avatar from '../../assets/img/avatar.jpg';
import RateFull from '../../assets/img/rate_full.png';
import RateHalf from '../../assets/img/rate_half.png';
import RateEmpty from '../../assets/img/rate_empty.png';
import Liked from '../../assets/img/like.png';
import Disliked from '../../assets/img/dislike.png';

import moment from "moment";

function getStars(rating) {
    const maxStars = 5;
    const fullStars = Math.floor(rating);
    const halfStars = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = maxStars - fullStars - halfStars;

    return {
        full: fullStars,
        half: halfStars,
        empty: emptyStars
    };
}

function displayStars(rating) {
    const stars = getStars(rating);
    const starElements = [];

    for (let i = 0; i < stars.full; i++) {
        starElements.push(RateFull);
    }

    for (let i = 0; i < stars.half; i++) {
        starElements.push(RateHalf);
    }

    for (let i = 0; i < stars.empty; i++) {
        starElements.push(RateEmpty);
    }
    return starElements;
}

function titleCase(str) {
    const formattedStr = str.replace(/_/g, ' ');
    const spacedStr = formattedStr.replace(/([a-z])([A-Z])/g, '$1 $2');
    const splitStr = spacedStr.toLowerCase().split(' ');

    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    return splitStr.join(' ');
}

function Review(props) {
    const {review} = props;
    let isReviewingDriver = review.author.user_role === "PERSONAL";
    let startsObj;

    if (review.feedback === null) return null;

    if (isReviewingDriver) {
        startsObj = {
            punctuality: displayStars(review.feedback.punctuality),
            drivingSkills: displayStars(review.feedback.drivingSkills),
            customerService: displayStars(review.feedback.customerService),
            professionalism: displayStars(review.feedback.professionalism),
            vehicleCondition: displayStars(review.feedback.vehicleCondition),
            knowledgeOfRoutes: displayStars(review.feedback.knowledgeOfRoutes)
        };
    } else {
        startsObj = {
            overall_experience: review.feedback.overall_experience,
            punctuality: displayStars(review.feedback.punctuality),
            customer_behaviour: displayStars(review.feedback.customer_behaviour),
        };
    }

    const stars = displayStars(review.rating);
    const overallExperience = review.feedback.overall_experience;

    return (
        <Card>
            <div className={`review ${isReviewingDriver ? 'driver-review' : 'customer-review'}`} style={props.style}>
                <div className={"review-header"}>
                    <h4>{isReviewingDriver ? "Customer reviewed" : "Driver reviewed"} {review.target.name ? review.target.name : `${review.target.first_name} ${review.target.last_name}`}</h4>
                    <div className={"reviewer"}>
                        <img src={review.author.avatar || Avatar}
                             alt={review.author.first_name + " " + review.author.last_name} className={"avatar"}/>
                        <div className={"reviewer-info"}>
                            <div className={"name"}>{review.author.first_name + " " + review.author.last_name}</div>
                            <div className={"date"}>{moment(review.created_at).format("DD MMM YYYY")}</div>
                        </div>

                        <div className={"ratings"}>
                            {Object.keys(startsObj).map((key, index) => {
                                if (key === 'overall_experience') {
                                    // if (overallExperience === null) return null;
                                    return (
                                        <div key={index} className={"rating"}>
                                            <div className={"rating-title"} style={{paddingBottom: '6px'}}>
                                                <p><b>{titleCase(key.replace(/_/g, " "))}</b></p>
                                            </div>
                                            <div className={"rating-wrap"}>
                                                <div className={"overall-experience"}>
                                                    {overallExperience ? (
                                                        <>
                                                            <div className="circle-container green">
                                                                <img src={Liked} alt="Liked"
                                                                     className="experience-icon"/>
                                                            </div>
                                                            <p className="experience-subtitle text-good">Good</p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="circle-container red">
                                                                <img src={Disliked} alt="Disliked"
                                                                     className="experience-icon"/>
                                                            </div>
                                                            <p className="experience-subtitle text-bad">Bad</p>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                                return null;
                            })}

                            <div className={"rating rating-general"}>
                                <div className={"rating-title"}>
                                    <p><b>General rating</b></p>
                                </div>
                                <div className={"rating-wrap"}>
                                    <div className={"rate"}>{(review.rating || 0)?.toFixed(1)}/5</div>
                                    <div className={"stars"}>
                                        {stars.map((star, index) => {
                                            return <img key={index} src={star} alt={"star"}/>
                                        })}
                                    </div>
                                </div>
                            </div>
                            {Object.keys(startsObj).map((key, index) => {
                                if (key === 'overall_experience') {
                                    // if (overallExperience === null) return null;
                                    return null;
                                }
                                return (
                                    <div key={index} className={"rating"}>
                                        <div className={"rating-title"}>
                                            <p>{titleCase(key.replace(/_/g, " "))}</p>
                                        </div>
                                        <div className={"rating-wrap"}>
                                            <div
                                                className={"rate"}>{review.feedback[key] !== null ? review.feedback[key] : 0}/5
                                            </div>
                                            <div className={"stars"}>
                                                {startsObj[key].map((star, index) => {
                                                    return <img key={index} src={star} alt={"star"}/>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className={"review-content"}>{review.comment}</div>
                <div className={"review-footer"}>

                </div>
            </div>
        </Card>
    );
}

export default Review;