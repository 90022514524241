export const vehicleClassOptions = [
    { name: 'SEDAN', value: 'SEDAN' },
    { name: 'CARAVAN', value: 'CARAVAN' },
    { name: 'SUV', value: 'SUV' },
    { name: 'MINIVAN', value: 'MINIVAN' },
    { name: 'VAN', value: 'VAN' },
    { name: 'MINIBUS', value: 'MINIBUS' },
    { name: 'BUS', value: 'BUS' },
    { name: 'CARGO_VAN', value: 'CARGO_VAN' },
];

export const vehicleCategoryOptions = [
    { name: 'STANDARD', value: 'STANDARD' },
    { name: 'PREMIUM', value: 'PREMIUM' },
];

export const cityCodeOptions = [
    { name: 'CE', value: 'CE' },
    { name: 'GO', value: 'GO' },
    { name: 'KK', value: 'KK' },
    { name: 'KP', value: 'KP' },
    { name: 'KR', value: 'KR' },
    { name: 'LJ', value: 'LJ' },
    { name: 'MB', value: 'MB' },
    { name: 'MS', value: 'MS' },
    { name: 'NM', value: 'NM' },
    { name: 'PO', value: 'PO' },
    { name: 'SG', value: 'SG' }
];


