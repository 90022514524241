import axios from "../lib/axios";

export const reportLostItem = async (lostItem) => {
    try {
        const response = await axios.post(`/lost_items/report`, lostItem);
        return response.data;
    } catch (error) {
        console.error('Error reporting lost item:', error);
        throw error;
    }
}

export const getLostItems = async () => {
    try {
        const response = await axios.get('/lost_items');
        return response.data;
    } catch (error) {
        console.error("Error fetching lost items:", error);
        throw error;
    }
};

export const updateLostItem = async (lostItemId, data) => {
    try {
        const response = await axios.patch(`/lost_items/update/${lostItemId}`, data);
        return response.data;
    } catch (error) {
        console.error('Error reporting lost item:', error);
        throw error;
    }
}
