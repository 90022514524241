export const carBrands = [
    { label: 'Acura', value: 'Acura' },
    { label: 'Alfa Romeo', value: 'Alfa Romeo' },
    { label: 'Audi', value: 'Audi' },
    { label: 'Bentley', value: 'Bentley' },
    { label: 'BMW', value: 'BMW' },
    { label: 'Buick', value: 'Buick' },
    { label: 'Cadillac', value: 'Cadillac' },
    { label: 'Chevrolet', value: 'Chevrolet' },
    { label: 'Chrysler', value: 'Chrysler' },
    { label: 'Citroen', value: 'Citroen' },
    { label: 'Dodge', value: 'Dodge' },
    { label: 'Fiat', value: 'Fiat' },
    { label: 'Ford', value: 'Ford' },
    { label: 'GMC', value: 'GMC' },
    { label: 'Honda', value: 'Honda' },
    { label: 'Hyundai', value: 'Hyundai' },
    { label: 'Infiniti', value: 'Infiniti' },
    { label: 'Jaguar', value: 'Jaguar' },
    { label: 'Jeep', value: 'Jeep' },
    { label: 'Kia', value: 'Kia' },
    { label: 'Land Rover', value: 'Land Rover' },
    { label: 'Lexus', value: 'Lexus' },
    { label: 'Lincoln', value: 'Lincoln' },
    { label: 'Mazda', value: 'Mazda' },
    { label: 'Mercedes-Benz', value: 'Mercedes-Benz' },
    { label: 'Mini', value: 'Mini' },
    { label: 'Mitsubishi', value: 'Mitsubishi' },
    { label: 'Nissan', value: 'Nissan' },
    { label: 'Peugeot', value: 'Peugeot' },
    { label: 'Ram', value: 'Ram' },
    { label: 'Renault', value: 'Renault' },
    { label: 'Seat', value: 'Seat' },
    { label: 'Skoda', value: 'Skoda' },
    { label: 'Subaru', value: 'Subaru' },
    { label: 'Suzuki', value: 'Suzuki' },
    { label: 'Tesla', value: 'Tesla' },
    { label: 'Toyota', value: 'Toyota' },
    { label: 'Volkswagen', value: 'Volkswagen' },
    { label: 'Volvo', value: 'Volvo' },
];

export const carModelsData = {
    Audi: [
        { value: 'A1', label: 'A1' },
        { value: 'A3', label: 'A3' },
        { value: 'A4', label: 'A4' },
        { value: 'A5', label: 'A5' },
        { value: 'A6', label: 'A6' },
        { value: 'A7', label: 'A7' },
        { value: 'A8', label: 'A8' },
        { value: 'Q2', label: 'Q2' },
        { value: 'Q3', label: 'Q3' },
        { value: 'Q5', label: 'Q5' },
        { value: 'Q7', label: 'Q7' },
        { value: 'Q8', label: 'Q8' },
        { value: 'R8', label: 'R8' },
        { value: 'TT', label: 'TT' },
    ],
    BMW: [
        { value: '1 Series', label: '1 Series' },
        { value: '2 Series', label: '2 Series' },
        { value: '3 Series', label: '3 Series' },
        { value: '4 Series', label: '4 Series' },
        { value: '5 Series', label: '5 Series' },
        { value: '6 Series', label: '6 Series' },
        { value: '7 Series', label: '7 Series' },
        { value: '8 Series', label: '8 Series' },
        { value: 'X1', label: 'X1' },
        { value: 'X2', label: 'X2' },
        { value: 'X3', label: 'X3' },
        { value: 'X4', label: 'X4' },
        { value: 'X5', label: 'X5' },
        { value: 'X6', label: 'X6' },
        { value: 'X7', label: 'X7' },
        { value: 'Z4', label: 'Z4' },
    ],
    Ford: [
        { value: 'Fiesta', label: 'Fiesta' },
        { value: 'Focus', label: 'Focus' },
        { value: 'Mustang', label: 'Mustang' },
        { value: 'Fusion', label: 'Fusion' },
        { value: 'Escape', label: 'Escape' },
        { value: 'Edge', label: 'Edge' },
        { value: 'Explorer', label: 'Explorer' },
        { value: 'Expedition', label: 'Expedition' },
        { value: 'Ranger', label: 'Ranger' },
        { value: 'F-Series', label: 'F-Series' },
    ],
    Honda: [
        { value: 'Accord', label: 'Accord' },
        { value: 'Civic', label: 'Civic' },
        { value: 'CR-V', label: 'CR-V' },
        { value: 'Fit', label: 'Fit' },
        { value: 'HR-V', label: 'HR-V' },
        { value: 'Odyssey', label: 'Odyssey' },
        { value: 'Pilot', label: 'Pilot' },
    ],
    Toyota: [
        { value: 'Camry', label: 'Camry' },
        { value: 'Corolla', label: 'Corolla' },
        { value: 'Rav4', label: 'Rav4' },
        { value: 'Highlander', label: 'Highlander' },
        { value: 'Prius', label: 'Prius' },
        { value: 'Tacoma', label: 'Tacoma' },
        { value: 'Sienna', label: 'Sienna' },
    ],
    'Mercedes-Benz' : [
        { value: 'A-Class', label: 'A-Class' },
        { value: 'B-Class', label: 'B-Class' },
        { value: 'C-Class', label: 'C-Class' },
        { value: 'CLA-Class', label: 'CLA-Class' },
        { value: 'CLS-Class', label: 'CLS-Class' },
        { value: 'E-Class', label: 'E-Class' },
        { value: 'GLA-Class', label: 'GLA-Class' },
        { value: 'GLB-Class', label: 'GLB-Class' },
        { value: 'GLC-Class', label: 'GLC-Class' },
        { value: 'GLE-Class', label: 'GLE-Class' },
        { value: 'GLS-Class', label: 'GLS-Class' },
        { value: 'S-Class', label: 'S-Class' },
        { value: 'SLC-Class', label: 'SLC-Class' },
        { value: 'SL-Class', label: 'SL-Class' },
        { value: 'AMG GT', label: 'AMG GT' },
        { value: 'GLK-Class', label: 'GLK-Class' },
        { value: 'G-Class', label: 'G-Class' },
        { value: 'Metris', label: 'Metris' },
        { value: 'Sprinter', label: 'Sprinter' },
        { value: 'V-Class', label: 'V-Class' },
    ],
    Volkswagen: [
        { value: 'Golf', label: 'Golf' },
        { value: 'Jetta', label: 'Jetta' },
        { value: 'Passat', label: 'Passat' },
        { value: 'Tiguan', label: 'Tiguan' },
        { value: 'Atlas', label: 'Atlas' },
        { value: 'Arteon', label: 'Arteon' },
        { value: 'Beetle', label: 'Beetle' },
        { value: 'CC', label: 'CC' },
        { value: 'Touareg', label: 'Touareg' },
        { value: 'ID.4', label: 'ID.4' },
        { value: 'ID.3', label: 'ID.3' },
        { value: 'Taos', label: 'Taos' },
        { value: 'T-Roc', label: 'T-Roc' },
        { value: 'T-Cross', label: 'T-Cross' },
        { value: 'Passat CC', label: 'Passat CC' },
        { value: 'Routan', label: 'Routan' },
        { value: 'Fox', label: 'Fox' },
        { value: 'Up!', label: 'Up!' },
        { value: 'Polo', label: 'Polo' },
        { value: 'Lupo', label: 'Lupo' },
    ],
    Tesla: [
        { value: 'Model S', label: 'Model S' },
        { value: 'Model 3', label: 'Model 3' },
        { value: 'Model X', label: 'Model X' },
        { value: 'Model Y', label: 'Model Y' },
        { value: 'Roadster', label: 'Roadster' },
        { value: 'Cybertruck', label: 'Cybertruck' },
        { value: 'Semi', label: 'Semi' },
    ],
    Chevrolet: [
        { value: 'Camaro', label: 'Camaro' },
        { value: 'Corvette', label: 'Corvette' },
        { value: 'Silverado', label: 'Silverado' },
        { value: 'Equinox', label: 'Equinox' },
        { value: 'Malibu', label: 'Malibu' },
        { value: 'Traverse', label: 'Traverse' },
        { value: 'Tahoe', label: 'Tahoe' },
        { value: 'Impala', label: 'Impala' },
        { value: 'Suburban', label: 'Suburban' },
        { value: 'Blazer', label: 'Blazer' },
    ],
    Nissan: [
        { value: 'Altima', label: 'Altima' },
        { value: 'Maxima', label: 'Maxima' },
        { value: 'Sentra', label: 'Sentra' },
        { value: 'Versa', label: 'Versa' },
        { value: 'Rogue', label: 'Rogue' },
        { value: 'Murano', label: 'Murano' },
        { value: 'Pathfinder', label: 'Pathfinder' },
        { value: 'Armada', label: 'Armada' },
        { value: 'Frontier', label: 'Frontier' },
        { value: 'Titan', label: 'Titan' },
    ],
    Hyundai: [
        { value: 'Accent', label: 'Accent' },
        { value: 'Elantra', label: 'Elantra' },
        { value: 'Sonata', label: 'Sonata' },
        { value: 'Veloster', label: 'Veloster' },
        { value: 'Tucson', label: 'Tucson' },
        { value: 'Santa Fe', label: 'Santa Fe' },
        { value: 'Kona', label: 'Kona' },
        { value: 'Palisade', label: 'Palisade' },
        { value: 'Venue', label: 'Venue' },
        { value: 'Nexo', label: 'Nexo' },
    ],
    Kia: [
        { value: 'Forte', label: 'Forte' },
        { value: 'Optima', label: 'Optima' },
        { value: 'Rio', label: 'Rio' },
        { value: 'Stinger', label: 'Stinger' },
        { value: 'Cadenza', label: 'Cadenza' },
        { value: 'Soul', label: 'Soul' },
        { value: 'Niro', label: 'Niro' },
        { value: 'Sportage', label: 'Sportage' },
        { value: 'Sorento', label: 'Sorento' },
        { value: 'Telluride', label: 'Telluride' },
    ],
    Subaru:[
        { value: 'Impreza', label: 'Impreza' },
        { value: 'Legacy', label: 'Legacy' },
        { value: 'WRX', label: 'WRX' },
        { value: 'BRZ', label: 'BRZ' },
        { value: 'Crosstrek', label: 'Crosstrek' },
        { value: 'Forester', label: 'Forester' },
        { value: 'Outback', label: 'Outback' },
        { value: 'Ascent', label: 'Ascent' },
    ],
    Volvo: [
        { value: 'S60', label: 'S60' },
        { value: 'S90', label: 'S90' },
        { value: 'V60', label: 'V60' },
        { value: 'V90', label: 'V90' },
        { value: 'XC40', label: 'XC40' },
        { value: 'XC60', label: 'XC60' },
        { value: 'XC70', label: 'XC70' },
        { value: 'XC90', label: 'XC90' },
        { value: 'C30', label: 'C30' },
        { value: 'C70', label: 'C70' },
        { value: '850', label: '850' },
        { value: '960', label: '960' },
    ],
    Porsche: [
        { value: '911', label: '911' },
        { value: 'Boxster', label: 'Boxster' },
        { value: 'Cayenne', label: 'Cayenne' },
        { value: 'Cayman', label: 'Cayman' },
        { value: 'Macan', label: 'Macan' },
        { value: 'Panamera', label: 'Panamera' },
        { value: 'Taycan', label: 'Taycan' },
        { value: '718', label: '718' },
        { value: '944', label: '944' },
        { value: '959', label: '959' },
        { value: '968', label: '968' },
        { value: '918 Spyder', label: '918 Spyder' },
    ],
    Mazda:[
        { value: 'Mazda2', label: 'Mazda2' },
        { value: 'Mazda3', label: 'Mazda3' },
        { value: 'Mazda6', label: 'Mazda6' },
        { value: 'MX-5 Miata', label: 'MX-5 Miata' },
        { value: 'CX-3', label: 'CX-3' },
        { value: 'CX-30', label: 'CX-30' },
        { value: 'CX-5', label: 'CX-5' },
        { value: 'CX-9', label: 'CX-9' },
        { value: 'MPV', label: 'MPV' },
        { value: 'RX-7', label: 'RX-7' },
        { value: 'RX-8', label: 'RX-8' },
        { value: 'MX-6', label: 'MX-6' },
    ],
    Lexus: [
        { value: 'IS', label: 'IS' },
        { value: 'ES', label: 'ES' },
        { value: 'GS', label: 'GS' },
        { value: 'LS', label: 'LS' },
        { value: 'RC', label: 'RC' },
        { value: 'LC', label: 'LC' },
        { value: 'UX', label: 'UX' },
        { value: 'NX', label: 'NX' },
        { value: 'RX', label: 'RX' },
        { value: 'GX', label: 'GX' },
        { value: 'LX', label: 'LX' },
        { value: 'CT', label: 'CT' },
    ],
    Jeep:[
        { value: 'Wrangler', label: 'Wrangler' },
        { value: 'Grand Cherokee', label: 'Grand Cherokee' },
        { value: 'Cherokee', label: 'Cherokee' },
        { value: 'Compass', label: 'Compass' },
        { value: 'Renegade', label: 'Renegade' },
        { value: 'Gladiator', label: 'Gladiator' },
        { value: 'Commander', label: 'Commander' },
        { value: 'Patriot', label: 'Patriot' },
        { value: 'Liberty', label: 'Liberty' },
        { value: 'Wagoneer', label: 'Wagoneer' },
        { value: 'CJ', label: 'CJ' },
        { value: 'Scrambler', label: 'Scrambler' },
    ],
    'Land Rover': [
        { value: 'Defender', label: 'Defender' },
        { value: 'Discovery', label: 'Discovery' },
        { value: 'Discovery Sport', label: 'Discovery Sport' },
        { value: 'Range Rover', label: 'Range Rover' },
        { value: 'Range Rover Sport', label: 'Range Rover Sport' },
        { value: 'Range Rover Velar', label: 'Range Rover Velar' },
        { value: 'Range Rover Evoque', label: 'Range Rover Evoque' },
        { value: 'Range Rover Evoque Convertible', label: 'Range Rover Evoque Convertible' },
        { value: 'Range Rover Evoque Coupe', label: 'Range Rover Evoque Coupe' },
        { value: 'Range Rover Evoque Five-Door', label: 'Range Rover Evoque Five-Door' },
        { value: 'Range Rover Sport SVR', label: 'Range Rover Sport SVR' },
    ],
    Jaguar: [
        { value: 'XE', label: 'XE' },
        { value: 'XF', label: 'XF' },
        { value: 'XJ', label: 'XJ' },
        { value: 'F-Type', label: 'F-Type' },
        { value: 'E-Pace', label: 'E-Pace' },
        { value: 'F-Pace', label: 'F-Pace' },
        { value: 'I-Pace', label: 'I-Pace' },
        { value: 'S-Type', label: 'S-Type' },
        { value: 'X-Type', label: 'X-Type' },
        { value: 'XK', label: 'XK' },
        { value: 'XKR', label: 'XKR' },
        { value: 'XJR', label: 'XJR' },
    ],
    Ram: [
        { value: '1000', label: '1000' },
        { value: '1200', label: '1200' },
        { value: '1500', label: '1500' },
        { value: '1500 REV', label: '1500 REV' },
        { value: '700', label: '700' },
        { value: 'ProMaster', label: 'ProMaster' },
        { value: 'HD', label: 'HD' },
        { value: 'V700', label: 'V700' },
    ],
    GMC: [
        { value: 'Sierra 1500', label: 'Sierra 1500' },
        { value: 'Sierra 2500HD', label: 'Sierra 2500HD' },
        { value: 'Sierra 3500HD', label: 'Sierra 3500HD' },
        { value: 'Canyon', label: 'Canyon' },
        { value: 'Yukon', label: 'Yukon' },
        { value: 'Yukon XL', label: 'Yukon XL' },
        { value: 'Acadia', label: 'Acadia' },
        { value: 'Terrain', label: 'Terrain' },
        { value: 'Savana', label: 'Savana' },
    ],
    Acura: [
        { value: 'ILX', label: 'ILX' },
        { value: 'TLX', label: 'TLX' },
        { value: 'RLX', label: 'RLX' },
        { value: 'MDX', label: 'MDX' },
        { value: 'RDX', label: 'RDX' },
        { value: 'NSX', label: 'NSX' },
    ],
    Buick: [
        { value: 'Encore', label: 'Encore' },
        { value: 'Encore GX', label: 'Encore GX' },
        { value: 'Enclave', label: 'Enclave' },
        { value: 'Envision', label: 'Envision' },
        { value: 'Regal Sportback', label: 'Regal Sportback' },
        { value: 'Regal TourX', label: 'Regal TourX' },
    ],
    Cadillac:[
        { value: 'CT4', label: 'CT4' },
        { value: 'CT5', label: 'CT5' },
        { value: 'CT6', label: 'CT6' },
        { value: 'XT4', label: 'XT4' },
        { value: 'XT5', label: 'XT5' },
        { value: 'XT6', label: 'XT6' },
        { value: 'Escalade', label: 'Escalade' },
        { value: 'Escalade ESV', label: 'Escalade ESV' },
        { value: 'ATS', label: 'ATS' },
        { value: 'XTS', label: 'XTS' },
        { value: 'CTS', label: 'CTS' },
        { value: 'ELR', label: 'ELR' },
    ],
    Lincoln:[
        { value: 'Aviator', label: 'Aviator' },
        { value: 'Corsair', label: 'Corsair' },
        { value: 'MKZ', label: 'MKZ' },
        { value: 'Continental', label: 'Continental' },
        { value: 'Nautilus', label: 'Nautilus' },
        { value: 'Navigator', label: 'Navigator' },
        { value: 'Town Car', label: 'Town Car' },
        { value: 'LS', label: 'LS' },
        { value: 'MKC', label: 'MKC' },
        { value: 'MKS', label: 'MKS' },
        { value: 'MKT', label: 'MKT' },
        { value: 'Zephyr', label: 'Zephyr' },
    ],
    Infiniti:[
        { value: 'Q50', label: 'Q50' },
        { value: 'Q60', label: 'Q60' },
        { value: 'Q70', label: 'Q70' },
        { value: 'QX30', label: 'QX30' },
        { value: 'QX50', label: 'QX50' },
        { value: 'QX55', label: 'QX55' },
        { value: 'QX60', label: 'QX60' },
        { value: 'QX70', label: 'QX70' },
        { value: 'QX80', label: 'QX80' },
        { value: 'EX35', label: 'EX35' },
        { value: 'EX37', label: 'EX37' },
        { value: 'FX35', label: 'FX35' },
        { value: 'FX37', label: 'FX37' },
        { value: 'FX45', label: 'FX45' },
        { value: 'FX50', label: 'FX50' },
        { value: 'G35', label: 'G35' },
        { value: 'G37', label: 'G37' },
        { value: 'M35', label: 'M35' },
        { value: 'M37', label: 'M37' },
        { value: 'M45', label: 'M45' },
    ],
    Chrysler:[
        { value: '300', label: '300' },
        { value: 'Pacifica', label: 'Pacifica' },
        { value: 'Voyager', label: 'Voyager' },
        { value: 'Aspen', label: 'Aspen' },
        { value: 'Crossfire', label: 'Crossfire' },
        { value: 'Sebring', label: 'Sebring' },
        { value: 'Concorde', label: 'Concorde' },
        { value: 'PT Cruiser', label: 'PT Cruiser' },
        { value: 'LHS', label: 'LHS' },
        { value: 'New Yorker', label: 'New Yorker' },
        { value: 'Imperial', label: 'Imperial' },
        { value: 'LeBaron', label: 'LeBaron' },
        { value: 'Fifth Avenue', label: 'Fifth Avenue' },
        { value: 'Cirrus', label: 'Cirrus' },
        { value: 'Laser', label: 'Laser' },
        { value: 'Dynasty', label: 'Dynasty' },
        { value: 'TC by Maserati', label: 'TC by Maserati' },
        { value: 'Saratoga', label: 'Saratoga' },
        { value: 'Vision', label: 'Vision' },
        { value: 'Arrow', label: 'Arrow' },
    ],
    Fiat: [
        { value: '500', label: '500' },
        { value: '500X', label: '500X' },
        { value: '500L', label: '500L' },
        { value: '500e', label: '500e' },
        { value: '124 Spider', label: '124 Spider' },
        { value: 'Panda', label: 'Panda' },
        { value: 'Punto', label: 'Punto' },
        { value: 'Tipo', label: 'Tipo' },
        { value: 'Bravo', label: 'Bravo' },
        { value: 'Doblo', label: 'Doblo' },
        { value: 'Talento', label: 'Talento' },
        { value: 'Ducato', label: 'Ducato' },
        { value: 'Freemont', label: 'Freemont' },
        { value: 'Fullback', label: 'Fullback' },
        { value: 'Qubo', label: 'Qubo' },
        { value: 'Linea', label: 'Linea' },
        { value: 'Idea', label: 'Idea' },
        { value: 'Sedici', label: 'Sedici' },
        { value: 'Mobi', label: 'Mobi' },
        { value: 'Fiorino', label: 'Fiorino' },
    ],
    Mini:[
        { value: 'Cooper', label: 'Cooper' },
        { value: 'Cooper S', label: 'Cooper S' },
        { value: 'Cooper SE', label: 'Cooper SE' },
        { value: 'Cooper Convertible', label: 'Cooper Convertible' },
        { value: 'Cooper S Convertible', label: 'Cooper S Convertible' },
        { value: 'Cooper SE Convertible', label: 'Cooper SE Convertible' },
        { value: 'Cooper Clubman', label: 'Cooper Clubman' },
        { value: 'Cooper S Clubman', label: 'Cooper S Clubman' },
        { value: 'Cooper SE Clubman', label: 'Cooper SE Clubman' },
        { value: 'Cooper Countryman', label: 'Cooper Countryman' },
        { value: 'Cooper S Countryman', label: 'Cooper S Countryman' },
        { value: 'Cooper SE Countryman', label: 'Cooper SE Countryman' },
        { value: 'Cooper Paceman', label: 'Cooper Paceman' },
        { value: 'Cooper S Paceman', label: 'Cooper S Paceman' },
        { value: 'Cooper John Cooper Works', label: 'Cooper John Cooper Works' },
        { value: 'Cooper S John Cooper Works', label: 'Cooper S John Cooper Works' },
        { value: 'Cooper SE Countryman All4', label: 'Cooper SE Countryman All4' },
        { value: 'Cooper S E Countryman All4', label: 'Cooper S E Countryman All4' },
        { value: 'Cooper S E Countryman All4 PHEV', label: 'Cooper S E Countryman All4 PHEV' },
        { value: 'Cooper SE Electric Hardtop 2 Door', label: 'Cooper SE Electric Hardtop 2 Door' },
    ],
    Mitsubishi:[
        { value: 'Mirage', label: 'Mirage' },
        { value: 'Mirage G4', label: 'Mirage G4' },
        { value: 'Outlander', label: 'Outlander' },
        { value: 'Outlander Sport', label: 'Outlander Sport' },
        { value: 'Eclipse Cross', label: 'Eclipse Cross' },
        { value: 'Eclipse', label: 'Eclipse' },
        { value: 'Lancer', label: 'Lancer' },
        { value: 'Lancer Evolution', label: 'Lancer Evolution' },
        { value: 'Lancer Sportback', label: 'Lancer Sportback' },
        { value: 'Galant', label: 'Galant' },
        { value: 'Montero Sport', label: 'Montero Sport' },
        { value: 'Raider', label: 'Raider' },
        { value: 'Diamante', label: 'Diamante' },
        { value: '3000GT', label: '3000GT' },
        { value: 'Mirage Asti', label: 'Mirage Asti' },
        { value: 'Mirage Van', label: 'Mirage Van' },
        { value: 'Minica', label: 'Minica' },
        { value: 'Airtrek', label: 'Airtrek' },
        { value: 'Attrage', label: 'Attrage' },
        { value: 'Dingo', label: 'Dingo' },
    ],
    'Alfa Romeo': [
        { value: 'Giulia', label: 'Giulia' },
        { value: 'Stelvio', label: 'Stelvio' },
        { value: '4C', label: '4C' },
        { value: 'Giulietta', label: 'Giulietta' },
        { value: 'Giulietta Quadrifoglio', label: 'Giulietta Quadrifoglio' },
        { value: 'Spider', label: 'Spider' },
        { value: '159', label: '159' },
        { value: 'Mito', label: 'Mito' },
        { value: 'Brera', label: 'Brera' },
        { value: '8C', label: '8C' },
        { value: '6C', label: '6C' },
        { value: '33', label: '33' },
        { value: 'Giulia GTA', label: 'Giulia GTA' },
        { value: 'Giulia Veloce', label: 'Giulia Veloce' },
        { value: 'Giulia QV', label: 'Giulia QV' },
        { value: 'Stelvio QV', label: 'Stelvio QV' },
        { value: 'GT', label: 'GT' },
        { value: 'SZ', label: 'SZ' },
        { value: 'RZ', label: 'RZ' },
        { value: '155', label: '155' },
    ],
    Bentley: [
        { value: 'Continental GT', label: 'Continental GT' },
        { value: 'Bentayga', label: 'Bentayga' },
        { value: 'Flying Spur', label: 'Flying Spur' },
        { value: 'Mulsanne', label: 'Mulsanne' },
        { value: 'Continental GTC', label: 'Continental GTC' },
        { value: 'Continental Supersports', label: 'Continental Supersports' },
        { value: 'Bentayga Speed', label: 'Bentayga Speed' },
        { value: 'Mulsanne Speed', label: 'Mulsanne Speed' },
        { value: 'Mulsanne Extended Wheelbase', label: 'Mulsanne Extended Wheelbase' },
        { value: 'Continental GT Speed', label: 'Continental GT Speed' },
        { value: 'Continental GT Convertible', label: 'Continental GT Convertible' },
        { value: 'Continental GT V8', label: 'Continental GT V8' },
        { value: 'Continental GT V8 S', label: 'Continental GT V8 S' },
        { value: 'Continental GT3-R', label: 'Continental GT3-R' },
        { value: 'Continental Flying Spur', label: 'Continental Flying Spur' },
        { value: 'Continental Flying Spur Speed', label: 'Continental Flying Spur Speed' },
        { value: 'Azure', label: 'Azure' },
        { value: 'Azure T', label: 'Azure T' },
        { value: 'Arnage', label: 'Arnage' },
        { value: 'Brooklands', label: 'Brooklands' },
    ],

    Renault: [
        { value: 'Clio', label: 'Clio' },
        { value: 'Megane', label: 'Megane' },
        { value: 'Captur', label: 'Captur' },
        { value: 'Kadjar', label: 'Kadjar' },
        { value: 'Scenic', label: 'Scenic' },
        { value: 'Talisman', label: 'Talisman' },
        { value: 'Zoe', label: 'Zoe' },
        { value: 'Koleos', label: 'Koleos' },
        { value: 'Espace', label: 'Espace' },
    ],

    Peugeot: [
        { value: '208', label: '208' },
        { value: '308', label: '308' },
        { value: '2008', label: '2008' },
        { value: '3008', label: '3008' },
        { value: '5008', label: '5008' },
        { value: '508', label: '508' },
        { value: 'Partner', label: 'Partner' },
        { value: 'Rifter', label: 'Rifter' },
        { value: 'Traveller', label: 'Traveller' },
    ],

    Citroen: [
        { value: 'C1', label: 'C1' },
        { value: 'C3', label: 'C3' },
        { value: 'C4', label: 'C4' },
        { value: 'C5', label: 'C5' },
        { value: 'Berlingo', label: 'Berlingo' },
        { value: 'C3 Aircross', label: 'C3 Aircross' },
        { value: 'C4 Cactus', label: 'C4 Cactus' },
        { value: 'C4 SpaceTourer', label: 'C4 SpaceTourer' },
    ],

    Suzuki: [
        { value: 'Swift', label: 'Swift' },
        { value: 'Vitara', label: 'Vitara' },
        { value: 'Jimny', label: 'Jimny' },
        { value: 'Baleno', label: 'Baleno' },
        { value: 'Ignis', label: 'Ignis' },
        { value: 'SX4 S-Cross', label: 'SX4 S-Cross' },
        { value: 'Celerio', label: 'Celerio' },
    ],

    Dodge: [
        { value: 'Challenger', label: 'Challenger' },
        { value: 'Charger', label: 'Charger' },
        { value: 'Durango', label: 'Durango' },
        { value: 'Journey', label: 'Journey' },
        { value: 'Ram 1500', label: 'Ram 1500' },
        { value: 'Grand Caravan', label: 'Grand Caravan' },
        { value: 'Viper', label: 'Viper' },
    ],

    Seat: [
        { value: 'Ibiza', label: 'Ibiza' },
        { value: 'Leon', label: 'Leon' },
        { value: 'Arona', label: 'Arona' },
        { value: 'Ateca', label: 'Ateca' },
        { value: 'Tarraco', label: 'Tarraco' },
        { value: 'Toledo', label: 'Toledo' },
        { value: 'Alhambra', label: 'Alhambra' },
        { value: 'Mii', label: 'Mii' },
    ],

    Skoda: [
        { value: 'Fabia', label: 'Fabia' },
        { value: 'Octavia', label: 'Octavia' },
        { value: 'Superb', label: 'Superb' },
        { value: 'Kodiaq', label: 'Kodiaq' },
        { value: 'Karoq', label: 'Karoq' },
        { value: 'Scala', label: 'Scala' },
        { value: 'Citigo', label: 'Citigo' },
        { value: 'Rapid', label: 'Rapid' },
    ],



};



