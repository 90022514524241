import React, { useState, useEffect } from 'react';
import PageWrapper from '../components/js/PageWrapper';
import Header from '../components/js/Header';
import PageTabs from '../components/js/PageTabs';
import { useNavigation } from '../context/NavigationContext';
import MerchantBusinessesList from "../components/js/MerchantBusinessesList";

const Merchants = () => {
    const { onNavigateTo } = useNavigation();

    const tabs = ['MERCHANT_BUSINESSES_LIST', 'MERCHANT_BUSINESSES_EARNINGS'];
    const [currentTab, setCurrentTab] = useState(tabs[0]);

    const handleTabChange = (selectedTab) => {
        setCurrentTab(selectedTab);
    };

    return (
        <PageWrapper>
            <div className="body">
                <Header title="Merchants" />
                <PageTabs
                    tabs={tabs}
                    initialTab={currentTab}
                    onTabChange={handleTabChange}
                    pageContext="DASHBOARD"
                />

                <div className="custom-row">
                    {currentTab === 'MERCHANT_BUSINESSES_LIST' && (
                        <div>
                            <MerchantBusinessesList />
                        </div>
                    )}
                    {currentTab === 'MERCHANT_BUSINESSES_EARNINGS' && (
                        <div>
                            {/*<BusinessesEarningsList />*/}
                        </div>
                    )}
                </div>
            </div>
        </PageWrapper>
    );
};

export default Merchants;