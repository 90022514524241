import React from 'react';
import ReactDOM from 'react-dom';

const ImageModal = ({ isOpen, imageSrc, onClose }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1000
            }}
            onClick={onClose}
        >
            <div
                style={{
                    position: 'relative',
                    maxWidth: '80%',
                    maxHeight: '80%',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <img
                    src={imageSrc}
                    alt="Expanded View"
                    style={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'contain',
                        display: 'block',
                        maxHeight: 'calc(100vh - 100px)',
                    }}
                />
                <button
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        background: 'rgba(255, 255, 255, 0.8)',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        padding: '5px 10px',
                        fontSize: '16px'
                    }}
                >
                    Close
                </button>
            </div>
        </div>,
        document.body
    );
};

export default ImageModal;
