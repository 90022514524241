import taxiApi from "../apis/taxiApi";
import {filterTrueValues} from "./helpers";
import {ORDER_TYPE} from "../constants/enums";

export const createTaxiOrderHandle = async (user, taxiOrder, joinRoom, taxi = true) => {
    if (taxi) {
        console.log("CREATE taxi order:", taxiOrder);
    } else {
        console.log("CREATE transfer order:", taxiOrder);
    }

    const isValidLocation = (location) => {
        return location && location.address && location.coordinates &&
            location.coordinates.latitude !== null && location.coordinates.longitude !== null;
    };

    // Filter out invalid locations from the route array
    const filteredRoute = taxiOrder.route.filter(location => isValidLocation(location));
    const filteredPreferences = filterTrueValues(user?.radio_preferences);
    const orderPreferences = {
        ...taxiOrder.preferences,
        radio_preferences: filteredPreferences
    };
    let orderData = {
        type: taxiOrder.type,
        preferences: orderPreferences,
        route: filteredRoute,
        pickup_location: taxiOrder.pickup_location,
        delivery_location: taxiOrder.delivery_location,
        estimates: taxiOrder.estimates,
        payment: taxiOrder.payment,
        telephone: taxiOrder.telephone
    };
    if (taxiOrder.driver && taxiOrder.driver.driver_id) {
        orderData.driver = taxiOrder.driver;
    }

    try {
        console.log(orderData, 'orderData before creation');
        let order = await taxiApi.createTaxiOrder(orderData);
        if (order && !order.error) {
            joinRoom("order_" + order.order_id);
            console.log("joinRoom: ", "order_" + order.order_id);
        } else {
            console.error("Error creating order: ", order.error);
        }
    } catch (error) {
        console.error("Error creating taxi order:", error);
        throw new Error("Error creating taxi order: " + error.message);
    }
};
