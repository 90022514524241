import React, { useEffect, useState } from 'react';
import TimePickerComponent from './TimePickerComponent';
import colors from '../../constants/colors';
import { getLocalisedTexts } from "../../l10n/localisations/languages";
import Button from "./Button";
import '../css/RadioButtons.css';
import '../css/Input.css';


const WorkingHours = (props) => {
    const l10nWH = getLocalisedTexts('WORKING_HOURS');

    const [selectedDays, setSelectedDays] = useState(
        props?.selectedDays ? props?.selectedDays : {}
    );

    const handleInputChange = (time, index, field, day) => {
        const updatedSelection = { ...selectedDays };

        if (!updatedSelection[day]) {
            updatedSelection[day] = [["", ""]];
        }

        if (field.includes("second")) {
            updatedSelection[day][1][field.includes("StartTime") ? 0 : 1] = time;
        } else {
            updatedSelection[day][0][field === 'startTime' ? 0 : 1] = time;
        }

        setSelectedDays(updatedSelection);
        props.setSelectedDays(updatedSelection);
    };

    const handleDayChange = (dayTag) => {
        const updatedSelection = { ...selectedDays };

        if (updatedSelection[dayTag] === null) {
            updatedSelection[dayTag] = [["", ""]];
        } else {
            updatedSelection[dayTag] = null;
        }

        setSelectedDays(updatedSelection);
        props.setSelectedDays(updatedSelection);
    };


    const toggleDoubleWorkingHours = (dayTag) => {
        const updatedSelection = { ...selectedDays };

        if (!updatedSelection[dayTag]) {
            updatedSelection[dayTag] = [["", ""]];
        }

        if (updatedSelection[dayTag].length === 1) {
            updatedSelection[dayTag].push(["", ""]);
        } else {
            updatedSelection[dayTag].pop();
        }

        setSelectedDays(updatedSelection);
        props.setSelectedDays(updatedSelection);
    };

    const timePickerCustomStyle = {
        label: { color: '#333' },
        inputContainer: { cursor: 'pointer' },
        input: { border: '1px solid #ccc' },
        overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
        modal: { padding: '20px', borderRadius: '8px' },
        timePicker: { backgroundColor: '#fff' },
    }


    return (
        <div style={{ marginTop: '10px' }}>
            {props.label && <p className={'p-label'}>{props.label}</p>}
            <p style={{ fontSize: '12px', marginBottom: '10px' }}>{l10nWH?.infoMessage}</p>

            {props.days.map((day, index) => {
                const daySelected = selectedDays[day.tag] !== null;
                const workingTime = selectedDays[day.tag];

                return (
                    <div key={index} style={{ marginVertical: '5px' }}>
                        <label className="radio-button-item">
                            <input
                                type="checkbox"
                                className="custom-checkbox"
                                checked={daySelected}
                                onChange={() => handleDayChange(day.tag)}
                            />
                            {day.name}
                        </label>

                        {daySelected && (
                            <div style={{ marginTop: '5px' }}>
                                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', flex: 1}}>
                                    <TimePickerComponent
                                        time={workingTime ? workingTime[0][0] : ""}
                                        setTime={(time) => handleInputChange(time, index, 'startTime', day.tag)}
                                        title="Start Time"
                                        placeholder="HH:mm"
                                        customStyle={timePickerCustomStyle}
                                    />
                                    <span style={{margin: '0 8px', marginTop: '3%'}}> _ </span>
                                    <TimePickerComponent
                                        time={workingTime ? workingTime[0][1] : ""}
                                        setTime={(time) => handleInputChange(time, index, 'endTime', day.tag)}
                                        title="End Time"
                                        placeholder="HH:mm"
                                        customStyle={timePickerCustomStyle}
                                    />
                                </div>
                                {workingTime && workingTime.length === 2 && (
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        flex: 1,
                                        marginTop: 10
                                    }}>
                                        <TimePickerComponent
                                            time={workingTime[1][0]}
                                            setTime={(time) => handleInputChange(time, index, 'secondStartTime', day.tag)}
                                            title="Second Start Time"
                                            placeholder="HH:mm"
                                            customStyle={timePickerCustomStyle}
                                        />
                                        <span  style={{margin: '0 8px', marginTop: '4%'}}> _ </span>
                                        <TimePickerComponent
                                            time={workingTime[1][1]}
                                            setTime={(time) => handleInputChange(time, index, 'secondEndTime', day.tag)}
                                            title="Second End Time"
                                            placeholder="HH:mm"
                                            customStyle={timePickerCustomStyle}
                                        />
                                    </div>
                                )}

                                <Button
                                    text={workingTime && workingTime.length === 2 ? "Hide Second Hours" : "Add Second Hours"}
                                    action={() => toggleDoubleWorkingHours(day.tag)}
                                    style={{
                                        height: '20px',
                                        borderColor: colors.yellow,
                                        backgroundColor: colors.yellow,
                                        marginTop: '10px',
                                        marginBottom: '12px',
                                        width:'100%'
                                    }}
                                    styleText={{fontSize: 12}}
                                />
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default WorkingHours;
