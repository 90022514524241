import React, { useState } from 'react';
import Numbering from './Numbering';
import colors from "../../constants/colors";
import AddressInput from "./AddressInput";
import RadioButtons from './RadioButtons';

export const DailyMealsExtendedContent = ({ rowData, handlePreferences, handleDayPreferences, removeItem, setAddressObject }) => {
    const [mealPreferences, setMealPreferences] = useState(rowData?.daily_meal_preferences || {
        normal: { amount: 0 },
        substitution: { amount: 0 },
    });

    const [addressData, setAddressData] = useState(rowData?.address || '');

    const daysOfWeek = [
        { name: 'Monday', value: 'Monday' },
        { name: 'Tuesday', value: 'Tuesday' },
        { name: 'Wednesday', value: 'Wednesday' },
        { name: 'Thursday', value: 'Thursday' },
        { name: 'Friday', value: 'Friday' },
        { name: 'Saturday', value: 'Saturday' },
        { name: 'Sunday', value: 'Sunday' }
    ];

    const everyDayOption = [{ name: 'Every Day', value: 'Every Day' }];

    console.log(rowData?.daily_meal_day_preferences)

    const [selectedDays, setSelectedDays] = useState(rowData?.daily_meal_day_preferences?.length && rowData?.daily_meal_day_preferences?.length !== 0 ? rowData.daily_meal_day_preferences : ['Every Day']);

    const handleNumberChange = (type, newValue) => {
        const updatedPreferences = {
            ...mealPreferences,
            [type]: {
                amount: newValue,
            },
        };

        setMealPreferences(updatedPreferences);
        handlePreferences({
            user_id: rowData?.user_id,
            daily_meal_preferences: updatedPreferences,
        });
    };

    const handleDaySelection = (selectedItems) => {
        console.log('Selected Items:', selectedItems);
        const isEveryDaySelected = selectedItems.some(item => item.value === 'Every Day');
        const updatedSelectedDays = isEveryDaySelected ? daysOfWeek.map(day => day.value) : selectedItems.map(item => item.value);

        console.log('Updated Selected Days:', updatedSelectedDays);
        setSelectedDays(updatedSelectedDays);
        handleDayPreferences({
            user_id: rowData?.user_id,
            daily_meal_day_preferences: updatedSelectedDays,
        });
    };

    const handleRemoveClick = () => {
        if (rowData?.business_id) {
            removeItem({ business_id: rowData?.business_id });
        } else if (rowData?.delivery_driver_id) {
            removeItem({ delivery_driver_id: rowData?.delivery_driver_id });
        } else if (rowData?.user_id) {
            removeItem({ user_id: rowData?.user_id });
        }
    };

    return (
        <div className="other-extended-content" style={{ position: 'relative', width: '100%', minHeight: 30 }}>

            {/* User Address Section */}
            <div style={{marginBottom: 18}}>
                <h4>User delivery location:</h4>
                <AddressInput
                    value={addressData}
                    shouldAutocomplete={true}
                    placeholder="Enter user delivery location"
                    setAddressData={setAddressData}
                    setAddressObject={(value) => setAddressObject(rowData?.user_id, value)}
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* Column 1: Meal Preferences */}
                <div style={{ flex: 1, marginRight: '20px' }}>
                    <div>Daily Meal Preferences:</div>
                    <div style={{ flexDirection: 'column', width: '20%', marginTop: 10 }}>
                        <Numbering
                            title="Normal meal"
                            value={mealPreferences.normal.amount}
                            onUpdate={(newValue) => handleNumberChange('normal', newValue)}
                            red
                        />
                        <Numbering
                            title="Substitution meal"
                            value={mealPreferences.substitution.amount}
                            onUpdate={(newValue) => handleNumberChange('substitution', newValue)}
                            red
                        />
                    </div>
                </div>

                {/* Column 2: Day Preferences */}
                <div style={{ flex: 1 }}>
                    <div>Select Day Preferences:</div>
                    <RadioButtons
                        items={[...everyDayOption, ...daysOfWeek]}
                        onSelectionChange={(value) => handleDaySelection(value)}
                        value={selectedDays.map(day => ({ name: day, value: day }))}
                        row={false}
                        style={{ marginTop: 10 }}
                    />
                </div>
            </div>

            {/* Remove Button */}
            <button
                onClick={handleRemoveClick}
                style={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    backgroundColor: colors.red,
                    color: 'white',
                }}
            >
                Remove
            </button>
        </div>
    );
};

export default DailyMealsExtendedContent;
