import React, { useState } from 'react';
import PageWrapper from '../components/js/PageWrapper';
import Header from '../components/js/Header';
import PageTabs from '../components/js/PageTabs';
import { useNavigation } from '../context/NavigationContext';
import UsersList from "../components/js/lists/UsersList";

const Users = () => {
    const { onNavigateTo } = useNavigation();

    const tabs = ['USERS_LIST'];
    const [currentTab, setCurrentTab] = useState(tabs[0]);

    const handleTabChange = (selectedTab) => {
        setCurrentTab(selectedTab);
    };

    return (
        <PageWrapper>
            <div className="body">
                <Header title="Users Dashboard" />
                <PageTabs
                    tabs={tabs}
                    initialTab={currentTab}
                    onTabChange={handleTabChange}
                    pageContext="USERS"
                />

                {currentTab === 'USERS_LIST' && (
                    <div>
                        <UsersList />
                    </div>
                )}
            </div>
        </PageWrapper>
    );
};

export default Users;