import React, { useState } from 'react';
import PageWrapper from '../components/js/PageWrapper';
import Header from '../components/js/Header';
import PageTabs from '../components/js/PageTabs';
import { useNavigation } from '../context/NavigationContext';
import DriversDocumentsExpirationList from "../components/js/lists/DriversDocumentsExpirationList";
import DeliveryOrdersOverwatch from "../components/js/overwatch/DeliveryOrdersOverwatch";
import DeliveryDriversList from "../components/js/lists/DeliveryDriversList";
import TaxiDashboardTab from "./tabs/TaxiDashboardTab";
import DeliveryDashboardTab from "./tabs/DeliveryDashboardTab";
import DailyMealsOverwatch from "../components/js/overwatch/DailyMealsOverwatch";

const DashboardDelivery = () => {
    const { onNavigateTo } = useNavigation();

    const tabs = ['DASHBOARD', 'DELIVERY_DRIVERS_LIST', 'DAILY_MEALS'];
    const [currentTab, setCurrentTab] = useState(tabs[0]);

    const handleTabChange = (selectedTab) => {
        setCurrentTab(selectedTab);
    };

    return (
        <PageWrapper>
            <div className="body">
                <Header title="Delivery Dashboard" />
                <PageTabs
                    tabs={tabs}
                    initialTab={currentTab}
                    onTabChange={handleTabChange}
                    pageContext="DASHBOARD"
                />

                <div className="custom-row">
                    {currentTab === 'DASHBOARD' && (
                        <div>
                            <h3>Welcome back!</h3>
                            <DeliveryDashboardTab daily_meals={false}/>
                            <DeliveryOrdersOverwatch />
                        </div>
                    )}
                    {currentTab === 'DELIVERY_DRIVERS_LIST' && (
                        <div>
                            <DeliveryDriversList />
                        </div>
                    )}
                    {currentTab === 'DAILY_MEALS' && (
                        <div>
                            <h3>Welcome back!</h3>
                            <DeliveryDashboardTab daily_meals={true}/>
                            <DailyMealsOverwatch/>
                        </div>
                    )}
                </div>
            </div>
        </PageWrapper>
    );
};

export default DashboardDelivery;
