import React, { useState } from 'react';
import PageWrapper from '../components/js/PageWrapper';
import Header from '../components/js/Header';
import PageTabs from '../components/js/PageTabs';
import { useNavigation } from '../context/NavigationContext';
import DeliveryDriversList from "../components/js/lists/DeliveryDriversList";
import DeliveryDriversEarningsList from "../components/js/lists/DeliveryDriversEarningsList";

const DeliveryDrivers = () => {
    const { onNavigateTo } = useNavigation();

    const tabs = ['DELIVERY_DRIVERS_LIST', 'DELIVERY_DRIVERS_EARNINGS'];
    const [currentTab, setCurrentTab] = useState(tabs[0]);

    const handleTabChange = (selectedTab) => {
        setCurrentTab(selectedTab);
    };

    return (
        <PageWrapper>
            <div className="body">
                <Header title="Delivery Dashboard" />
                <PageTabs
                    tabs={tabs}
                    initialTab={currentTab}
                    onTabChange={handleTabChange}
                    pageContext="DASHBOARD"
                />

                <div className="custom-row">

                    {currentTab === 'DELIVERY_DRIVERS_LIST' && (
                        <div>
                            <DeliveryDriversList />
                        </div>
                    )}
                    {currentTab === 'DELIVERY_DRIVERS_EARNINGS' && (
                        <div>
                            <DeliveryDriversEarningsList />
                        </div>
                    )}
                </div>
            </div>
        </PageWrapper>
    );
};

export default DeliveryDrivers;