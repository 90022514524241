//MapUtils.js

export const onZoomChanged = (map, setZoomLevel) => {
    if (map) {
        setZoomLevel(map.getZoom());
    }
};

export const getStartAndEndOfDay = (date) => {
    const start = new Date(date);
    start.setHours(0, 0, 0, 0);

    const end = new Date(date);
    end.setHours(23, 59, 59, 999);

    return {start, end};
};

export const getStepByZoomLevel = (zoomLevel) => {
    if (zoomLevel <= 11) return 11;
    if (zoomLevel <= 12) return 8;
    if (zoomLevel <= 14) return 5;
    if (zoomLevel <= 16) return 2;
    return 1;
};