import React, {createContext, useContext, useEffect, useState} from 'react';
import io from 'socket.io-client';
import {useSelector, useDispatch} from 'react-redux';
import {useNotification} from "../message/NotificationContext";

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({children}) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const { showNotification, hideNotification } = useNotification();
   
    const [socket, setSocket] = useState(null);
    const token = user && user.access_token ? user.access_token : null;

    useEffect(() => {
        const newSocket = io(SOCKET_URL, {
            extraHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
        newSocket.on('connect', () => {
            console.log('connected to socket server');
        });

        newSocket.on('disconnect', () => {
            console.log('disconnected from socket server');
        });

        newSocket.on('sos_alert', (data) => {
            console.log('sos_alert', data);

            const formattedRole = data.user_role
                .toLowerCase()
                .replace(/_/g, ' ');

            const message = `A ${formattedRole} with the name ${data.first_name} ${data.last_name} is requesting help at ${data.location}. Contact number: ${data.telephone}`;

            showNotification({
                title: 'SOS Alert',
                description: message,
                button1Text: 'Close',
                button2CallAction: hideNotification
            });

        });

        setSocket(newSocket);

        return () => {
            newSocket.disconnect();
        };
    }, [token]);

    const joinRoom = (roomName) => {
        if (socket) {
            socket.emit('joinRoom', roomName);
        }
    };

    const leaveRoom = (roomName) => {
        if (socket) {
            socket.emit('leaveRoom', roomName);
        }
    };

    return (
        <SocketContext.Provider value={{socket, joinRoom, leaveRoom}}>
            {children}
        </SocketContext.Provider>
    );
};